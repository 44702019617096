import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export default function PartialLoading({ ...props }) {
  return (
    <React.Fragment>
      <Grid {...props} sx={{ textAlign: "center", margin: "auto" }}>
        <CircularProgress color="primary" style={{ margin: "auto" }} />
      </Grid>
    </React.Fragment>
  );
}
