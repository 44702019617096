import { Alert, Comment, Report, SessionsDailyMetric } from "@/interfaces";
import { getDateAndTime, getDurationFromString } from "./time";

export function arrayToCSV(data) {
  return data
    .map((row) =>
      row
        .map(String)
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(",")
    )
    .join("\r\n");
}

export function downloadCSV({ filename, data }) {
  downloadBlob(data, `${filename}.csv`, "text/csv;charset=utf-8;");
}

export function downloadBlob(content, filename, contentType) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

export function convertReportsToDataset(t, reports: Report[]): string[][] {
  const headerRow: string[] = [
    t("members.list.count"),
    t("members.list.first-name"),
    t("members.list.last-name"),
    t("members.metrics.average-work-per-day") + " (HH:mm:ss)",
    t("members.metrics.time-worked") + " (HH:mm:ss)",
    t("members.metrics.longest-work-day") + " (HH:mm:ss)",
    t("members.metrics.shortest-work-day") + " (HH:mm:ss)",
  ];
  const dataRows: string[][] = reports.map((report, index) => {
    const averageWorkPerDay = report.sessionsMetric.averageDailyDuration;
    const timeWorked = report.sessionsMetric.totalDuration;
    const longestWorkDay = report.sessionsMetric.highestDailyDuration;
    const shortestWorkDay = report.sessionsMetric.lowestDailyDuration;

    return [
      (index + 1).toString(),
      report.member.firstName || "",
      report.member.lastName || "",
      getDurationFromString(averageWorkPerDay, true, t, true) ?? "",
      getDurationFromString(timeWorked, true, t, true) ?? "",
      getDurationFromString(longestWorkDay, true, t, true) ?? "",
      getDurationFromString(shortestWorkDay, true, t, true) ?? "",
    ];
  });

  const dataset: string[][] = [headerRow, ...dataRows];

  return dataset;
}

export function convertDailyMetricsToDataset(
  t,
  dailyMetrics: SessionsDailyMetric[]
): string[][] {
  const headerRow: string[] = [
    t("members.metrics.timesheet-count"),
    t("members.metrics.timesheet-time-worked") + " (HH:mm:ss)",
    t("members.metrics.timesheet-started-at"),
    t("members.metrics.timesheet-ended-at"),
    t("members.metrics.timesheet-alerts"),
    t("members.metrics.timesheet-comments"),
  ];
  const dataRows: string[][] = dailyMetrics.map((metric, index) => {
    return [
      (index + 1).toString(),
      getDurationFromString(metric.totalDuration, false, t, true) ?? "",
      getDateAndTime(metric.startedAt) ?? "",
      getDateAndTime(metric.endedAt) ?? "",
      inlineAlerts(t, metric.alerts) ?? "",
      inlineComments(t, metric.comments) ?? "",
    ];
  });

  const dataset: string[][] = [headerRow, ...dataRows];

  return dataset;
}

function inlineAlerts(t, alerts: Alert[]): string {
  if (!alerts || alerts.length === 0) return "";

  const inline: string[] = [];
  alerts.forEach((alert) => {
    let title: string = "";

    if (alert.alertType === "system-checkout") {
      title = t("members.metrics.timesheet-system-checkout-alert");
    } else if (alert.alertType === "far-away-checking") {
      title = t("members.metrics.timesheet-far-away-checking-alert");
    } else {
      title = t("members.metrics.timesheet-unknown-alert");
    }

    inline.push(title);
  });

  return inline.join(", ");
}

function inlineComments(t, comments: Comment[]): string {
  if (!comments || comments.length === 0) return "";

  const inline: string[] = [];
  comments.forEach((comment) => {
    inline.push(comment.content);
  });

  return inline.join(", ");
}
