import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import PartialLoading from "@/components/partial-loading";
import { getDeviceInfo } from "@/helpers/device";
import {
  GEOLOCATION_OPTIONS,
  isGeoGranted,
  setGeoGranted,
  unsetGeoGranted,
} from "@/helpers/geo";
import { clearIdentity, isGuest, setIdentity } from "@/helpers/identity";
import {
  assignPathWith,
  getAfterAuthPath,
  getParams,
  keepPath,
  rawURLAssign,
  replacePathWith,
  setAfterAuthPath,
} from "@/helpers/navigation";
import { relativeDate } from "@/helpers/time";
import i18n from "@/i18n";
import {
  CurrentAction,
  LastEvent,
  Location,
  Myself,
  Organization,
  ZoneSetting,
} from "@/interfaces";
import AskGeolocation from "@/pages/ask-geolocation";
import CustomPageError from "@/pages/custom-page-error";
import PageError from "@/pages/error";
import {
  APP_STORE_URL,
  GOOGLE_PLAY_URL,
} from "@/pages/organization/dashboard/live";
import { gql, useMutation, useQuery } from "@apollo/client";
import BackHandIcon from "@mui/icons-material/BackHand";
import LoopIcon from "@mui/icons-material/Loop";
import { Alert, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { parseISO } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const TRIGGER_REFRESH_GEOLOCATION = 60000;
const NOT_WORKING_TIMEOUT = 8000;

const DEFAULT_ACTION = "CHECKIN";
const CHECKIN = gql`
  mutation Checkin(
    $latitude: Float
    $longitude: Float
    $accuracy: Float
    $zoneId: UUID!
  ) {
    Checkin(
      latitude: $latitude
      longitude: $longitude
      accuracy: $accuracy
      zoneId: $zoneId
    ) {
      action
    }
  }
`;

const CHECKOUT = gql`
  mutation Checkout(
    $latitude: Float
    $longitude: Float
    $accuracy: Float
    $zoneId: UUID!
  ) {
    Checkout(
      latitude: $latitude
      longitude: $longitude
      accuracy: $accuracy
      zoneId: $zoneId
    ) {
      action
    }
  }
`;

const GET_MYSELF = gql`
  query GetMyself {
    GetMyself {
      id
      firstName
      lastName
      lastEvent {
        id
        action
        createdAt
        triggeredBy
      }
      organization {
        id
        name
        slug
        zoneSetting {
          id
          qrcodeAccessLocationMode
        }
      }
    }
  }
`;

const FIRST_SCAN_SIGN_IN = gql`
  mutation FirstScanSignin($organizationID: UUID!, $deviceInput: DeviceInput!) {
    FirstScanSignin(
      organizationID: $organizationID
      deviceInput: $deviceInput
    ) {
      id
      accessToken
      role
      organizationID
    }
  }
`;

export function LinearBuffer({ setSlowLoading }) {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setSlowLoading(true);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
}

export function LoadingGeolocation({ setSkipGeolocation, locationMode }) {
  const { t } = useTranslation("member");
  const [slowLoading, setSlowLoading] = useState<boolean>(false);
  const [notWorking, setNotWorking] = useState<boolean>(false);

  React.useEffect(() => {
    if (slowLoading) {
      setTimeout(() => {
        setNotWorking(true);
      }, NOT_WORKING_TIMEOUT);
    }
  }, [slowLoading]);

  if (notWorking) {
    const extra = (
      <React.Fragment>
        <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
          <strong>{t("geolocation.denied-help-important")}</strong>{" "}
          {t("geolocation.denied-help-subimportant")}
        </Alert>
        {locationMode !== "REQUIRED" ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="error"
            sx={{ mb: 2, mt: 2 }}
            onClick={() => {
              setSkipGeolocation(true);
            }}
          >
            {t("geolocation.skip-this-step")}
          </Button>
        ) : (
          <></>
        )}
      </React.Fragment>
    );

    return <PageError error={t("geolocation.device-too-slow")} extra={extra} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LoopIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {slowLoading
            ? t("geolocation.wait-a-little-more")
            : t("geolocation.wait")}
        </Typography>
        <Box sx={{ pt: 2, mt: 1, width: "100%", display: "flex" }}>
          {slowLoading && <CircularProgress sx={{ margin: "auto" }} />}
          {!slowLoading && <LinearBuffer setSlowLoading={setSlowLoading} />}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export function CheckButton({
  latitude,
  longitude,
  accuracy,
  mutation,
  buttonLabel,
  setSuccess,
  ...props
}) {
  const [currentMutation, { data, loading, error }] = useMutation(mutation);
  const { zoneID } = useParams();

  useEffect(() => {
    if (data) {
      setSuccess(true);
    }
  }, [data]);

  if (loading) return <PartialLoading />;

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault();

    // strong typed all the way
    // but it's useless to send them at 0
    // it means they were never really set
    if (latitude == 0) latitude = null;
    if (longitude == 0) longitude = null;
    if (accuracy == 0) longitude = null;

    currentMutation({
      variables: {
        latitude,
        longitude,
        accuracy,
        zoneId: zoneID,
      },
    });
  };

  return (
    <div>
      <FormError error={error} sx={{ mb: 3 }} />
      <LoadingButton
        type="submit"
        fullWidth
        text={buttonLabel}
        size="large"
        variant="contained"
        sx={{ mb: 2 }}
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
        {...props}
      />
    </div>
  );
}

export function ActionButton({
  action,
  latitude,
  longitude,
  accuracy,
  setSuccess,
}) {
  const { t } = useTranslation("member");

  if (action === "CHECKIN") {
    return (
      <CheckButton
        latitude={latitude}
        longitude={longitude}
        accuracy={accuracy}
        mutation={CHECKIN}
        setSuccess={setSuccess}
        buttonLabel={t("checkin.submit")}
        color="primary"
      />
    );
  }

  if (action === "CHECKOUT") {
    return (
      <CheckButton
        latitude={latitude}
        longitude={longitude}
        accuracy={accuracy}
        mutation={CHECKOUT}
        setSuccess={setSuccess}
        buttonLabel={t("checkout.submit")}
        color="secondary"
      />
    );
  }

  return <div></div>;
}

function liveClock(startDate: Date, currentDate: Date): string {
  const timeDifference = currentDate.getTime() - startDate.getTime();

  const hours = Math.floor(timeDifference / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

export function LastEventTimer({ lastEvent }: { lastEvent: LastEvent }) {
  const { t } = useTranslation("member");
  const currAction = currentAction(t, lastEvent);
  const [clock, setClock] = useState<string>("-");
  const activeClock = currAction.action === "CHECKOUT" && lastEvent.createdAt;

  useEffect(() => {
    setInterval(() => {
      if (!activeClock) return;
      const startDate = parseISO(lastEvent.createdAt || "");

      const currentDate = new Date();
      const clock = liveClock(startDate, currentDate);
      setClock(clock);
    }, 1000);
  });

  if (!activeClock) return <></>;
  return (
    <React.Fragment>
      <div className="text-2xl m-3 border-grey">{clock}</div>
    </React.Fragment>
  );
}

function currentAction(t, lastEvent: LastEvent): CurrentAction {
  let currentAction: CurrentAction = {
    lastActionText: null,
    action: DEFAULT_ACTION,
  };

  if (lastEvent.createdAt !== null) {
    const readableDate = relativeDate(lastEvent.createdAt, i18n);
    const lastActionText =
      lastEvent.action === "CHECKIN"
        ? t("entrypoint.last-action-checkin-text", { date: readableDate })
        : t("entrypoint.last-action-checkout-text", { date: readableDate });
    let nextAction: "CHECKIN" | "CHECKOUT" | null = null;

    if (lastEvent.action === "CHECKIN") {
      nextAction = "CHECKOUT";
    } else if (lastEvent.action === "CHECKOUT") {
      nextAction = "CHECKIN";
    }

    currentAction = { lastActionText, action: nextAction };
  }

  return currentAction;
}

export function ShowAction({
  geolocation,
  lastEvent,
  organization,
  setSuccess,
  formWarning = <></>,
}) {
  const { t } = useTranslation("member");
  const currAction = currentAction(t, lastEvent);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <BackHandIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("entrypoint.choose-action", { name: organization.name })}
        </Typography>
        <Typography variant="subtitle1">{currAction.lastActionText}</Typography>
        <LastEventTimer lastEvent={lastEvent} />
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          {formWarning}
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <ActionButton
                // geolocation may be null but we still
                // communicate it through all of this
                action={currAction.action}
                latitude={geolocation.latitude}
                longitude={geolocation.longitude}
                accuracy={geolocation.accuracy}
                setSuccess={setSuccess}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DownloadNativeWarning />
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

import AppStore from "@/assets/app-store.svg";
import GooglePlay from "@/assets/google-play.svg";

export function DownloadNativeWarning() {
  const AppStoreLink = () => (
    <Grid
      item
      xs={6}
      margin="auto"
      onClick={() => {
        window.open(APP_STORE_URL, "_blank");
      }}
    >
      <img
        src={AppStore}
        alt="App Store"
        width="100%"
        style={{ cursor: "pointer" }}
      />
    </Grid>
  );

  const GooglePlayLink = () => (
    <Grid
      item
      xs={6}
      margin="auto"
      onClick={() => {
        window.open(GOOGLE_PLAY_URL, "_blank");
      }}
    >
      <img
        src={GooglePlay}
        alt="Play Store"
        width="100%"
        style={{ cursor: "pointer" }}
      />
    </Grid>
  );

  return (
    <div className="bg-grey m-auto mt-5 rounded-lg text-center pb-2">
      <Grid container xs={10} spacing={2} margin="auto">
        <Grid item xs={10} paddingLeft={0} margin="auto">
          For an optimal experience with us, download the mobile app.
        </Grid>
        <AppStoreLink />
        <GooglePlayLink />
      </Grid>
    </div>
  );
}

export default function MemberEntrypointQRCode() {
  const { t } = useTranslation(["member", "misc"]);
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization>({
    id: null,
    slug: null,
    name: null,
  });
  const [, setZoneSetting] = React.useState<ZoneSetting>({
    id: null,
    geofenceRadius: null,
    qrcodeAccessLocationMode: null,
  });
  // this is to skip the whole geolocation logic in this entrypoint
  const [skipGeolocation, setSkipGeolocation] = React.useState<boolean>(false);
  const [locationMode, setLocationMode] = React.useState<string>("");
  const [lastEvent, setLastEvent] = React.useState<LastEvent>({
    id: null,
    action: null,
    triggeredBy: null,
    createdAt: null,
  });
  const [myself, setMyself] = React.useState<Myself>({
    id: null,
    firstName: null,
    lastName: null,
  });
  const [geolocation, setGeolocation] = useState<Location>({
    latitude: 0,
    longitude: 0,
    accuracy: 0,
  });
  const [stateGeolocation, setStateGeolocation] = useState<string>("");
  const [refreshGeolocation, setRefreshGeolocation] = useState<boolean>(true);
  // we must use refs because it's going inside of intervals or closure
  // that don't keep the value if we use a useState
  const freezePage = useRef<boolean>(false);
  const [mutationFirstScanSignin, firstScanSignin] =
    useMutation(FIRST_SCAN_SIGN_IN);
  const hasCalledFirstScanSignin = useRef<boolean>(false);

  const params = getParams();
  let { organizationID } = useParams();
  if (!organizationID) organizationID = params.get("organization_id") || "";

  const getGetMyself = useQuery(GET_MYSELF, {
    skip: isGuest(),
  });

  useEffect(() => {
    if (getGetMyself.data) {
      const data = getGetMyself.data.GetMyself;
      setMyself({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      setOrganization(data.organization);
      setZoneSetting(data.organization.zoneSetting);
      setLocationMode(data.organization.zoneSetting.qrcodeAccessLocationMode);
      setLastEvent(data.lastEvent);
    }
  }, [getGetMyself]);

  // not updated after rendering to avoid
  // having the listener being set multiple times
  useEffect(() => {
    // if the tab is hidden we reload the geolocation
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        if (freezePage.current) {
          return;
        }

        setRefreshGeolocation(true);
      }
    });

    // we passed through a lot of steps
    // from this point forward we can ask for permission
    queryGeoPermission(setStateGeolocation);

    setInterval(() => {
      if (freezePage.current) return;

      setRefreshGeolocation(true);
    }, TRIGGER_REFRESH_GEOLOCATION);
  }, []);

  // this is for the first scan sign-in
  // we want to call it only once
  useEffect(() => {
    // we use ref to not have re-render of the mutation
    if (isGuest() && !hasCalledFirstScanSignin.current) {
      const deviceInput = getDeviceInfo();
      mutationFirstScanSignin({ variables: { organizationID, deviceInput } });
      hasCalledFirstScanSignin.current = true;
    }
  }, []);

  if (success) {
    freezePage.current = true;
    return <Success lastEvent={lastEvent} />;
  }

  // this is the first scan sign-in system
  // it'll help trigger a first scan
  // when on-boarding new customers
  // and self-signin the person with any device
  if (isGuest()) {
    if (firstScanSignin.error) {
      return (
        <PageError
          error={t("error.something-went-wrong-please-refresh", { ns: "misc" })}
        />
      );
    }

    if (firstScanSignin.data?.FirstScanSignin) {
      // if we receive a first scan sign-in
      // it means we have all data to build
      // an identity and refresh the page
      const data = firstScanSignin.data.FirstScanSignin;
      setIdentity({
        id: data.id,
        accessToken: data.accessToken,
        role: data.role,
        organizationID: data.organizationID,
      });
      const redirectURL = getAfterAuthPath({ clear: false }) || "/";
      rawURLAssign(redirectURL);
      // we show nothing because
      // it'll redirect the page directly
      return <GlobalSmallLoading />;
    }

    if (firstScanSignin.loading) {
      // we need that because we want to wait
      // for data to come in before going furhter
      return <GlobalSmallLoading />;
    }
  }

  if (myself.id) {
    if (myself.firstName === null || myself.lastName === null) {
      // if the person is signed-in but have no first name or last name
      // we want to get back here afterwards
      setAfterAuthPath(keepPath(window.location.href));
      assignPathWith(navigate, "/myself/name");
      // we show nothing because
      // it'll redirect the page directly
      return <GlobalSmallLoading />;
    }
  }

  // the guy is trying to check-in
  // but is from another organization
  // we should tell him
  if (organization.id !== null && organization.id !== organizationID) {
    clearIdentity();

    return (
      <CustomPageError
        title={t("error.sign-in-another-organization", { ns: "misc" })}
        extra={t("error.sign-in-another-organization-extra", { ns: "misc" })}
      />
    );
  }

  if (isGuest() || getGetMyself.error) {
    // if there's an error or he's not signed-in
    // the member is probably not registered
    setAfterAuthPath(keepPath(window.location.href));
    replacePathWith(
      navigate,
      `/authentication/sign-in?organization_id=${organizationID}`
    );
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  }
  if (getGetMyself.loading) return <GlobalSmallLoading />;

  if (!navigator.geolocation && locationMode === "REQUIRED") {
    return <PageError error={t("geolocation.device-error", { ns: "misc" })} />;
  }

  // NOTE: this is not working as expected at all,
  // it's stays "prompt" on ios even though there are position and coords
  if (stateGeolocation === "denied" && locationMode === "REQUIRED") {
    // In some devics this actually breaks the app and it goes in circle
    // So we can just let it there for now, denid is denied anyway.
    // unsetGeoGranted();
    const extra = (
      <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
        <strong>{t("geolocation.denied-help-important")}</strong>{" "}
        {t("geolocation.denied-help-subimportant")}
      </Alert>
    );
    return (
      <PageError
        // the original was "geolocation.denied" but
        // it's better to just have a general message
        error={t("geolocation.device-too-slow")}
        extra={extra}
      />
    );
  }

  // Chrome iOS doesn't respect the "granted" state so we have to store the response when granting permission
  // https://stackoverflow.com/questions/78258245/why-is-chrome-ios-sending-prompt-when-geolocation-is-granted
  if (
    stateGeolocation === "prompt" &&
    !isGeoGranted() &&
    (locationMode === "ASKED" || locationMode === "REQUIRED") &&
    !skipGeolocation
  ) {
    return (
      <AskGeolocation
        message={t("geolocation.need-for-section", { ns: "misc" })}
        setGeolocation={setGeolocation}
        setStateGeolocation={setStateGeolocation}
      />
    );
  }

  // temp solution until there's an answer for
  // https://stackoverflow.com/questions/78258245/why-is-chrome-ios-sending-prompt-when-geolocation-is-granted
  // wee consider "prompt" like "granted" because Chrome iOS doesn't respect the API
  if (
    refreshGeolocation &&
    (locationMode === "ASKED" || locationMode === "REQUIRED") &&
    !skipGeolocation
  ) {
    if (stateGeolocation === "granted" || stateGeolocation === "prompt") {
      queryGeoPosition({ setGeolocation, setRefreshGeolocation });
    }
  }

  // this is useful to load the geolocation at all time even after the permission was accepted
  // or for refresh position when tab is active again
  if (
    refreshGeolocation &&
    (locationMode === "ASKED" || locationMode === "REQUIRED") &&
    !skipGeolocation
  ) {
    return (
      <LoadingGeolocation
        locationMode={locationMode}
        setSkipGeolocation={setSkipGeolocation}
      />
    );
  }

  // we take care of eventual warnings
  let formWarning: JSX.Element = <></>;

  // if they attempted to get the location but it was unsuccessful
  // we should tell them
  if (locationMode === "ASKED" && geolocation.latitude === null) {
    formWarning = (
      <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
        <strong>{t("geolocation.denied-help-important")}</strong>{" "}
        {t("geolocation.denied-help-subimportant")}
      </Alert>
    );
  }

  // if he did not check-out and the system did it for them
  // we should tell them so they try harder
  if (lastEvent.triggeredBy === "SYSTEM") {
    formWarning = (
      <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
        {t("entrypoint.forgot-checkout")}
      </Alert>
    );
  }

  return (
    <ShowAction
      lastEvent={lastEvent}
      geolocation={geolocation}
      organization={organization}
      formWarning={formWarning}
      setSuccess={setSuccess}
    />
  );
}

function queryGeoPermission(setter) {
  // sometimes they don't have the API for it
  if (!navigator.permissions || !navigator.permissions.query) return;
  // we passed through a lot of steps
  // from this point forward we can ask for permission
  navigator.permissions.query({ name: "geolocation" }).then((response) => {
    setter(response.state);
  });
}

function queryGeoPosition({ setGeolocation, setRefreshGeolocation }) {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      setGeoGranted();
      setGeolocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
      });
      setRefreshGeolocation(false);
    },
    () => {
      unsetGeoGranted();
    },
    GEOLOCATION_OPTIONS
  );
}

export function Success({ lastEvent }) {
  const { t } = useTranslation("member");
  const currAction = currentAction(t, lastEvent);

  let actionText: string = "";
  let subText: string = "";
  let backgroundColor: string = "";

  if (currAction.action == "CHECKIN") {
    actionText = t("checkin.page-success-title");
    subText = t("checkin.page-success-sub");
    backgroundColor = "primary.main";
  } else if (currAction.action === "CHECKOUT") {
    actionText = t("checkout.page-success-title");
    subText = t("checkout.page-success-sub");
    backgroundColor = "secondary.main";
  } else {
    return <></>;
  }

  return (
    <Container maxWidth={false} sx={{ bgcolor: backgroundColor }}>
      <Box height="100vh" display="flex" flexDirection="column">
        <Typography
          component="h1"
          variant="h3"
          color="white"
          align="center"
          sx={{ pt: 30 }}
        >
          {actionText}
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          color="white"
          align="center"
          sx={{ pt: 2 }}
        >
          {subText}
        </Typography>
      </Box>
    </Container>
  );
}
