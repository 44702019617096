import GlobalSmallLoading from "@/components/global-small-loading";
import { sleep } from "@/helpers";
import { clearIdentity, hasIdentity } from "@/helpers/identity";
import { rawURLReplace } from "@/helpers/navigation";

export default function SignOut() {
  clearIdentity();

  (async () => {
    while (hasIdentity()) await sleep(500);
    // we wait until we got rid of it
    // technically in the storage
    rawURLReplace("/");
  })();
  // we show nothing because
  // it'll redirect the page directly
  return <GlobalSmallLoading />;
}
