import {
  enUS as dataGridEnUS,
  esES as dataGridEs,
} from "@mui/x-data-grid/locales";
import {
  Locale,
  format,
  formatDistance,
  formatRelative,
  getHours,
  getMinutes,
  parse,
  parseISO,
} from "date-fns";
import { enGB, es } from "date-fns/locale";
import { TFunction, i18n } from "i18next";
import { Duration } from "luxon";

export function timeDifference(start: string, end: string) {
  if (!start || !end) {
    return "-";
  }
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  return formatDistance(endDate, startDate);
}

export function timestampToDate(timestamp: string) {
  // const roundedDate = new Date(
  //   Math.round(parseISO(timestamp).getTime() / (30 * 60 * 1000)) *
  //     (30 * 60 * 1000)
  // );

  const roundedDate = parseISO(timestamp);
  return getDate(roundedDate);

  // return dateToReadableDayMonthYear(roundedDate);
}

export function getHoursAndMinutes(createdAt: string) {
  const date = parseISO(createdAt);
  return `${getHours(date)}:${getMinutes(date)}`;
}

export function getToday() {
  return getDate(new Date());
}

export function getDate(date: string | Date) {
  if (typeof date === "string") date = parseISO(date);
  if (!(date instanceof Date)) return "";
  return format(date, "dd/MM/yyyy");
}

export function getDateAndTime(date: string | Date) {
  if (typeof date === "string") date = new Date(date);
  if (!(date instanceof Date)) return "";
  return format(date, "dd/MM/yyyy HH:mm:ss");
}

export function relativeDate(createdAt: string, i18n: i18n) {
  const date = parseISO(createdAt);
  return relativeDateFromDate(date, i18n);
}

export function relativeDateFromDate(date: Date, i18n: i18n) {
  const locale = localeFromI18n(i18n);

  return formatRelative(date, new Date(), { locale });
}

export function dataGridlocaleFromI18n(i18n: i18n) {
  switch (i18n?.language) {
    case "en":
      // for now, we prefer enGB because of the date format
      // but we'll probably need to change that in the future
      return dataGridEnUS;
    case "es":
      return dataGridEs;
    default:
      return dataGridEnUS;
  }
}

export function localeFromI18n(i18n: i18n): Locale {
  switch (i18n?.language) {
    case "en":
      // for now, we prefer enGB because of the date format
      // but we'll probably need to change that in the future
      return enGB;
    case "es":
      return es;
    // case "fr":
    //   locale = fr;
    //   break;
    default:
      return enGB;
  }
}

export function isDate(val) {
  return !isNaN(new Date(val).getTime());
}

export function secondsToIso8601Duration(seconds) {
  const years = Math.floor(seconds / (365 * 24 * 60 * 60));
  const months = Math.floor(
    (seconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60)
  );
  const days = Math.floor(
    ((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) / (24 * 60 * 60)
  );
  const hours = Math.floor(
    (((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) %
      (24 * 60 * 60)) /
      (60 * 60)
  );
  const minutes = Math.floor(
    ((((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) %
      (24 * 60 * 60)) %
      (60 * 60)) /
      60
  );
  const remainingSeconds = Math.round(
    (((seconds % (365 * 24 * 60 * 60)) % (30 * 24 * 60 * 60)) %
      (24 * 60 * 60)) %
      60
  );

  let iso8601Duration = "P";

  if (years > 0) {
    iso8601Duration += `${years}Y`;
  }

  if (months > 0) {
    iso8601Duration += `${months}M`;
  }

  if (days > 0) {
    iso8601Duration += `${days}D`;
  }

  if (hours > 0 || minutes > 0 || remainingSeconds > 0) {
    iso8601Duration += "T";
  }

  if (hours > 0) {
    iso8601Duration += `${hours}H`;
  }

  if (minutes > 0) {
    iso8601Duration += `${minutes}M`;
  }

  if (remainingSeconds > 0) {
    iso8601Duration += `${remainingSeconds}S`;
  }

  return iso8601Duration;
}

export function minutesToIso8601Duration(minutes: number): string {
  let duration = minutes * 60 * 1000; // Convert minutes to milliseconds

  const hours = Math.floor(duration / (60 * 60 * 1000));
  duration -= hours * (60 * 60 * 1000);

  const mins = Math.floor(duration / (60 * 1000));
  duration -= mins * (60 * 1000);

  const secs = Math.floor(duration / 1000);

  const isoDuration = `PT${hours}H${mins}M${secs}S`;

  return isoDuration;
}

export function getDurationFromString(
  iso8601Duration: string,
  approx: boolean,
  t: TFunction<[string, string], undefined>,
  csvFormat: boolean = false
): string {
  if (iso8601Duration === "") return "";

  const duration = Duration.fromISO(iso8601Duration);
  const hours: number = Math.floor(duration.as("hours"));
  const minutes: number = Math.floor(duration.as("minutes")) % 60;
  const remainingSeconds: number = Math.floor(duration.as("seconds")) % 60;

  if (hours <= 0 && minutes <= 0 && remainingSeconds <= 0) {
    return "";
  }

  const parts: string[] = [];

  if (hours > 0) {
    const hourTrans =
      hours <= 0
        ? t("duration.hour", { ns: "misc" })
        : t("duration.hours", { ns: "misc" });

    if (csvFormat) parts.push(`${("0" + hours).slice(-2)}`);
    else parts.push(`${hours} ${hourTrans}`);
  } else {
    if (csvFormat) {
      parts.push("00");
    }
  }

  if (minutes > 0 || (hours > 0 && remainingSeconds > 0)) {
    const minuteTrans =
      minutes <= 0
        ? t("duration.minute", { ns: "misc" })
        : t("duration.minutes", { ns: "misc" });

    if (csvFormat) parts.push(`${("0" + minutes).slice(-2)}`);
    else parts.push(`${minutes} ${minuteTrans}`);
  } else {
    if (csvFormat) {
      parts.push("00");
    }
  }

  if (!approx || (hours === 0 && minutes === 0)) {
    if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
      const secondTrans =
        remainingSeconds <= 0
          ? t("duration.second", { ns: "misc" })
          : t("duration.seconds", { ns: "misc" });

      if (csvFormat) parts.push(`${("0" + remainingSeconds).slice(-2)}`);
      else parts.push(`${remainingSeconds} ${secondTrans}`);
    } else {
      if (csvFormat) {
        parts.push("00");
      }
    }
  } else {
    if (csvFormat) {
      parts.push("00");
    }
  }

  if (csvFormat) return parts.join(":");
  else return parts.join(" ");
}

export function iso8601DurationToSeconds(isoDuration: string) {
  const regex =
    /P(?:([\d.]+)Y)?(?:([\d.]+)M)?(?:([\d.]+)D)?T?(?:([\d.]+)H)?(?:([\d.]+)M)?(?:([\d.]+)S)?/;

  const matches = isoDuration.match(regex);

  if (!matches) {
    throw new Error("Invalid ISO 8601 duration format");
  }

  const [, years, months, days, hours, minutes, seconds] =
    matches.map(parseFloat);

  const totalSeconds =
    (years || 0) * 365 * 24 * 60 * 60 +
    (months || 0) * 30 * 24 * 60 * 60 +
    (days || 0) * 24 * 60 * 60 +
    (hours || 0) * 60 * 60 +
    (minutes || 0) * 60 +
    (seconds || 0);

  return Math.floor(totalSeconds);
}

export function iso8601DurationToMinutes(isoDuration: string) {
  const regex =
    /P(?:([\d.]+)Y)?(?:([\d.]+)M)?(?:([\d.]+)D)?T?(?:([\d.]+)H)?(?:([\d.]+)M)?(?:([\d.]+)S)?/;

  const matches = isoDuration.match(regex);

  if (!matches) {
    throw new Error("Invalid ISO 8601 duration format");
  }

  const [, years, months, days, hours, minutes, seconds] =
    matches.map(parseFloat);

  const totalMinutes =
    (years || 0) * 365 * 24 * 60 +
    (months || 0) * 30 * 24 * 60 +
    (days || 0) * 24 * 60 +
    (hours || 0) * 60 +
    (minutes || 0) +
    (seconds || 0) / 60;

  return Math.floor(totalMinutes);
}

export function iso8601ToSeconds(duration: string): number {
  const match = duration.match(
    /P(\d+Y)?(\d+M)?(\d+W)?(\d+D)?T(\d+H)?(\d+M)?(\d+S)?/
  );

  if (!match) {
    throw new Error("Invalid ISO 8601 duration format");
  }

  const years = match[1] ? parseInt(match[1]) : 0;
  const months = match[2] ? parseInt(match[2]) : 0;
  const weeks = match[3] ? parseInt(match[3]) : 0;
  const days = match[4] ? parseInt(match[4]) : 0;
  const hours = match[5] ? parseInt(match[5]) : 0;
  const minutes = match[6] ? parseInt(match[6]) : 0;
  const seconds = match[7] ? parseInt(match[7]) : 0;

  return (
    years * 365 * 24 * 60 * 60 + // Convert years to days
    months * 30 * 24 * 60 * 60 + // Assume 30 days in a month
    weeks * 7 * 24 * 60 * 60 + // Convert weeks to days
    days * 24 * 60 * 60 +
    hours * 60 * 60 +
    minutes * 60 +
    seconds
  );
}

export function splitDuration(totalSeconds: number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

interface TimeObject {
  hours: number;
  minutes: number;
  seconds: number;
}

export function timeObjectToSeconds(time: TimeObject): number {
  const totalSeconds = time.hours * 3600 + time.minutes * 60 + time.seconds;
  return totalSeconds;
}

export function secondsToISO8601(totalSeconds: number): string {
  const years = Math.floor(totalSeconds / (365 * 24 * 60 * 60));
  totalSeconds %= 365 * 24 * 60 * 60;

  const months = Math.floor(totalSeconds / (30 * 24 * 60 * 60));
  totalSeconds %= 30 * 24 * 60 * 60;

  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  totalSeconds %= 24 * 60 * 60;

  const hours = Math.floor(totalSeconds / (60 * 60));
  totalSeconds %= 60 * 60;

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  let isoDuration = "P";
  if (years > 0) isoDuration += years + "Y";
  if (months > 0) isoDuration += months + "M";
  if (days > 0) isoDuration += days + "D";
  isoDuration += "T";
  if (hours > 0) isoDuration += hours + "H";
  if (minutes > 0) isoDuration += minutes + "M";
  if (seconds > 0) isoDuration += seconds + "S";

  return isoDuration;
}

export function timeOfTheDayToDate(time: string) {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const dateString = `${formattedDate} ${time}`;
  return parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
}

export function dateToTimeOfTheDay(date: string | number | Date | undefined) {
  if (typeof date === "undefined") return "";
  return format(date, "HH:mm:ss");
}

export function dateToReadableDayMonthYear(date: Date | null): string {
  if (!date) return "";
  return format(date, "dd/MM/yyyy");
}

export function timestampToTime(timestamp: string) {
  const roundedDate = new Date(
    Math.round(parseISO(timestamp).getTime() / (30 * 60 * 1000)) *
      (30 * 60 * 1000)
  );

  return roundedDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}
