import { name, version } from "@/../package.json";
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { eventBusEmit } from "./helpers/event-bus";
import { getIdentity, getLanguage } from "./helpers/identity";

console.log(
  `GraphQL endpoint detected at ${import.meta.env.VITE_API_GRAPHQL_ENDPOINT}`
);

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  const contentLanguage = getLanguage();

  const extraHeaders = {};
  extraHeaders["X-Aquiestoy-App-Version"] = version;
  extraHeaders["X-Aquiestoy-App-Name"] = name;

  if (contentLanguage) {
    extraHeaders["Content-Language"] = contentLanguage;
  }

  return {
    headers: {
      ...headers,
      ...extraHeaders,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

//  NOTE : if we want to be specific some day, here's
// the list of error we could avoid to dispatch
// otherwise we just go for all the `UNKNOWN` for Sentry
// this will typically come from the backend as type
// and it should be more or less in sync
// but we can adapt it of course
// const skipReportDispatch = [
//   "WRONG_PASSWORD",
//   "MEMBER_NOT_FOUND",
//   ...
// ];

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    Sentry.setContext("identity", getIdentity());

    graphQLErrors
      .filter((err) => "UNKNOWN" === (err.extensions.code as string))
      .map((error) => {
        Sentry.setContext("graphQLError", error);
        Sentry.captureMessage(error.message);
      });
  }

  // if there's a major network issue
  // we crash the app itself
  if (networkError) {
    // if the status code is forbidden
    // we clear up and reload completely (revoke access, etc. do this)
    // it's done in the receiver
    eventBusEmit({ type: "network-error", payload: networkError });
  }
});

const appLink = from([errorLink, authLink.concat(httpLink)]);

const createApolloClient = () => {
  return new ApolloClient({
    link: appLink,
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
