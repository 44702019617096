import * as React from "react";

import { RenderBarChartDurations } from "@/components/charts/render-bar-chart-durations";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const GET_SESSIONS_DAILY_TIME_SERIES = gql`
  query GetSessionsDailyTimeSeries(
    $startDate: Time!
    $endDate: Time!
    $memberId: UUID!
    $zoneId: UUID
  ) {
    GetSessionsDailyTimeSeries(
      startDate: $startDate
      endDate: $endDate
      memberId: $memberId
      zoneId: $zoneId
    ) {
      data {
        totalDuration
        alerts {
          id
          alertType
        }
        timestamp
      }
      totalIntervals
      totalData
    }
  }
`;

export default function TimelineAverageDaily({
  dateRange,
  memberId,
  zoneId,
  referencePoint,
}) {
  const { t } = useTranslation(["organization", "misc"]);
  const intervalInMinutes = 60 * 24;

  const variables = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    memberId,
  };

  if (zoneId) {
    variables["zoneId"] = zoneId;
  }

  const { data, error, loading } = useQuery(GET_SESSIONS_DAILY_TIME_SERIES, {
    variables,
  });

  const timeseries = data?.GetSessionsDailyTimeSeries;

  const elements = [
    {
      name: t("members.metrics.timeline-average-daily-worked-alerts-label"),
      entry: "totalDuration",
      color: "error",
      type: "alerts",
    },
    {
      name: t("members.metrics.timeline-average-daily-worked-label"),
      entry: "totalDuration",
      color: "primary",
      type: "regular",
    },
  ];

  return (
    <React.Fragment>
      <RenderBarChartDurations
        timeseries={timeseries}
        error={error}
        loading={loading}
        elements={elements}
        intervalInMinutes={intervalInMinutes}
        referencePoint={referencePoint}
        t={t}
      />
    </React.Fragment>
  );
}
