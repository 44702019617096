import AstroWondering from "@/components/astro-wondering";
import { Copyright } from "@/components/copyright";
import { ApolloError } from "@apollo/client";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function NotFound({
  error = new ApolloError({ graphQLErrors: [] }),
}) {
  const { t } = useTranslation("misc");

  // we don't need to translate it because
  // it's just for development debugging
  const displayError =
    import.meta.env.VITE_ENV === "development" ? error?.message : "";

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <QuestionMarkIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("error.page-not-found")}
        </Typography>
        {displayError}
        <AstroWondering />
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
