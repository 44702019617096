import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { eventBusEmit } from "@/helpers/event-bus";
import { getRole } from "@/helpers/identity";
import {
  dateToTimeOfTheDay,
  iso8601ToSeconds,
  secondsToISO8601,
  splitDuration,
  timeObjectToSeconds,
  timeOfTheDayToDate,
} from "@/helpers/time";
import { Organization, SessionSetting, ZoneSetting } from "@/interfaces";
import Title from "@/pages/organization/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);

const GET_MYSELF_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      name
      registrationDisabled
      sessionSetting {
        id
        openTime
        closeTime
        defaultSessionDuration
        systemCheckoutTriggerDuration
        systemCheckoutTriggerOnClose
      }
      zoneSetting {
        id
        geofenceRadius
        qrcodeAccessLocationMode
      }
    }
  }
`;

const UPDATE_SESSION_SETTING = gql`
  mutation UpdateSessionSetting(
    $id: UUID!
    $openTime: String
    $closeTime: String
    $systemCheckoutTriggerDuration: Duration
    $systemCheckoutTriggerOnClose: Boolean
    $defaultSessionDuration: Duration
  ) {
    UpdateSessionSetting(
      id: $id
      openTime: $openTime
      closeTime: $closeTime
      systemCheckoutTriggerDuration: $systemCheckoutTriggerDuration
      systemCheckoutTriggerOnClose: $systemCheckoutTriggerOnClose
      defaultSessionDuration: $defaultSessionDuration
    ) {
      id
    }
  }
`;

const UPDATE_ZONE_SETTING = gql`
  mutation UpdateZoneSetting(
    $id: UUID!
    $qrcodeAccessLocationMode: AccessLocationMode
    $geofenceRadius: Int
  ) {
    UpdateZoneSetting(
      id: $id
      qrcodeAccessLocationMode: $qrcodeAccessLocationMode
      geofenceRadius: $geofenceRadius
    ) {
      id
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($registrationDisabled: Boolean, $name: String) {
    UpdateOrganization(
      registrationDisabled: $registrationDisabled
      name: $name
    ) {
      id
      registrationDisabled
      name
    }
  }
`;

export function DurationField({
  originalDuration,
  setDuration,
  defaultWithSeconds,
  ...props
}) {
  const MAX_DURATION_FIELD = 900;
  const { t } = useTranslation("organization");
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  // setting the default ones
  useEffect(() => {
    if (originalDuration) {
      const durationInSeconds = iso8601ToSeconds(originalDuration);
      const timeSplit = splitDuration(durationInSeconds);

      setHours(timeSplit.hours);
      setMinutes(timeSplit.minutes);
      setSeconds(timeSplit.seconds);
    }
  }, [originalDuration]);

  // we change the setter given from outside each time we refresh the hours/minutes/seconds
  useEffect(() => {
    const changeInSeconds = timeObjectToSeconds({
      hours,
      minutes,
      seconds,
    });

    setDuration(secondsToISO8601(changeInSeconds));
  }, [hours, minutes, seconds]);

  let showSeconds = false;
  let showMinutes = false;
  let showHours = false;

  if (defaultWithSeconds) {
    showSeconds = true;
    showMinutes = true;
  } else {
    showSeconds = false;
    showMinutes = true;
    showHours = true;
  }

  if (parseInt(`${hours}`) > 0) {
    showHours = true;
    showMinutes = true;
    showSeconds = false;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {showHours ? (
          <Grid item xs={6} md={3}>
            <TextField
              label={t("fields.hours")}
              type="number"
              value={hours || ""}
              onChange={(e) => {
                const time = parseInt(e.target.value);
                if (time > MAX_DURATION_FIELD) return;
                setHours(isNaN(time) ? 0 : time);
              }}
              {...props}
            />
          </Grid>
        ) : (
          <></>
        )}
        {showMinutes ? (
          <Grid item xs={6} md={3}>
            <TextField
              label={t("fields.minutes")}
              type="number"
              value={minutes || ""}
              onChange={(e) => {
                const time = parseInt(e.target.value);
                if (time > MAX_DURATION_FIELD) return;
                setMinutes(isNaN(time) ? 0 : time);
              }}
              {...props}
            />
          </Grid>
        ) : (
          <></>
        )}
        {showSeconds ? (
          <Grid item xs={6} md={3}>
            <TextField
              label={t("fields.seconds")}
              type="number"
              value={seconds || ""}
              onChange={(e) => {
                const time = parseInt(e.target.value);
                if (time > MAX_DURATION_FIELD) return;
                setSeconds(isNaN(time) ? 0 : time);
              }}
              {...props}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default function Dashboard() {
  const { t } = useTranslation(["organization", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [updateSessionSetting, setUpdateSessionSetting] =
    useState<boolean>(false);
  const [updateOrganizationSetting, setUpdateOrganizationSetting] =
    useState<boolean>(false);

  const getMyOrganization = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("settings.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success) {
      eventBusEmit({
        type: "form-success",
        payload: t("settings.edit.updated"),
      });
      setSuccess(false);
    }
  }, [success, error]);

  if (getMyOrganization.loading) return <Loading />;
  if (getMyOrganization.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setUpdateSessionSetting(true);
    setUpdateOrganizationSetting(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <OrganizationSettings
            fetchedOrganizationSetting={getMyOrganization.data.MyOrganization}
            setUpdateOrganizationSetting={setUpdateOrganizationSetting}
            updateOrganizationSetting={updateOrganizationSetting}
            setError={setError}
            setSuccess={setSuccess}
          />

          <Divider variant="middle" sx={{ mb: 2 }} />

          <SessionSettings
            fetchedSessionSetting={
              getMyOrganization.data.MyOrganization.sessionSetting
            }
            setUpdateSessionSetting={setUpdateSessionSetting}
            updateSessionSetting={updateSessionSetting}
            setError={setError}
            setSuccess={setSuccess}
            custom={false}
          />

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              disabled={
                !permitAccess({
                  role: getRole(),
                  resource: Resource.DASHBOARD,
                  permission: Permission.UPDATE,
                })
              }
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("settings.submit")}
            </Button>
          </Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export function OrganizationSettings({
  fetchedOrganizationSetting,
  setUpdateOrganizationSetting,
  updateOrganizationSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["organization", "misc"]);
  const [organization, setOrganization] = useState<Organization>({
    id: null,
    name: null,
    registrationDisabled: false,
  });

  const [mutationUpdateOrganization, { data, error }] =
    useMutation(UPDATE_ORGANIZATION);

  useEffect(() => {
    if (data) setSuccess(true);
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (fetchedOrganizationSetting) setOrganization(fetchedOrganizationSetting);
  }, [fetchedOrganizationSetting]);

  useEffect(() => {
    if (updateOrganizationSetting) {
      mutationUpdateOrganization({
        variables: {
          registrationDisabled: organization.registrationDisabled,
          name: organization.name,
        },
      });
      setUpdateOrganizationSetting(false);
    }
  }, [updateOrganizationSetting]);

  if (organization.id === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Title>{t("organization.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("organization.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("organization.entity-details")}
            description={t("organization.entity-details-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="organization"
                  name="organizationName"
                  value={organization.name}
                  onChange={(event) => {
                    setOrganization((prevSet: Organization) => ({
                      ...prevSet,
                      name: event.target.value,
                    }));
                  }}
                  required
                  id="organizationName"
                  label={t("organization.name-field")}
                />
              </Grid>
            </Paper>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("organization.general-access")}
            description={t("organization.general-access-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={organization.registrationDisabled}
                      onChange={(event) => {
                        setOrganization((prevSet: Organization) => ({
                          ...prevSet,
                          registrationDisabled: event.target.checked,
                        }));
                      }}
                    />
                  }
                  label={t("organization.disabled-registration")}
                />
                <Typography component="p" sx={{ color: "grey" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("organization.disabled-registration-info", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Typography>
              </FormGroup>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export function SessionSettings({
  fetchedSessionSetting,
  setUpdateSessionSetting,
  updateSessionSetting,
  setSuccess,
  setError,
  custom,
}) {
  const { t } = useTranslation(["organization", "misc"]);
  const [systemCheckoutTriggerDuration, setSystemCheckoutTriggerDuration] =
    useState<number>(0);
  const [defaultSessionDuration, setDefaultSessionDuration] =
    useState<number>(0);
  const [sessionSetting, setSessionSetting] = useState<SessionSetting>({
    id: null,
    openTime: "",
    closeTime: "",
    defaultSessionDuration: 0,
    systemCheckoutTriggerDuration: 0,
    systemCheckoutTriggerOnClose: false,
  });
  const [mutationSessionSetting, { data, error }] = useMutation(
    UPDATE_SESSION_SETTING
  );

  useEffect(() => {
    if (data) setSuccess(true);
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (updateSessionSetting) {
      // we need to convert all the nulls data that were defined here
      // and send them  on the network as zero (numbers)
      // so it can nullified on the Golang side
      mutationSessionSetting({
        variables: sessionSetting,
      });
      setUpdateSessionSetting(false);
    }
  }, [updateSessionSetting]);

  useEffect(() => {
    if (fetchedSessionSetting) {
      setSessionSetting(fetchedSessionSetting);
      setDefaultSessionDuration(fetchedSessionSetting.defaultSessionDuration);
      setSystemCheckoutTriggerDuration(
        fetchedSessionSetting.systemCheckoutTriggerDuration
      );
    }
  }, [fetchedSessionSetting]);

  React.useEffect(() => {
    setSessionSetting((prevSet: SessionSetting) => ({
      ...prevSet,
      defaultSessionDuration: defaultSessionDuration,
      systemCheckoutTriggerDuration: systemCheckoutTriggerDuration,
    }));
  }, [defaultSessionDuration, systemCheckoutTriggerDuration]);

  if (sessionSetting.id === null) {
    return <></>;
  }

  const trans = custom ? "work-shifts-custom" : "work-shifts";

  return (
    <React.Fragment>
      <Title>{t(`${trans}.title`)}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t(`${trans}.description`)}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t(`${trans}.opening-hours`)}
            description={t(`${trans}.opening-hours-description`)}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <OrganizationOpeningHours
              sessionSetting={sessionSetting}
              setSessionSetting={setSessionSetting}
            />
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t(`${trans}.default-shift-duration`)}
            description={t(`${trans}.default-shift-duration-description`)}
          />

          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <DurationField
                originalDuration={fetchedSessionSetting.defaultSessionDuration}
                setDuration={setDefaultSessionDuration}
                defaultWithSeconds={false}
              />
            </Paper>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t(`${trans}.automatic-checkout`)}
            description={t(`${trans}.automatic-checkout-description`)}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <SessionAutomaticCheckout
              sessionSetting={sessionSetting}
              originalDuration={
                fetchedSessionSetting.systemCheckoutTriggerDuration
              }
              setSessionSetting={setSessionSetting}
              setSystemCheckoutTriggerDuration={
                setSystemCheckoutTriggerDuration
              }
            />
          </Grid>
        </Stack>
        <Stack alignItems="center"></Stack>
      </Grid>
    </React.Fragment>
  );
}

import { UpgradeButton } from "@/components/upgrade-button";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export function ZoneSettings({
  fetchedZoneSetting,
  setUpdateZoneSetting,
  updateZoneSetting,
  setSuccess,
  setError,
}) {
  const { t } = useTranslation(["organization", "misc"]);
  const [zoneSetting, setZoneSetting] = useState<ZoneSetting>({
    id: null,
    geofenceRadius: null,
    qrcodeAccessLocationMode: "",
  });
  const [mutationZoneSetting, { data, error }] =
    useMutation(UPDATE_ZONE_SETTING);

  useEffect(() => {
    if (data) setSuccess(true);
    if (error) setError(error);
  }, [data, error]);

  useEffect(() => {
    if (updateZoneSetting) {
      // we need to convert all the nulls data that were defined here
      // and send them  on the network as zero (numbers)
      // so it can nullified on the Golang side
      mutationZoneSetting({
        variables: zoneSetting,
      });
      setUpdateZoneSetting(false);
    }
  }, [updateZoneSetting]);

  useEffect(() => {
    if (fetchedZoneSetting) {
      setZoneSetting(fetchedZoneSetting);
    }
  }, [fetchedZoneSetting]);

  if (zoneSetting.id === null) {
    return <></>;
  }

  const marks = [
    {
      value: 10,
      label: "10m",
    },
    {
      value: 100,
      label: "100m",
    },
    {
      value: 200,
      label: "200m",
    },
    {
      value: 300,
      label: "300m",
    },
  ];

  return (
    <React.Fragment>
      <Title>{t("qrcodes.title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("qrcodes.description")}
      </Typography>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("qrcodes.geofence-radius")}
            description={t("qrcodes.geofence-radius-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1 }}
                alignItems="center"
              >
                <KeyboardDoubleArrowDownIcon />
                <Slider
                  aria-label="Meters"
                  defaultValue={zoneSetting.geofenceRadius || 0}
                  onChange={(event: Event, newValue: number | number[]) => {
                    setZoneSetting((prevSet: ZoneSetting) => ({
                      ...prevSet,
                      geofenceRadius: newValue as number,
                    }));
                  }}
                  getAriaValueText={(value: number) => `${value}m`}
                  valueLabelDisplay="auto"
                  shiftStep={25}
                  step={25}
                  marks={marks}
                  min={25}
                  max={300}
                />
                <KeyboardDoubleArrowUpIcon />
              </Stack>
            </Paper>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <SettingDescription
            title={t("qrcodes.access-location-mode")}
            description={t("qrcodes.access-location-mode-description")}
          />
          <Grid item xs={12} md={8} sx={{ mb: 2, mt: 2 }}>
            <Paper sx={{ p: 2 }}>
              <FormControl>
                <InputLabel>
                  {t("qrcodes.access-location-mode-label")}
                </InputLabel>
                <Select
                  value={zoneSetting.qrcodeAccessLocationMode || ""}
                  label={t("mqrcode-access-location-mode-label")}
                  required
                  onChange={(e) => {
                    setZoneSetting((prevSet: ZoneSetting) => ({
                      ...prevSet,
                      qrcodeAccessLocationMode: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value={"ASKED"}>
                    {t("qrcodes.access-location-mode-asked")}
                  </MenuItem>
                  <MenuItem value={"REQUIRED"}>
                    {t("qrcodes.access-location-mode-required")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Grid>
        </Stack>
      </Grid>
    </React.Fragment>
  );
}

export function SettingDescription({ title, description }) {
  return (
    <Grid item xs={12} md={4} sx={{ mb: 2, mt: 2 }}>
      <Typography component="h3" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography component="p" sx={{ color: "grey" }}>
        {description}
      </Typography>
    </Grid>
  );
}

export function OrganizationOpeningHours({
  sessionSetting,
  setSessionSetting,
}) {
  const { t } = useTranslation(["organization", "misc"]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <TimePicker
            ampm={false}
            label={t("organization.open-time")}
            value={
              sessionSetting.openTime
                ? dayjs(timeOfTheDayToDate(sessionSetting.openTime))
                : null
            }
            onChange={(event) => {
              const date = event?.toDate();
              let openTime: string;

              if (isNaN(Number(date))) {
                openTime = "";
              } else {
                openTime = dateToTimeOfTheDay(date);
              }
              setSessionSetting((prevSet: SessionSetting) => ({
                ...prevSet,
                openTime,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TimePicker
            ampm={false}
            label={t("organization.close-time")}
            value={
              sessionSetting.closeTime
                ? dayjs(timeOfTheDayToDate(sessionSetting.closeTime))
                : null
            }
            onChange={(event) => {
              const date = event?.toDate();
              let closeTime: string;

              if (isNaN(Number(date))) {
                closeTime = "";
              } else {
                closeTime = dateToTimeOfTheDay(date);
              }
              setSessionSetting((prevSet: SessionSetting) => ({
                ...prevSet,
                closeTime,
              }));
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export function SessionAutomaticCheckout({
  sessionSetting,
  originalDuration,
  setSessionSetting,
  setSystemCheckoutTriggerDuration,
}) {
  const { t } = useTranslation(["organization", "misc"]);

  return (
    <Paper sx={{ p: 2 }}>
      <DurationField
        originalDuration={originalDuration}
        setDuration={setSystemCheckoutTriggerDuration}
        defaultWithSeconds={false}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={sessionSetting.systemCheckoutTriggerOnClose}
              onChange={(event) => {
                setSessionSetting((prevSet: SessionSetting) => ({
                  ...prevSet,
                  systemCheckoutTriggerOnClose: event.target.checked,
                }));
              }}
            />
          }
          label={t("work-shifts.automatic-checkout-checkbox")}
        />
        <Typography component="p" sx={{ color: "grey" }}>
          {t("work-shifts.automatic-checkout-info")}
        </Typography>
      </FormGroup>
    </Paper>
  );
}
