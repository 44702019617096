import i18n from "@/i18n";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";

import * as React from "react";
import { useTranslation } from "react-i18next";

export default function Calendar({ defaultCalendar, setCalendar, loading }) {
  const { i18n } = useTranslation("misc");

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <DateCalendar
          onChange={(value) => setCalendar(value)}
          defaultValue={dayjs(defaultCalendar)}
          disabled={loading}
          disableFuture
        />
      </LocalizationProvider>
    </React.Fragment>
  );
}
