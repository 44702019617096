import { Button, CircularProgress } from "@mui/material";

const defaultClick: React.MouseEventHandler<HTMLButtonElement> = () => {};

export default function LoadingButton({
  loading,
  onClick = defaultClick,
  startIcon = <></>,
  disabled,
  text,
  ...props
}) {
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      onClick={onClick}
      startIcon={loading ? <></> : startIcon}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <CircularProgress color="primary" size="2em" /> : text}
    </Button>
  );
}
