import { CircularProgress, Grid, Stack } from "@mui/material";

export default function Loading({ extra = "" }) {
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      sx={{ margin: "auto", paddingTop: "45vh" }}
    >
      <Grid item xs={12} sm={4}>
        <CircularProgress color="primary" />
        {extra}
      </Grid>
    </Stack>
  );
}
