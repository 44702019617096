import AstroWondering from "@/components/astro-wondering";
import { Copyright } from "@/components/copyright";
import { capitalizeFirst } from "@/helpers/format";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default function PageError({ error, extra = <></> }) {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PriorityHighIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {capitalizeFirst(error)}
        </Typography>
        <AstroWondering />
      </Box>
      {extra}
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
