import { downloadImage } from "@/helpers";
import { SensitiveData } from "./sensitive-data";

export function QrcodeImage({ image, clickToDownload = false, mini = false }) {
  if (!image) return <div></div>;

  let style = {};
  if (clickToDownload) {
    style = { cursor: "pointer" };
  }

  let imgStyle = {};
  if (mini) {
    imgStyle = { maxWidth: "80px" };
  }

  return (
    <div
      style={style}
      className="m-auto"
      onClick={() => {
        if (!clickToDownload) return;
        downloadImage(image);
      }}
    >
      <SensitiveData>
        <img src={image} alt="" style={imgStyle} />
      </SensitiveData>
    </div>
  );
}
