import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import GlobalSmallLoading from "@/components/global-small-loading";
import PartialLoading from "@/components/partial-loading";
import { findAndTranslateErrors } from "@/helpers/format";
import {
  getParams,
  rawURLReplace,
  replacePathWith,
} from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CREATE_ZONE = gql`
  mutation CreateMovingZone($name: String!) {
    CreateMovingZone(name: $name) {
      id
    }
  }
`;

const GET_MYSELF_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      name
    }
  }
`;

export function CreateZoneButton({ name, primary, setError, disabled }) {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  const [mutationCreateZone, { data, loading, error }] =
    useMutation(CREATE_ZONE);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationCreateZone({
      variables: { primary, name },
    });
  };

  if (error) {
    // setError(t("zones.create.error-on-moving-creation"));
    setError(findAndTranslateErrors({ error, t }));
  }

  if (loading) {
    return <PartialLoading />;
  }

  if (data) {
    // primary is not just used to know where to redirect the person
    if (primary) {
      replacePathWith(navigate, "/organization/dashboard/live");
    } else {
      // otherwise it doesn't refresh the QR Codes (could be improved)
      rawURLReplace("/organization/dashboard/zones");
    }
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  }

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mb: 2 }}
      disabled={disabled}
      onClick={handleSubmit}
    >
      {t("zones.create.moving-submit")}
    </Button>
  );
}

export default function CreateZone() {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [organization, setOrganization] = useState<{
    id: null | string;
    slug: null | string;
    name: null | string;
  }>({ id: null, slug: null, name: null });

  const [name, setZoneName] = useState<string>("");
  const [primary, setPrimary] = useState<boolean>(false);
  const getMyOrganization = useQuery(GET_MYSELF_ORGANIZATION);

  const confirmGeolocation = () => {
    replacePathWith(
      navigate,
      `/organization/zones/create/fixed?primary=${primary}&name=${name}`
    );
  };

  const clickGeolocation: React.ReactElement = (
    <Grid item xs={12} textAlign="center" justifyContent="center">
      <Button style={{ textTransform: "unset" }} onClick={confirmGeolocation}>
        {t("zones.create.change-to-fixed-link")}
      </Button>
    </Grid>
  );

  useEffect(() => {
    if (getMyOrganization.data) {
      if (organization.id === null)
        setOrganization(getMyOrganization.data.MyOrganization);
    }
  }, [getMyOrganization]);

  useEffect(() => {
    if (name === "" && organization.name) {
      const params = getParams();
      if (params.get("name")) {
        setZoneName(String(params.get("name")));
      } else if (primary) {
        setZoneName(`${organization.name} HQ`);
      }
    }
  }, [organization]);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  if (getMyOrganization.loading || !organization) return <GlobalLoading />;
  if (getMyOrganization.error) {
    return <NotFound error={getMyOrganization.error} />;
  }

  const title = primary
    ? t("zones.create.set-primary-moving-title", { name: organization.name })
    : t("zones.create.set-moving-title", { name: organization.name });

  const formCompleted = name.length > 0;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <QrCodeIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
          {t("zones.create.set-moving-description")}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <TextField
                name="name"
                value={name}
                onChange={(e) => setZoneName(e.target.value)}
                required
                fullWidth
                id="zoneName"
                label={t("zones.create.zone-name")}
                autoComplete="off"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <CreateZoneButton
                name={name}
                primary={primary}
                setError={setError}
                disabled={!formCompleted}
              />
            </Grid>
            {clickGeolocation}
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
