import { Copyright } from "@/components/copyright";
import { FormError, FormErrorComponent } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import LoadingButton from "@/components/loading-button";
import { PhoneField } from "@/components/phone-field";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { hasIdentity, setIdentity, setLanguage } from "@/helpers/identity";
import {
  addParamsToPath,
  getAfterAuthPath,
  getParams,
  rawURLAssign,
} from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DEFAULT_AFTER_SIGN_UP_URL = "/";

const MEMBER_SIGNUP = gql`
  mutation MemberSignup(
    $organizationID: UUID!
    $phone: String
    $email: String
    $firstName: String!
    $lastName: String!
    $deviceInput: DeviceInput!
  ) {
    MemberSignup(
      organizationID: $organizationID
      phone: $phone
      email: $email
      firstName: $firstName
      lastName: $lastName
      deviceInput: $deviceInput
    ) {
      id
      phone
      email
      role
      accessToken
    }
  }
`;

const GET_ORGANIZATION = gql`
  query GetOrganization($organizationID: UUID!) {
    GetOrganization(id: $organizationID) {
      id
      name
      slug
    }
  }
`;

export default function MemberSignUp() {
  const { t, i18n } = useTranslation("member");
  const params = getParams();

  const [organization, setOrganization] = useState({
    id: "",
    slug: "",
    name: "",
  });
  const [formData, setFormData] = useState({
    phone: params.get("phone") || "",
    email: params.get("email") || "",
    firstName: "",
    lastName: "",
  });
  const [mutationMemberSignUp, { data, loading, error }] =
    useMutation(MEMBER_SIGNUP);

  let { organizationID } = useParams();
  if (!organizationID) organizationID = params.get("organization_id") || "";

  const getOrganization = useQuery(GET_ORGANIZATION, {
    variables: { organizationID: organizationID },
  });

  useEffect(() => {
    if (data) {
      setLanguage(data.MemberSignup.displayLanguage, i18n);
      setIdentity({
        id: data.MemberSignup.id,
        accessToken: data.MemberSignup.accessToken,
        role: data.MemberSignup.role,
        organizationID: organization.id,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        const redirectURL = getAfterAuthPath({}) || DEFAULT_AFTER_SIGN_UP_URL;
        rawURLAssign(redirectURL);
      })();
    }
  }, [data]);

  if (getOrganization.loading) return <GlobalLoading />;
  if (getOrganization.data) {
    if (organization.id === "")
      setOrganization(getOrganization.data.GetOrganization);
  }
  if (getOrganization.error) {
    return <NotFound error={getOrganization.error} />;
  }

  const formCompleted =
    formData.firstName.length > 0 &&
    formData.lastName.length > 0 &&
    formData.phone.length > 0;

  const disabled = !formCompleted;
  const method = params.get("method") || "";
  const showEmail = method === "email";

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    // sometimes there will be left over in the system
    // so we just trick it and not send
    // if not shown on the front-end
    let email = formData.email || null;
    if (!showEmail) {
      email = null;
    }

    const deviceInput = getDeviceInfo();

    mutationMemberSignUp({
      variables: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone || null,
        email,
        organizationID: organization.id,
        deviceInput,
      },
    });
  };

  let compError: JSX.Element = <></>;
  let customError: string = "";
  let hasCompError: boolean = false;

  if (error) {
    if (graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN_ORGANIZATION")) {
      hasCompError = true;
      const link: string = `/authentication/sign-in?phone=${encodeURIComponent(
        formData.phone
      )}`;
      compError = (
        <Trans t={t} i18nKey="sign-up.phone-already-taken">
          text <a href={link}>text</a>
        </Trans>
      );
    }
    if (graphQLErrorCode(error, "EMAIL_ALREADY_TAKEN_OTHER_ORGANIZATION")) {
      customError = t("sign-up.phone-already-taken-other-organization");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("sign-up.sign-up-in-organization", { name: organization.name })}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          {hasCompError ? (
            <FormErrorComponent error={compError} sx={{ mb: 3 }} />
          ) : (
            <FormError error={customError || error} sx={{ mb: 3 }} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PhoneField
                required
                fullWidth
                id="phone"
                type="phone"
                label={t("sign-up.phone")}
                name="phone"
                value={formData.phone}
                onChange={(phone) => {
                  setFormData((prevFormData) => ({ ...prevFormData, phone }));
                }}
                autoComplete="phone"
              />
            </Grid>
            {/* It's not mandatory, but if he tried to sign-in with email we can give him the opportunity to fill it in */}
            {showEmail ? (
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  value={formData.email || ""}
                  onChange={handleChange}
                  label={t("sign-up.email")}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <TextField
                autoFocus
                autoComplete="given-name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                fullWidth
                id="firstName"
                label={t("sign-up.firstname")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="family-name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                fullWidth
                id="lastName"
                label={t("sign-up.lastname")}
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
            text={t("sign-up.submit")}
            fullWidth
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                style={{ textTransform: "unset" }}
                onClick={() => {
                  const params = {
                    phone: formData.phone,
                    email: formData.email,
                    method,
                    organization_id: organizationID || "",
                  };

                  const path = addParamsToPath(
                    "/authentication/sign-in",
                    params
                  );
                  rawURLAssign(path);
                }}
              >
                {t("sign-up.already-have-an-account")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
