import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import GlobalSmallLoading from "@/components/global-small-loading";
import { GoogleMapOneDraggableMarker } from "@/components/google-map";
import LoadingButton from "@/components/loading-button";
import PartialLoading from "@/components/partial-loading";
import { findAndTranslateErrors } from "@/helpers/format";
import {
  GEOLOCATION_OPTIONS,
  isGeoGranted,
  setGeoGranted,
  unsetGeoGranted,
} from "@/helpers/geo";
import {
  getParams,
  rawURLReplace,
  replacePathWith,
} from "@/helpers/navigation";
import AskGeolocation from "@/pages/ask-geolocation";
import { default as PageError } from "@/pages/error";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CREATE_REGULAR_ZONE = gql`
  mutation CreateFixedZone(
    $latitude: Float!
    $longitude: Float!
    $accuracy: Float!
    $name: String!
  ) {
    CreateFixedZone(
      latitude: $latitude
      longitude: $longitude
      accuracy: $accuracy
      name: $name
    ) {
      id
    }
  }
`;

const GET_MYSELF_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      name
    }
  }
`;

export function CreatePrimaryLocationButton({
  latitude,
  longitude,
  accuracy,
  name,
  primary,
  setDone,
  setError,
  disabled,
}) {
  const { t } = useTranslation("organization");

  const [mutationCreateZone, { data, loading, error }] =
    useMutation(CREATE_REGULAR_ZONE);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationCreateZone({
      variables: { latitude, longitude, accuracy, primary, name },
    });
  };

  useEffect(() => {
    if (error) {
      setError(findAndTranslateErrors({ error, t }));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setDone(true);
    }
  }, [data]);

  if (accuracy === null) {
    return <PartialLoading />;
  }

  return (
    <LoadingButton
      loading={loading}
      disabled={disabled || accuracy === null}
      onClick={handleSubmit}
      text={t("zones.create.fixed-submit")}
      fullWidth
    />
  );
}

export default function CreateFixedZone() {
  const { t } = useTranslation(["organization", "misc"]);
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [done, setDone] = useState<boolean>(false);
  const [organization, setOrganization] = useState<{
    id: null | string;
    slug: null | string;
    name: null | string;
  }>({ id: null, slug: null, name: null });
  const [geolocation, setGeolocation] = useState<{
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
  }>({ latitude: null, longitude: null, accuracy: null });
  const [stateGeolocation, setStateGeolocation] = useState<string>("");
  const [name, setZoneName] = useState<string>("");
  const [primary, setPrimary] = useState<boolean>(false);
  const getMyOrganization = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    if (getMyOrganization.data) {
      if (organization.id === null)
        setOrganization(getMyOrganization.data.MyOrganization);
      if (geolocation.latitude === null) {
        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then((response) => {
              setStateGeolocation(response.state);
            });
        } else if (navigator.geolocation) {
          setStateGeolocation("granted");
        } else {
          setStateGeolocation("denied");
        }
      }
    }
  }, [getMyOrganization]);

  const confirmNoGeolocation = () => {
    replacePathWith(
      navigate,
      `/organization/zones/create/moving?primary=${primary}&name=${name}`
    );
  };

  const clickNoGeolocation: React.ReactElement = (
    <Grid item xs={12} textAlign="center" justifyContent="center">
      <Button style={{ textTransform: "unset" }} onClick={confirmNoGeolocation}>
        {t("zones.create.no-geolocation")}
      </Button>
    </Grid>
  );

  const clickMovingZone: React.ReactElement = (
    <Grid item xs={12} textAlign="center" justifyContent="center">
      <Button style={{ textTransform: "unset" }} onClick={confirmNoGeolocation}>
        {t("zones.create.change-to-moving-link")}
      </Button>
    </Grid>
  );

  useEffect(() => {
    if (name === "" && organization.name) {
      const params = getParams();
      if (params.get("name")) {
        setZoneName(String(params.get("name")));
      } else if (primary) {
        setZoneName(`${organization.name} HQ`);
      }
    }
  }, [organization]);

  useEffect(() => {
    const params = getParams();
    setPrimary(String(params.get("primary")) === "true");
  }, []);

  useEffect(() => {
    if (stateGeolocation === "granted") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setGeoGranted();
            setGeolocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            });
          },
          () => {
            unsetGeoGranted();
          },
          GEOLOCATION_OPTIONS
        );
      }
    }
  }, [stateGeolocation]);

  // I had to actually make a whole "done" state because inside the component
  // it would crash completely and have infinite loop redirect on iPad
  if (done) {
    if (primary) {
      rawURLReplace("/organization/dashboard/live");
    } else {
      rawURLReplace("/organization/dashboard/zones");
    }

    return <GlobalSmallLoading />;
  }

  if (!navigator.geolocation) {
    return (
      <PageError
        error={t("geolocation.not-supported", { ns: "misc" })}
        extra={clickNoGeolocation}
      />
    );
  }

  if (
    stateGeolocation === "prompt" ||
    // Chrome iOS doesn't respect the "granted" state so we have to store the response when granting permission
    // https://stackoverflow.com/questions/78258245/why-is-chrome-ios-sending-prompt-when-geolocation-is-granted
    (!isGeoGranted() && stateGeolocation === "prompt")
  ) {
    return (
      <AskGeolocation
        message={t("geolocation.need-for-creation", { ns: "misc" })}
        extra={clickNoGeolocation}
        setGeolocation={setGeolocation}
        setStateGeolocation={setStateGeolocation}
      />
    );
  } else if (stateGeolocation === "denied") {
    unsetGeoGranted();
    return (
      <PageError
        error={t("geolocation.denied", { ns: "misc" })}
        extra={clickNoGeolocation}
      />
    );
  }

  if (getMyOrganization.loading || !organization) return <GlobalLoading />;
  if (getMyOrganization.error) {
    return <NotFound error={getMyOrganization.error} />;
  }

  let title: React.ReactElement;
  let subtitle: React.ReactElement;
  if (primary) {
    title = (
      <Typography component="h1" variant="h5">
        {t("zones.create.set-primary-fixed-title", {
          name: organization.name,
        })}
      </Typography>
    );
    subtitle = (
      <Typography variant="subtitle1">
        {t("zones.create.set-primary-fixed-description")}
      </Typography>
    );
  } else {
    title = (
      <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
        {t("zones.create.set-fixed-title", { name: organization.name })}
      </Typography>
    );

    subtitle = (
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        {t("zones.create.set-fixed-description")}
      </Typography>
    );
  }

  const formCompleted = name.length > 0;
  const disabled = !formCompleted;

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <QrCodeIcon />
        </Avatar>
        {title}
        {subtitle}
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                value={name}
                onChange={(e) => setZoneName(e.target.value)}
                required
                fullWidth
                id="zoneName"
                autoComplete="off"
                label={t("zones.create.zone-name")}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <GoogleMapOneDraggableMarker
                geolocation={geolocation}
                setGeolocation={setGeolocation}
              />
            </Grid>
            <Grid item xs={12}>
              <CreatePrimaryLocationButton
                latitude={geolocation.latitude}
                longitude={geolocation.longitude}
                accuracy={geolocation.accuracy}
                name={name}
                primary={primary}
                disabled={disabled}
                setError={setError}
                setDone={setDone}
              />
            </Grid>
            {clickMovingZone}
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
