import { NavigateFunction } from "react-router-dom";

export function setAfterAuthPath(
  afterAuthURL: string,
  options = { forceSet: false }
): void {
  const forceSet = options.forceSet;
  const existingAuthURL = sessionStorage.getItem("afterAuthURL");
  if (existingAuthURL && !forceSet) return;

  return sessionStorage.setItem("afterAuthURL", afterAuthURL);
}

export function getAfterAuthPath({
  clear = true,
}: {
  clear?: boolean;
}): string | null {
  const url = sessionStorage.getItem("afterAuthURL");
  if (!url) return null;

  if (clear) sessionStorage.removeItem("afterAuthURL");
  return url;
}

export function assignPathWith(navigate: NavigateFunction, path: string) {
  navigate(path);
}

export function replacePathWith(navigate: NavigateFunction, path: string) {
  navigate(path, { replace: true });
}

export function rawURLAssign(url: string) {
  window.location.assign(url);
}

export function rawURLReplace(url: string) {
  window.location.replace(url);
}

export function currentRoute(route: string): boolean {
  const path = window.location.pathname;
  return path.startsWith(route);
}

export function getParams() {
  return new URLSearchParams(window.location.search);
}

export function addParamsToPath(
  path: string,
  params: Record<string, string>
): string {
  const urlObject = new URL(path, currentLocation());

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      urlObject.searchParams.set(key, value);
    }
  }

  return keepPath(urlObject.toString());
}

export function keepPath(url) {
  const urlObject = new URL(url, currentLocation());
  const path = urlObject.pathname + urlObject.search;
  return path;
}

export function currentLocation() {
  const currentProtocol = window.location.protocol;
  const currentDomain = window.location.hostname;
  const currentPort = window.location.port;
  const baseURL = `${currentProtocol}//${currentDomain}${
    currentPort ? `:${currentPort}` : ""
  }`;
  return baseURL;
}

export function updateSearchParams(params: { [key: string]: string }): void {
  const urlSearchParams = new URLSearchParams(window.location.search);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      urlSearchParams.set(key, params[key]);
    }
  }

  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;
  window.history.replaceState({}, "", newUrl);
}
