import AstroQuestion from "@/components/astro-question";
import { capitalizeFirst } from "@/helpers/format";
import { relativeDate } from "@/helpers/time";
import { Alert, Device } from "@/interfaces";
import { tableContainerSx, tableHeadSx, tableSx } from "@/theme";
import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";

import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabletMacIcon from "@mui/icons-material/TabletMac";

export function ResolutionChip({ device }: { device: Device }) {
  // TODO: add screenRatio and zoom details
  // TODO: maybe add touch screen too
  return (
    <>
      {device.screenWidth} x {device.screenHeight}
    </>
  );
}

export function DeviceChip({
  device,
  alerts = [],
  tooltip,
}: {
  device: Device;
  alerts?: Alert[];
  tooltip: boolean;
}) {
  const { t } = useTranslation("organization");
  if (!device) return <></>;

  let icon: React.JSX.Element = <></>;
  let type: string = "";
  switch (device.deviceType) {
    case "DESKTOP":
      icon = <LaptopMacIcon />;
      type = "Webapp";
      break;
    case "MOBILE":
      icon = <PhoneIphoneIcon />;
      type = "Webapp";
      break;
    case "NATIVE":
      icon = <PhoneIphoneIcon />;
      type = "Application";
      break;
    case "TABLET":
      icon = <TabletMacIcon />;
      type = "Webapp";
      break;
    default:
      break;
  }

  const readName: string[] = [];

  if (device.deviceBrand) {
    readName.push(device.deviceBrand);
  }

  readName.push(device.deviceName || device.platformName);

  const color = "default";

  const chip = (
    <Chip
      sx={{
        fontWeight: "bold",
      }}
      label={readName.join(" ")}
      color={color}
      icon={icon}
    />
  );

  let browser: string = "";
  if (device.browserName && device.browserVersion)
    browser = `${device.browserName} ${device.browserVersion}`;

  let platform: string = "";
  if (device.platformName && device.platformVersion)
    platform = `${device.platformName} ${device.platformVersion}`;

  if (tooltip) {
    const tooltipText = [type, platform, browser].join(" / ");
    return (
      <Tooltip title={tooltipText} arrow placement="top">
        {chip}
      </Tooltip>
    );
  } else {
    return chip;
  }
}

export function List({ devices }: { devices: Device[] }) {
  const { i18n, t } = useTranslation("organization");

  if (devices == null || devices.length === 0)
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid container alignContent="center">
          <Grid item xs={12} lg={4} sx={{ mb: 3 }} margin="auto">
            <AstroQuestion />
            <Typography>{t("devices.nothing-to-show")}</Typography>
          </Grid>
        </Grid>
      </Stack>
    );

  return (
    <React.Fragment>
      <Table size="small" sx={tableSx}>
        <TableHead>
          <TableRow key="0">
            <TableCell sx={tableHeadSx}>{t("devices.list.name")}</TableCell>
            <TableCell sx={tableHeadSx}>
              {t("devices.list.last-used-at")}
            </TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.platform")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.browser")}</TableCell>
            <TableCell sx={tableHeadSx}>
              {t("devices.list.resolution")}
            </TableCell>
            <TableCell sx={tableHeadSx}>
              {t("devices.list.memory-concurrency")}
            </TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.gpu")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.network")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.ip")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.timezone")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("devices.list.country")}</TableCell>
            <TableCell sx={tableHeadSx}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device) => (
            <TableRow key={device.id}>
              <TableCell>
                <DeviceChip device={device} tooltip={false} />
              </TableCell>
              <TableCell>
                {capitalizeFirst(relativeDate(device.lastUsedAt, i18n))}
              </TableCell>
              <TableCell>
                {device.platformName} {device.platformVersion}
              </TableCell>
              <TableCell>
                {device.browserName} {device.browserVersion}
              </TableCell>
              <TableCell>
                <ResolutionChip device={device} />
              </TableCell>
              <TableCell>
                {device.deviceMemory}GB / {device.deviceConcurrency}
              </TableCell>
              <TableCell>{device.GPU}</TableCell>
              <TableCell>
                {device.networkBandwidth}Mb/s ({device.networkRTT}ms)
              </TableCell>
              <TableCell>{device.IPAddress}</TableCell>
              <TableCell>{device.timezone}</TableCell>
              <TableCell>{device.country}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default function DevicesList({ devices }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List devices={devices} />
      </TableContainer>
    </React.Fragment>
  );
}
