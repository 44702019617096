import { blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: "#f4511e",
    },
    // warning: {
    //   main: pink[600],
    // },
    error: {
      main: red[600],
    },
    info: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: [
      // "-apple-system",
      // "BlinkMacSystemFont",
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Tahoma",
      // "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(","),
    h4: {
      fontSize: 24,
    },
    button: {
      //fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
});

export const tableSx = { m: 0, p: 0 };
export const tableContainerSx = {
  display: "flex",
  flexDirection: "column",
};
export const tableHeadSx = {
  fontWeight: "bold",
  pt: 2,
  pb: 2,
};

export const dialogStyle = {
  backdropFilter: "blur(3px)",
};
