import { Identity } from "@/interfaces";

// Define enums for Permissions and Resources
export enum Permission {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  READ = "READ",
}

export enum Resource {
  MYSELF = "MYSELF",
  DASHBOARD = "DASHBOARD",
  COMMENTS = "COMMENTS",
  ENTRYPOINT = "ENTRYPOINT",
  BILLING = "BILLING",
  SUPPORT = "SUPPORT",
}

// Define interface for ResourcePermissions
interface ResourcePermissions {
  resource: Resource;
  permissions: Permission[];
}

// Define interface for RolePermissions
interface RolePermissions {
  TECH: ResourcePermissions[];
  ADMIN: ResourcePermissions[];
  VIEWER: ResourcePermissions[];
  USER: ResourcePermissions[];
}

// Define constants for fullAccess and noAccess
const fullAccess: Permission[] = [
  Permission.CREATE,
  Permission.UPDATE,
  Permission.DELETE,
  Permission.READ,
];

const noAccess: Permission[] = [];

// Define the rolePermissions object based on the interfaces
const rolePermissions: RolePermissions = {
  TECH: [
    { resource: Resource.MYSELF, permissions: fullAccess },
    { resource: Resource.DASHBOARD, permissions: fullAccess },
    { resource: Resource.COMMENTS, permissions: fullAccess },
    { resource: Resource.ENTRYPOINT, permissions: fullAccess },
    { resource: Resource.BILLING, permissions: fullAccess },
    { resource: Resource.SUPPORT, permissions: fullAccess },
  ],
  ADMIN: [
    { resource: Resource.MYSELF, permissions: fullAccess },
    { resource: Resource.DASHBOARD, permissions: fullAccess },
    { resource: Resource.COMMENTS, permissions: fullAccess },
    { resource: Resource.ENTRYPOINT, permissions: fullAccess },
    { resource: Resource.BILLING, permissions: fullAccess },
    { resource: Resource.SUPPORT, permissions: fullAccess },
  ],
  VIEWER: [
    { resource: Resource.MYSELF, permissions: fullAccess },
    { resource: Resource.ENTRYPOINT, permissions: [Permission.READ] },
    { resource: Resource.COMMENTS, permissions: fullAccess },
    { resource: Resource.DASHBOARD, permissions: [Permission.READ] },
    { resource: Resource.BILLING, permissions: noAccess },
    { resource: Resource.SUPPORT, permissions: fullAccess },
  ],
  USER: [
    { resource: Resource.MYSELF, permissions: fullAccess },
    { resource: Resource.ENTRYPOINT, permissions: [Permission.READ] },
    { resource: Resource.COMMENTS, permissions: noAccess },
    { resource: Resource.DASHBOARD, permissions: noAccess },
    { resource: Resource.BILLING, permissions: noAccess },
    { resource: Resource.SUPPORT, permissions: fullAccess },
  ],
};

export function permitAccess({
  role,
  resource,
  permission,
}: {
  role: Identity["role"];
  resource: Resource;
  permission: Permission;
}): boolean {
  const permissions = rolePermissions[role];
  if (!permissions) {
    return false;
  }

  const permForResource =
    permissions.filter((perm) => perm.resource === resource)?.[0] ?? null;
  if (!permForResource) {
    return false;
  }

  for (const p of permForResource.permissions) {
    if (p === permission) {
      return true;
    }
  }

  return false;
}
