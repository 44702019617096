import { FormInfo } from "@/components/form";
import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { PhoneField } from "@/components/phone-field";
import { eventBusEmit } from "@/helpers/event-bus";
import { findAndTranslateErrors } from "@/helpers/format";
import { getIdentity, setLanguage } from "@/helpers/identity";
import { assignPathWith, rawURLReplace } from "@/helpers/navigation";
import { getDateAndTime } from "@/helpers/time";
import Title from "@/pages/organization/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SessionSettings } from "../../settings";

const GET_MEMBER = gql`
  query GetMember($id: UUID!) {
    GetMember(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role
      displayLanguage
      accessRevokedAt
      nationalID
      sessionSetting {
        id
        openTime
        closeTime
        defaultSessionDuration
        systemCheckoutTriggerDuration
        systemCheckoutTriggerOnClose
      }
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation UpdateMember(
    $id: UUID!
    $firstName: String
    $lastName: String
    $role: Role!
    $displayLanguage: String!
    $email: String
    $phone: String
    $nationalID: String
  ) {
    UpdateMember(
      id: $id
      firstName: $firstName
      lastName: $lastName
      role: $role
      displayLanguage: $displayLanguage
      email: $email
      phone: $phone
      nationalID: $nationalID
    ) {
      id
      displayLanguage
    }
  }
`;

const CREATE_MEMBER_SESSION_SETTING = gql`
  mutation CreateMemberSessionSetting($id: UUID!) {
    CreateMemberSessionSetting(id: $id) {
      id
      openTime
      closeTime
      defaultSessionDuration
      systemCheckoutTriggerDuration
      systemCheckoutTriggerOnClose
    }
  }
`;

const DELETE_MEMBER = gql`
  mutation DeleteMember($id: UUID!) {
    DeleteMember(id: $id)
  }
`;

const REVOKE_ACCESS = gql`
  mutation RevokeAccess($id: UUID!) {
    RevokeAccess(id: $id) {
      id
    }
  }
`;

const ALLOW_ACCESS = gql`
  mutation AllowAccess($id: UUID!) {
    AllowAccess(id: $id) {
      id
    }
  }
`;

const FORCE_SIGNOUT = gql`
  mutation ForceSignout($id: UUID!) {
    ForceSignout(id: $id) {
      id
    }
  }
`;

export default function EditMember() {
  const { t, i18n } = useTranslation(["organization", "misc"]);
  const navigate = useNavigate();
  const [, setID] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [displayLanguage, setDisplayLanguage] = useState<string | null>("");
  const [email, setEmail] = useState<string>("");
  // keep it null to make a difference
  // between loaded but empty and loading
  const [phone, setPhone] = useState<string>("");
  const [nationalID, setNationalID] = useState<string>("");
  const [accessRevoked, setAccessRevoked] = useState<boolean>(false);
  const [sessionSetting, setSessionSetting] = useState(null);

  const { memberID } = useParams();
  const getMember = useQuery(GET_MEMBER, {
    variables: { id: memberID },
    fetchPolicy: "no-cache",
  });
  const [mutationUpdateMember, { data, error }] = useMutation(UPDATE_MEMBER);
  const [mutationRevokeAccess, revokeAccess] = useMutation(REVOKE_ACCESS);
  const [mutationDeleteMember, deleteMember] = useMutation(DELETE_MEMBER);
  const [mutationCreateMemberSessionSetting, createMemberSessionSetting] =
    useMutation(CREATE_MEMBER_SESSION_SETTING);
  const [mutationAllowAccess, allowAccess] = useMutation(ALLOW_ACCESS);
  const [mutationForceSignout, forceSignout] = useMutation(FORCE_SIGNOUT);

  const [sessionSettingError, setSessionSettingError] = useState<string>("");
  const [sessionSettingSuccess, setSessionSettingSuccess] =
    useState<boolean>(false);
  const [updateSessionSetting, setUpdateSessionSetting] =
    useState<boolean>(false);

  const isMyself = getIdentity().id === memberID;

  useEffect(() => {
    const possibleErrors =
      error ||
      forceSignout.error ||
      revokeAccess.error ||
      deleteMember.error ||
      sessionSettingError ||
      createMemberSessionSetting.error;
    const errorDisplay: string = findAndTranslateErrors({
      error: possibleErrors,
      t,
    });
    let successDisplay: string = "";
    if (data) {
      successDisplay = t("members.edit.updated");
    } else if (forceSignout.data)
      successDisplay = t("members.edit.force-sign-out-successful");
    else if (allowAccess.data) {
      successDisplay = t("members.edit.access-allowed");
    } else if (revokeAccess.data) {
      successDisplay = t("members.edit.access-revoked");
    } else if (createMemberSessionSetting.data) {
      setSessionSetting(
        createMemberSessionSetting.data.CreateMemberSessionSetting
      );
      successDisplay = t("members.edit.session-setting-created");
    } else if (sessionSettingSuccess) {
      successDisplay = t("members.edit.session-setting-updated");
    } else if (deleteMember.data)
      // in this case we should leave the page entirely
      // since the employee does not exist anymore.
      rawURLReplace("/organization/dashboard/members");

    if (successDisplay) {
      eventBusEmit({ type: "form-success", payload: successDisplay });
    }
    if (errorDisplay) {
      eventBusEmit({ type: "form-error", payload: errorDisplay });
    }
  }, [
    t,
    data,
    error,
    forceSignout.error,
    revokeAccess.error,
    deleteMember.error,
    forceSignout.data,
    revokeAccess.data,
    allowAccess.data,
    deleteMember.data,
    createMemberSessionSetting.error,
    createMemberSessionSetting.data,
    sessionSettingError,
  ]);

  useEffect(() => {
    if (data) {
      const newLanguage = data.UpdateMember.displayLanguage;
      const reloadPage =
        i18n.language !== newLanguage && newLanguage !== "system";
      setLanguage(newLanguage, i18n);
      if (newLanguage !== "system") i18n.changeLanguage(newLanguage);
      if (reloadPage) {
        window.location.reload();
      }
    }
  }, [data]);

  useEffect(() => {
    if (getMember.data) {
      setID(getMember.data.GetMember.id);
      setFirstName(getMember.data.GetMember.firstName);
      setLastName(getMember.data.GetMember.lastName);
      setEmail(getMember.data.GetMember.email);
      setPhone(getMember.data.GetMember.phone);
      setRole(getMember.data.GetMember.role);
      setDisplayLanguage(getMember.data.GetMember.displayLanguage);
      setNationalID(getMember.data.GetMember.nationalID);
      setAccessRevoked(!!getMember.data.GetMember.accessRevokedAt);
      setSessionSetting(getMember.data.GetMember.sessionSetting);

      if (
        getMember.data.GetMember.firstName &&
        getMember.data.GetMember.lastName
      ) {
        const pageName: string = `${getMember.data.GetMember.firstName} ${getMember.data.GetMember.lastName}`;
        eventBusEmit({ type: "page-name", payload: pageName });
      }

      const rightMenu: JSX.Element = (
        <React.Fragment>
          <IconButton
            color="inherit"
            aria-label={t("members.metrics-button")}
            onClick={() => {
              assignPathWith(
                navigate,
                `/organization/dashboard/members/${getMember.data.GetMember.id}/metrics`
              );
            }}
          >
            <BarChartIcon />
          </IconButton>
        </React.Fragment>
      );
      eventBusEmit({ type: "right-menu", payload: rightMenu });
    }
  }, [getMember.data, navigate, t]);

  if (getMember.loading) return <Loading />;
  if (getMember.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleCreateMemberSessionSetting: React.MouseEventHandler<
    HTMLButtonElement
  > = (event) => {
    event.preventDefault();

    mutationCreateMemberSessionSetting({
      variables: {
        id: memberID,
      },
    });
  };

  const handleDeleteMember: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationDeleteMember({
      variables: {
        id: memberID,
      },
    });
  };

  const handleRevokeAccess: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationRevokeAccess({
      variables: {
        id: memberID,
      },
    });
    setAccessRevoked(true);
  };

  const handleAllowAccess: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationAllowAccess({
      variables: {
        id: memberID,
      },
    });
    setAccessRevoked(false);
  };

  const handleForceSignout: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationForceSignout({
      variables: {
        id: memberID,
      },
    });
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setUpdateSessionSetting(true);
    mutationUpdateMember({
      variables: {
        id: memberID,
        firstName,
        lastName,
        role,
        displayLanguage,
        email,
        phone,
        nationalID,
      },
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Title>{t("members.edit.title")}</Title>

          {getMember.data.GetMember.accessRevokedAt ? (
            <FormInfo
              message={t("members.access-revoked-notice", {
                date: getDateAndTime(getMember.data.GetMember.accessRevokedAt),
              })}
            />
          ) : (
            ""
          )}

          {isMyself ? <FormInfo message={t("members.editing-yourself")} /> : ""}

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Title>{t("members.edit.identity-title")}</Title>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("members.edit.identity-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      fullWidth
                      id="firstName"
                      label={t("members.edit.firstname")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      fullWidth
                      id="lastName"
                      label={t("members.edit.lastname")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="nationalID"
                      value={nationalID}
                      onChange={(e) => setNationalID(e.target.value)}
                      fullWidth
                      id="nationalID"
                      label={t("members.edit.national-id")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Stack>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Title>{t("members.edit.system-access-title")}</Title>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("members.edit.system-access-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      id="email"
                      label={t("members.edit.email")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneField
                      autoComplete="off"
                      name="phone"
                      value={phone}
                      onChange={(phone) => {
                        setPhone(phone);
                      }}
                      fullWidth
                      id="phone"
                      label={t("members.edit.phone-number")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t("members.edit.role")}</InputLabel>
                      <Select
                        value={role}
                        label={t("members.edit.role")}
                        required
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      >
                        <MenuItem value={"USER"}>
                          {t("members.user-role")}
                        </MenuItem>
                        <MenuItem value={"VIEWER"}>
                          {t("members.viewer-role")}
                        </MenuItem>
                        <MenuItem value={"ADMIN"}>
                          {t("members.admin-role")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Stack>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Title>{t("members.edit.preferences-title")}</Title>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("members.edit.preferences-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Display language</InputLabel>
                      <Select
                        value={displayLanguage}
                        label={t("members.edit.display-language")}
                        required
                        onChange={(e) => {
                          setDisplayLanguage(e.target.value);
                        }}
                      >
                        <MenuItem value={"system"}>
                          {t("language.system", { ns: "misc" })}
                        </MenuItem>
                        <MenuItem value={"en"}>
                          {t("language.english", { ns: "misc" })}
                        </MenuItem>
                        <MenuItem value={"es"}>
                          {t("language.spanish", { ns: "misc" })}
                        </MenuItem>
                        {/* <MenuItem value={"fr"}>
                          {t("language.french", { ns: "misc" })}
                        </MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Stack>

          {sessionSetting ? (
            <SessionSettings
              fetchedSessionSetting={sessionSetting}
              setUpdateSessionSetting={setUpdateSessionSetting}
              updateSessionSetting={updateSessionSetting}
              setError={setSessionSettingError}
              setSuccess={setSessionSettingSuccess}
              custom={true}
            />
          ) : (
            <></>
          )}

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("members.edit.submit")}
            </Button>
          </Grid>

          {sessionSetting ? (
            <></>
          ) : (
            <React.Fragment>
              <Divider variant="middle" sx={{ mb: 2 }} />

              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {t("members.edit.advanced-settings-title")}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
                {t("members.edit.advanced-settings-description")}
              </Typography>

              <Grid item xs={12} md={12} sx={{ mb: 2, mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      onClick={handleCreateMemberSessionSetting}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("members.edit.create-member-session-setting")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Typography component="h2" variant="h6" color="error" gutterBottom>
            {t("members.edit.sensitive-actions-title")}
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("members.edit.sensitive-actions-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={12} sx={{ mb: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="error"
                    onClick={handleForceSignout}
                    variant="contained"
                    sx={{ mr: 2 }}
                  >
                    {t("members.edit.force-sign-out")}
                  </Button>
                  {accessRevoked ? (
                    <Button
                      type="submit"
                      color="primary"
                      onClick={handleAllowAccess}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("members.edit.allow-access-button")}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      color="error"
                      onClick={handleRevokeAccess}
                      disabled={isMyself}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("members.edit.revoke-access-button")}
                    </Button>
                  )}
                  <Tooltip
                    title={t("members.edit.delete-tooltip")}
                    placement="top"
                  >
                    <Button
                      type="submit"
                      color="error"
                      disabled={isMyself}
                      onClick={handleDeleteMember}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("members.edit.delete-button")}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          <Grid item lg={2}></Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
