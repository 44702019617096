import { Subject } from "rxjs";
export const eventBus = new Subject();

interface Event {
  type: string;
  payload: any;
}

export function eventBusEmit(event: Event): void {
  eventBus.next(event);
}

export function eventBusReceive(
  type: string,
  callback: (payload) => void
): void {
  eventBus.subscribe((event: any) => {
    if (event.type === type) return callback(event.payload);
  });
}
