import AstroHelp from "@/components/astro-help";
import { Copyright } from "@/components/copyright";
import LoadingButton from "@/components/loading-button";
import {
  GEOLOCATION_OPTIONS,
  setGeoGranted,
  unsetGeoGranted,
} from "@/helpers/geo";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Alert, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// in some weird edge-cases it hangs forever, so we have to take over
// and just skip this page and consider the geolocation as taken or denied.
// in any case we must go to the next page
// typically it'll be higher than the default timeout from the geolocation API (browser)
const MAX_LOADING = 20000;

export default function PageGeo({
  setStateGeolocation,
  setGeolocation,
  message,
  extra = <></>,
}) {
  const { t } = useTranslation("misc");
  const [loading, setLoading] = useState<boolean>(false);

  const askForPermission = (event) => {
    event.preventDefault();

    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      const timeoutID = setTimeout(() => {
        setStateGeolocation("denied");
        unsetGeoGranted();
        // this means the client didn't accept geolocation
        // or at least geolocation couldn't be recovered
        // we do not refresh the page otherwise
        // it would just go back to the ask geolocation page again
        // from the StateGeolocation it should change automatically
      }, MAX_LOADING);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timeoutID);
          setGeoGranted();
          setStateGeolocation("granted");
          setGeolocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          });
          setLoading(false);
        },
        () => {
          clearTimeout(timeoutID);
          unsetGeoGranted();
          setStateGeolocation("denied");
          // this means the client didn't accept geolocation
          // we do not refresh the page otherwise
          // it would just go back to the ask geolocation page again
          // from the StateGeolocation it should change automatically
        },
        GEOLOCATION_OPTIONS
      );
    }
  }, [loading]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <PriorityHighIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {message}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={6}
            lg={8}
            sx={{ mt: 2 }}
            style={{ marginLeft: "-2.3em" }}
          >
            <AstroHelp />
          </Grid>
          <Grid item xs={8} textAlign="center">
            <Grid item>
              <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={askForPermission}
                text={t("geolocation.ask-for-permission")}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {extra}
      <LocationAlert />
      <Copyright sx={{ mb: 2 }} />
    </Container>
  );
}

export function LocationAlert() {
  const { t } = useTranslation("member");
  return (
    <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
      <strong>{t("geolocation.warning-important")}</strong>{" "}
      {t("geolocation.warning-subimportant")}
    </Alert>
  );
}
