import { Copyright } from "@/components/copyright";
import { isGuest } from "@/helpers/identity";
import { Organization } from "@/interfaces";
import { gql, useQuery } from "@apollo/client";
import BackHandIcon from "@mui/icons-material/BackHand";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const GET_MYSELF = gql`
  query GetMyself {
    GetMyself {
      id
      organization {
        id
        name
        slug
      }
    }
  }
`;

export default function Welcome() {
  const { t } = useTranslation("member");
  const [organization, setOrganization] = useState<Organization>({
    id: null,
    slug: null,
    name: null,
  });

  const getGetMyself = useQuery(GET_MYSELF, {
    skip: isGuest(),
  });

  useEffect(() => {
    if (getGetMyself.data) {
      if (organization.id === null)
        setOrganization(getGetMyself.data.GetMyself.organization);
    }
  }, [getGetMyself]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <BackHandIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("welcome.title", { name: organization.name })}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 1 }}>
              {t("welcome.instructions")}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
