import { Member } from "@/interfaces";
import { ApolloError } from "@apollo/client";
import { TFunction } from "i18next";

export function slugify(string) {
  return string
    .toString() // Convert to string
    .normalize("NFD") // Normalize to decompose diacritics (e.g., accents)
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading and trailing spaces
    .replace(/[^a-z0-9\s-]/g, "") // Remove non-alphanumeric characters except spaces and hyphens
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
}

export function removeHiglightAfter(ms: number) {
  const classNameToRemove = "highlightedRow";

  setTimeout(() => {
    const elements = document.querySelectorAll(`.${classNameToRemove}`);

    elements.forEach((element) => {
      element.classList.remove(classNameToRemove);
    });
  }, ms);
}

export const memberName = (member: Member): string => {
  if (member.firstName && member.lastName)
    return `${member.firstName} ${member.lastName}`;

  if (member.firstName || member.lastName)
    return member.firstName || member.lastName || "";

  return member.email || member.phone || "";
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function findAndTranslateErrors({ error, t }): string {
  let displayError: string = "";

  // plain string
  if (typeof error === "string") displayError = error;
  // graphql
  if (error?.graphQLErrors) {
    if (error.graphQLErrors.length === 0) {
      // if the error is a status or not a clear graphql error
      // we just render a generalist one
      return t("error.something-went-wrong", { ns: ["misc"] });
    }
    displayError = error.graphQLErrors
      .map((graphErr) => translateErrorCode(graphErr, t))
      .join(", ");
  }

  return capitalizeFirst(displayError);
}

export function graphQLErrorCode(error: ApolloError, code: string) {
  return (
    error.graphQLErrors.filter((err) => err.extensions.code === code).length > 0
  );
}

export function listGraphQLErrorCodes(error: ApolloError) {
  return error.graphQLErrors.map((err) => err.extensions.code);
}

export function translateErrorCode(
  graphErr: { extensions: { code: string }; message: string },
  t: TFunction<"misc", undefined>
): string {
  const code = graphErr.extensions.code;
  const message = graphErr.message;
  const translationKey = `error.code.${code.toLowerCase().replace(/_/g, "-")}`;

  // if we don't find the translation, we generally don't want to show the error
  // because it could some dirty raw SQL
  // GraphQL doesn't make the difference on the matter
  if (code === "UNKNOWN")
    return t("error.something-went-wrong", { ns: ["misc"] });
  const translationValue = t(translationKey, { ns: ["misc"] });

  if (translationValue === translationKey) {
    console.log(`missing translation for error ${translationKey}`);
    return message;
  }
  return t(translationKey, { ns: ["misc"] });
}

export function camelToKebab(str) {
  return str
    .replace(/([a-z0-9])([A-Z])/g, "$1-$2") // Insert hyphen between lowercase/number and uppercase
    .replace(/([A-Z])([A-Z][a-z])/g, "$1-$2") // Insert hyphen between consecutive uppercase letters followed by lowercase
    .toLowerCase(); // Convert the entire string to lowercase
}
