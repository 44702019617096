import { isGuest } from "@/helpers/identity";
import {
  getParams,
  keepPath,
  rawURLReplace,
  setAfterAuthPath,
} from "@/helpers/navigation";
import { useEffect } from "react";

// THIS IS A LEGACY PAGE THAT EXISTS ONLY TO REDIRECT TO THE NEXT PAGE
export default function PresignAction() {
  const params = getParams();
  const redirectURL = String(params.get("redirect_url"));

  useEffect(() => {
    if (isGuest()) {
      // store where it should go after the person sign-in
      setAfterAuthPath(keepPath(window.location.href), { forceSet: true });
    }
    rawURLReplace(redirectURL);
  }, [redirectURL]);

  // we show nothing because
  // it'll redirect the page directly
  return <></>;
}
