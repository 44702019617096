import PartialError from "@/components/partial-error";
import PartialLoading from "@/components/partial-loading";
import { timestampToTime, timestampToDate } from "@/helpers/time";
import { defaultTheme } from "@/theme";
import { Paper, Typography } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

export function RenderBarChartNumbers({
  timeseries,
  loading,
  error,
  elements,
  intervalInMinutes,
  axisName,
}) {
  const theme = defaultTheme;
  let style = {};

  if (loading) return <PartialLoading />;
  if (error) return <PartialError />;

  if (timeseries.totalData === 0) {
    style = { opacity: "50%" };
  }

  const dataset: Array<object> = [];

  timeseries.data.forEach((entry) => {
    const points = {};
    elements.map((el) => {
      const value = entry[el.entry];
      return (points[`${el.name}`] = value);
    });

    let timestamp;

    // if intervals are less than a day we show a time
    if (intervalInMinutes < 60 * 24) {
      timestamp = timestampToTime(entry.timestamp);
    } else {
      // otherwise we show per day
      // NOTE: we could enlarge this if we data of over a month
      timestamp = timestampToDate(entry.timestamp);
    }

    const item = Object.assign({ timestamp }, points);

    dataset.push(item);
  });

  const RenderBars: JSX.Element[] = [];
  let idx = 0;
  elements.forEach((el) => {
    RenderBars.push(
      <Bar
        key={idx}
        dataKey={el.name}
        stackId="a"
        fill={theme.palette[el.color].main}
      />
    );
    idx++;
  });

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      let noContent = true;
      const content = payload.map((item) => {
        const readableValue = item.value;

        if (noContent && item.value) noContent = false;

        return (
          <React.Fragment>
            <Typography fontWeight="bold" color="default">
              {item.payload.timestamp}
            </Typography>
            <Typography fontWeight="bold" color={item.color}>
              {item.name}
            </Typography>
            {readableValue}
          </React.Fragment>
        );
      });

      if (noContent) return <></>;
      return <Paper sx={{ p: 2 }}>{content}</Paper>;
    }

    return null;
  };

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <BarChart
          style={style}
          width={500}
          height={300}
          data={dataset}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis>
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
              }}
            >
              {axisName}
            </Label>
          </YAxis>
          <Tooltip cursor={{ opacity: "50%" }} content={<CustomTooltip />} />
          <Legend />
          {RenderBars}
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
