import { FormInfo } from "@/components/form";
import { GoogleMapOneDraggableMarker } from "@/components/google-map";
import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import { findAndTranslateErrors } from "@/helpers/format";
import { getIdentity } from "@/helpers/identity";
import { rawURLReplace } from "@/helpers/navigation";
import { getDateAndTime } from "@/helpers/time";
import Title from "@/pages/organization/dashboard/components/title";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const GET_ZONE = gql`
  query GetZone($id: UUID!) {
    GetZone(id: $id) {
      id
      name
      geofencing
      kind
      location {
        id
        accuracy
        latitude
        longitude
      }
    }
  }
`;

const UPDATE_ZONE = gql`
  mutation UpdateZone(
    $id: UUID!
    $latitude: Float
    $longitude: Float
    $accuracy: Float
    $name: String!
    $geofencing: Boolean!
  ) {
    UpdateZone(
      id: $id
      latitude: $latitude
      longitude: $longitude
      accuracy: $accuracy
      name: $name
      geofencing: $geofencing
    ) {
      id
    }
  }
`;

const DELETE_ZONE = gql`
  mutation DeleteZone($id: UUID!) {
    DeleteZone(id: $id)
  }
`;

export function UpdateLocation({ geolocation, setGeolocation }) {
  const { t } = useTranslation(["organization", "misc"]);

  if (geolocation.latitude === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Title>{t("zones.edit.location-title")}</Title>
      <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
        {t("zones.edit.location-description")}
      </Typography>

      <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
        <FormInfo message={t("zones.edit.location-warning")} />
      </Grid>

      <Stack direction={{ xs: "column", md: "row" }} sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
          <Paper sx={{ p: 2 }}>
            <GoogleMapOneDraggableMarker
              geolocation={geolocation}
              setGeolocation={setGeolocation}
            />
          </Paper>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}

export default function EditZone() {
  const { t } = useTranslation(["organization", "misc"]);
  const navigate = useNavigate();
  const [, setID] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [kind, setKind] = useState<string>("");
  const [geolocation, setGeolocation] = useState<{
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
  }>({ latitude: null, longitude: null, accuracy: null });
  const [geofencing, setGeofencing] = useState<boolean>(false);

  const { zoneID } = useParams();
  const getZone = useQuery(GET_ZONE, {
    variables: { id: zoneID },
    fetchPolicy: "no-cache",
  });
  const [mutationUpdateZone, { data, error }] = useMutation(UPDATE_ZONE);
  const [mutationDeleteZone, deleteZone] = useMutation(DELETE_ZONE);

  const isMyself = getIdentity().id === zoneID;

  useEffect(() => {
    const possibleErrors = error || deleteZone.error;
    const errorDisplay: string = findAndTranslateErrors({
      error: possibleErrors,
      t,
    });
    let successDisplay: string = "";
    if (data) {
      successDisplay = t("zones.edit.updated");
    } else if (deleteZone.data) {
      // in this case we should leave the page entirely
      // since the zone does not exist anymore.
      rawURLReplace("/organization/dashboard/zones");
    }

    if (successDisplay) {
      eventBusEmit({ type: "form-success", payload: successDisplay });
    }
    if (errorDisplay) {
      eventBusEmit({ type: "form-error", payload: errorDisplay });
    }
  }, [t, data, error, deleteZone.data]);

  useEffect(() => {
    if (getZone.data) {
      setID(getZone.data.GetZone.id);
      setName(getZone.data.GetZone.name);
      setGeofencing(getZone.data.GetZone.geofencing);
      setKind(getZone.data.GetZone.kind);
      if (getZone.data.GetZone.location) {
        setGeolocation(getZone.data.GetZone.location);
      }

      if (getZone.data.GetZone.name) {
        const pageName: string = getZone.data.GetZone.name;
        eventBusEmit({ type: "page-name", payload: pageName });
      }
    }
  }, [getZone.data, navigate, t]);

  if (getZone.loading) return <Loading />;
  if (getZone.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  const handleDeleteZone: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();

    mutationDeleteZone({
      variables: {
        id: zoneID,
      },
    });
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    mutationUpdateZone({
      variables: {
        id: zoneID,
        name,
        geofencing,
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        accuracy: geolocation.accuracy,
      },
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Title>{t("zones.edit.title")}</Title>

          {getZone.data.GetZone.accessRevokedAt ? (
            <FormInfo
              message={t("zones.access-revoked-notice", {
                date: getDateAndTime(getZone.data.GetZone.accessRevokedAt),
              })}
            />
          ) : (
            ""
          )}

          {isMyself ? <FormInfo message={t("zones.editing-yourself")} /> : ""}

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Title>{t("zones.edit.information-title")}</Title>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("zones.edit.information-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{ mb: 2, mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      id="name"
                      label={t("zones.edit.name")}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Stack>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <UpdateLocation
            geolocation={geolocation}
            setGeolocation={setGeolocation}
          />

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={12} sx={{ mb: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title>{t("zones.edit.geofencing")}</Title>
                  <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
                    {t("zones.edit.geofencing-description")}
                  </Typography>
                  <FormControl
                    component="fieldset"
                    disabled={kind === "MOVING"}
                  >
                    <Tooltip
                      title={
                        kind === "MOVING"
                          ? t("zones.edit.geofencing-moving-not-available")
                          : ""
                      }
                      arrow
                      placement="top"
                    >
                      <RadioGroup
                        row
                        aria-label="boolean"
                        name="boolean1"
                        value={String(geofencing)}
                        onChange={(event) => {
                          setGeofencing(event.target.value === "true");
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          checked={geofencing}
                          control={<Radio />}
                          label="ON"
                        />
                        <FormControlLabel
                          value="false"
                          checked={!geofencing}
                          control={<Radio />}
                          label="OFF"
                        />
                      </RadioGroup>
                    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              type="submit"
              onClick={handleSubmit}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("zones.edit.submit")}
            </Button>
          </Grid>

          <Divider variant="middle" sx={{ mb: 2 }} />

          <Typography component="h2" variant="h6" color="error" gutterBottom>
            {t("zones.edit.sensitive-actions-title")}
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
            {t("zones.edit.sensitive-actions-description")}
          </Typography>

          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ mb: 2 }}
            spacing={2}
          >
            <Grid item xs={12} md={12} sx={{ mb: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tooltip
                    title={t("zones.edit.delete-tooltip")}
                    placement="top"
                  >
                    <Button
                      type="submit"
                      color="error"
                      onClick={handleDeleteZone}
                      variant="contained"
                      sx={{ mr: 2 }}
                    >
                      {t("zones.edit.delete-button")}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          <Grid item lg={2}></Grid>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
