import { Alert } from "@/interfaces";

export function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function downloadImage(image) {
  const href: string | null = image;
  if (href === null) {
    return;
  }
  const anchor: HTMLAnchorElement = document.createElement("a");
  anchor.href = href;
  anchor.download = href;
  document.body.appendChild(anchor);
  anchor.click();
}

export function URLforHTMLExport({
  organizationId,
  memberId,
  zoneId,
  startDate,
  endDate,
  lang,
}) {
  const baseURL = import.meta.env.VITE_API_BASE_URL;
  if (!zoneId) zoneId = "";

  return `${baseURL}/exports/html/work-timesheet?organization_id=${organizationId}&member_id=${memberId}&zone_id=${zoneId}&start_date=${startDate}&end_date=${endDate}&lang=${lang}`;
}

export function isAlert(alert: Alert[], alertType: string): boolean {
  return alert.filter((ev) => ev.alertType == alertType).length > 0;
}

export function getAlert(alert: Alert[], alertType: string): Alert {
  return alert.filter((ev) => ev.alertType == alertType)[0];
}
