import { Copyright } from "@/components/copyright";
import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export default function PrivacyStatement() {
  const { t, i18n } = useTranslation("legal");

  let legalPaper;
  if (i18n.language === "es") {
    legalPaper = <TermsSpanish />;
  } else {
    legalPaper = <TermsEnglish />;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <AccountBalanceIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ align: "center" }}>
          {t("legal.terms-of-service")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      ></Box>
      {legalPaper}
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

export function TermsEnglish() {
  return (
    <React.Fragment>
      <p>
        <strong>Laurent Pierre Roger Schaffner</strong> (&quot;Aquiestoy&quot;,
        or “we” and its derivatives) operate aquiestoy.io (together with any
        subdomains, the “Site”) and provide our users (each user, “you”) with
        mobile, and web applications (each an “App” and, collectively, the
        “Apps”). These Terms of Service (the “Terms”) are a binding agreement
        between you and Aquiestoy regarding your use of the Site, the Apps, and
        any related services we may perform (the Site, Apps and services
        together, the “Services”). As used in these Terms, a “device” means any
        computer, phone, tablet, or other computer hardware on which Apps run,
        and the term &quot;Premium mode&quot; means a group which has access to
        paying features.
      </p>
      <p>
        <strong>Ownership and Restrictions</strong>
      </p>
      <p>
        By downloading or using the Apps, these terms will automatically apply
        to you – you should make sure therefore that you read them carefully
        before using the Apps. You are not allowed to copy or modify the Apps,
        any part of the Apps, or our trademarks in any way. You are not allowed
        to attempt to extract the source code of the Apps, and you also should
        not try to translate the Apps into other languages, or make derivative
        versions. The Apps itself, and all the trade marks, copyright, database
        rights and other intellectual property rights related to it, still
        belong to Aquiestoy.
      </p>
      <p>
        <strong>Changes to the Apps and Services</strong>
      </p>
      <p>
        We are committed to ensuring that the Apps are as useful and efficient
        as possible. For that reason, we reserve the right to make changes to
        the Apps or to charge for its services, at any time and for any reason.
        We will never charge you for the Apps or its services without making it
        very clear to you exactly what you are paying for.
      </p>
      <p>
        <strong>Data Privacy and Security</strong>
      </p>
      <p>
        This App stores and processes personal data that you enter, in order to
        provide its Service. It is your responsibility to keep your device and
        access to the Apps secure. We advise against jailbreaking or rooting
        your device, as doing so may compromise your phone&#39;s security and
        the proper functioning of the Apps.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        You should be aware that there are certain things that we will not take
        responsibility for. Certain functions of the Apps will require the Apps
        to have an active internet connection. The connection can be Wi-Fi, or
        provided by your mobile network provider, but we cannot take
        responsibility for the Apps not working at full functionality if you
        don’t have access to Wi-Fi, and you don’t have any of your data
        allowance left.
      </p>
      <p>
        If you are using the Apps outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the Apps, or other third party charges. In using the
        Apps, you are accepting responsibility for any such charges, including
        roaming data charges if you use the Apps outside of your home territory
        (i.e. region or country) without turning off data roaming. If you are
        not the bill payer for the device on which you are using the Apps,
        please be aware that we assume that you have received permission from
        the bill payer for using the Apps.
      </p>
      <p>
        Along the same lines, we cannot always take responsibility for the way
        you use the Apps i.e. You need to make sure that your device stays
        charged – if it runs out of battery and you can’t turn it on to avail
        the Service, we cannot accept responsibility.
      </p>
      <p>
        With respect to our responsibility for your use of the Apps, when you
        are using the Apps, it is important to bear in mind that although we
        endeavour to ensure that it is updated and correct at all times, we do
        rely on third parties to provide information to us so that we can make
        it available to you. We accept no liability for any loss, direct or
        indirect, you experience as a result of relying wholly on this
        functionality of the Apps.
      </p>
      <p>
        <strong>Subscription</strong>
      </p>
      <p>
        Aquiestoy offer a subscription purchase option which allows you to
        access Premium mode.
      </p>
      <p>
        Payments will be processed through Stripe (&quot;Subscription
        Provider&quot;) from which you can access through the Apps.
      </p>
      <p>
        By purchasing subscription through the Subscription Provider, you agree
        to the following terms of service:
      </p>
      <ul>
        <li>
          Purchases options: Our Apps provides several purchase options, each
          with its own duration and features. The available subscription plans
          will be displayed within the app, along with their respective prices.
        </li>
        <li>
          Free Trial: We may offer a free trial period for new subscribers. The
          duration of the free trial and the specific terms of service will be
          indicated on the In Apps and may vary based on your location.
        </li>
        <li>
          Payment: When you subscribe to our service, you agree to pay the
          applicable subscription fee, as well as any taxes or additional
          charges that may apply. The payment will be charged to your account
          upon confirmation of your purchase.
        </li>
        <li>
          Automatic Renewal: Some purchase options displayed within the app are
          recurring. Subscriptions purchased through the In Apps will
          automatically renew unless you cancel your subscription, and the
          renewal feed will be charged to you by the Subscription Provider.
        </li>
        <li>
          Refunds: No refunds or credits will be provided for partial
          subscription periods. Upon cancellation, you will continue to have
          access to the service until the end of the current subscription
          period.
        </li>
        <li>
          Price: The price of Aquiestoy subscription may vary depending on the
          date of the purchase, the location of the user, local taxes, app used,
          product testing, promotions and more. We reserve the right to change
          the subscription prices at any time. However, any price changes will
          not affect the current subscription period you have already paid for.
          The new prices will apply to subsequent subscription periods.
        </li>
        <li>
          Delays and Disruptions: We shall not be responsible for any delays or
          disruptions in service caused by the Apps or its services, including
          but not limited to billing issues, technical problems, or app updates.
        </li>
        <li>
          Data Privacy: We may collect and process certain personal information
          as described in our Privacy Policy. By purchasing a subscription, you
          consent to the collection and processing of your data in accordance
          with our Privacy Policy.
        </li>
        <li>
          Termination: We reserve the right to terminate or suspend your
          subscription and access to the service if you violate our Terms of
          Service or engage in any unauthorized use of the app.
        </li>
      </ul>
      <p>
        In the unlikely event that we are unable to repair the relevant
        &quot;in-app&quot; purchase or are unable to do so within a reasonable
        period of time and without significant inconvenience to you, we might
        authorise the Subscription Provider, from which you originally
        subscribed, to refund you an amount up to the cost of the relevant
        purchase.
      </p>
      <p>
        <strong>Prohibition of Reselling Services</strong>
      </p>
      <p>
        By using our Apps and subscribing to our services, you agree not to
        resell, transfer, or share your subscription or any part of our services
        to any third party. The subscription you purchase is for your personal
        use only, and you are strictly prohibited from distributing,
        sublicensing, or otherwise making our services available to others,
        whether for commercial purposes or otherwise.
      </p>
      <p>
        We invest significant time and resources in developing and providing our
        services to our valued customers. Unauthorized reselling or distribution
        of our services undermines the integrity of our platform, violates our
        Terms of Service, and may result in legal action.
        <br />
        If we have reasonable grounds to believe that you have engaged in the
        reselling or unauthorized distribution of our services, we reserve the
        right to take appropriate actions, including but not limited to:
      </p>
      <ul>
        <li>
          Termination of Subscription: We may immediately terminate your
          subscription and access to our services without prior notice.
        </li>
        <li>
          Legal Action: We may take legal action to protect our rights and seek
          damages caused by the unauthorized reselling or distribution of our
          services.
        </li>
        <li>
          Account Suspension: We may suspend your account pending an
          investigation into potential violations of this clause.
        </li>
      </ul>
      <p>
        By using our mobile app and subscribing to our services, you acknowledge
        and agree that the services are provided to you for personal use only.
        You further understand that any unauthorized resale, transfer, or
        sharing of our services may result in severe consequences as outlined in
        this section.
      </p>
      <p>
        <strong>Application compatibility</strong>
      </p>
      <p>
        At some point, we may wish to update the Apps. The requirements for
        devices may change, and you will need to download the updates if you
        want to keep using the Apps. We do not promise that it will always
        update the Apps so that it is relevant to you and/or works with the
        Operating System or Browser that you have installed on your device.
        However, you promise to always accept updates to the application when
        offered to you, We may also wish to stop providing the Apps, and may
        terminate use of it at any time without giving notice of termination to
        you. Unless we tell you otherwise, upon any termination, (a) the rights
        and licenses granted to you in these terms will end; (b) you must stop
        using the Apps, and (if needed) delete it from your device.
      </p>
      <p>
        <strong>Changes to This Terms of service</strong>
      </p>
      <p>
        We may update our Terms of service from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms of service on this page.
        These changes are effective immediately after they are posted on this
        page.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about our Terms of service, do
        not hesitate to contact us at support@aquiestoy.io
      </p>
    </React.Fragment>
  );
}

export function TermsSpanish() {
  return (
    <React.Fragment>
      <p>
        <strong>Laurent Pierre Roger Schaffner</strong> ("Aquiestoy" o
        "nosotros" y sus derivados) opera aquiestoy.io (junto con cualquier
        subdominio, el "Sitio") y proporciona a nuestros usuarios (cada usuario,
        "usted") aplicaciones móviles y web (cada una una "Aplicación" y,
        colectivamente, las "Aplicaciones"). Estos Términos de Servicio (los
        "Términos") son un acuerdo vinculante entre usted y Aquiestoy con
        respecto a su uso del Sitio, las Aplicaciones y cualquier servicio
        relacionado que podamos realizar (el Sitio, las Aplicaciones y los
        servicios juntos, los "Servicios"). Según se utiliza en estos Términos,
        un "dispositivo" significa cualquier computadora, teléfono, tableta u
        otro hardware de computadora en el que se ejecuten las Aplicaciones, y
        el término "modo Premium" significa un grupo que tiene acceso a
        funciones de pago.
      </p>
      <p>
        <strong>Propiedad y Restricciones</strong>
      </p>
      <p>
        Al descargar o usar las Aplicaciones, estos términos se aplicarán
        automáticamente a usted; por lo tanto, asegúrese de leerlos
        detenidamente antes de usar las Aplicaciones. No se le permite copiar o
        modificar las Aplicaciones, ninguna parte de las Aplicaciones, ni
        nuestras marcas comerciales de ninguna manera. No se le permite intentar
        extraer el código fuente de las Aplicaciones, y tampoco debe intentar
        traducir las Aplicaciones a otros idiomas o crear versiones derivadas.
        Las propias Aplicaciones, y todas las marcas comerciales, derechos de
        autor, derechos de base de datos y otros derechos de propiedad
        intelectual relacionados con ellas, aún pertenecen a Aquiestoy.
      </p>
      <p>
        <strong>Cambios en las Aplicaciones y Servicios</strong>
      </p>
      <p>
        Nos comprometemos a asegurarnos de que las Aplicaciones sean lo más
        útiles y eficientes posible. Por esa razón, nos reservamos el derecho de
        realizar cambios en las Aplicaciones o cobrar por sus servicios, en
        cualquier momento y por cualquier motivo. Nunca le cobraremos por las
        Aplicaciones o sus servicios sin dejarle muy claro exactamente por lo
        que está pagando.
      </p>
      <p>
        <strong>Privacidad y Seguridad de los Datos</strong>
      </p>
      <p>
        Esta Aplicación almacena y procesa datos personales que usted ingresa,
        con el fin de proporcionar su Servicio. Es su responsabilidad mantener
        seguro su dispositivo y el acceso a las Aplicaciones. Le recomendamos
        que no haga jailbreak o root a su dispositivo, ya que hacerlo puede
        comprometer la seguridad de su teléfono y el funcionamiento adecuado de
        las Aplicaciones.
      </p>
      <p>
        <strong>Limitación de Responsabilidad</strong>
      </p>
      <p>
        Debe ser consciente de que hay ciertas cosas por las que no nos haremos
        responsables. Ciertas funciones de las Aplicaciones requerirán que las
        Aplicaciones tengan una conexión a Internet activa. La conexión puede
        ser Wi-Fi o proporcionada por su proveedor de red móvil, pero no podemos
        hacernos responsables de que las Aplicaciones no funcionen a plena
        funcionalidad si no tiene acceso a Wi-Fi y no le queda ninguna de su
        asignación de datos.
      </p>
      <p>
        Si está utilizando las Aplicaciones fuera de un área con Wi-Fi, debe
        recordar que los términos del acuerdo con su proveedor de red móvil
        seguirán aplicándose. Como resultado, es posible que su proveedor de
        telefonía móvil le cobre por el costo de los datos durante la conexión
        mientras accede a las Aplicaciones, o por otros cargos de terceros. Al
        utilizar las Aplicaciones, usted acepta la responsabilidad de tales
        cargos, incluidos los cargos por datos en roaming si utiliza las
        Aplicaciones fuera de su territorio de origen (es decir, región o país)
        sin desactivar el roaming de datos. Si no es el pagador de la factura
        del dispositivo en el que está utilizando las Aplicaciones, tenga en
        cuenta que asumimos que ha recibido permiso del pagador de la factura
        para utilizar las Aplicaciones.
      </p>
      <p>
        En la misma línea, no siempre podemos hacernos responsables de la forma
        en que utiliza las Aplicaciones, es decir, debe asegurarse de que su
        dispositivo esté cargado: si se queda sin batería y no puede encenderlo
        para disfrutar del Servicio, no podemos aceptar responsabilidad.
      </p>
      <p>
        Con respecto a nuestra responsabilidad por su uso de las Aplicaciones,
        cuando esté utilizando las Aplicaciones, es importante tener en cuenta
        que aunque nos esforzamos por garantizar que estén actualizadas y sean
        correctas en todo momento, dependemos de terceros para proporcionarnos
        información para que podamos ponerla a su disposición. No aceptamos
        ninguna responsabilidad por cualquier pérdida, directa o indirecta, que
        experimente como resultado de depender completamente de esta
        funcionalidad de las Aplicaciones.
      </p>
      <p>
        <strong>Suscripción</strong>
      </p>
      <p>
        Aquiestoy ofrece una opción de compra de suscripción que le permite
        acceder al modo Premium.
      </p>
      <p>
        Los pagos serán procesados a través de Stripe ("Proveedor de
        Suscripción") al que puede acceder a través de las Aplicaciones.
      </p>
      <p>
        Al comprar una suscripción a través del Proveedor de Suscripción, acepta
        los siguientes términos de servicio:
      </p>
      <ul>
        <li>
          Opciones de compra: Nuestras Aplicaciones ofrecen varias opciones de
          compra, cada una con su propia duración y características. Los planes
          de suscripción disponibles se mostrarán dentro de la aplicación, junto
          con sus respectivos precios.
        </li>
        <li>
          Prueba gratuita: Podemos ofrecer un período de prueba gratuito para
          nuevos suscriptores. La duración de la prueba gratuita y los términos
          de servicio específicos se indicarán en las Aplicaciones y pueden
          variar según su ubicación.
        </li>
        <li>
          Pago: Cuando se suscribe a nuestro servicio, acepta pagar la tarifa de
          suscripción aplicable, así como cualquier impuesto o cargo adicional
          que pueda aplicarse. El pago se cargará en su cuenta al confirmar su
          compra.
        </li>
        <li>
          Renovación automática: Algunas opciones de compra que se muestran
          dentro de la aplicación son recurrentes. Las suscripciones compradas a
          través de las Aplicaciones se renovarán automáticamente a menos que
          cancele su suscripción, y la tarifa de renovación le será cobrada por
          el Proveedor de Suscripción.
        </li>
        <li>
          Reembolsos: No se proporcionarán reembolsos ni créditos por períodos
          de suscripción parciales. Al cancelar, seguirá teniendo acceso al
          servicio hasta el final del período de suscripción actual.
        </li>
        <li>
          Precio: El precio de la suscripción a Aquiestoy puede variar según la
          fecha de la compra, la ubicación del usuario, los impuestos locales,
          la aplicación utilizada, las pruebas de productos, las promociones y
          más. Nos reservamos el derecho de cambiar los precios de suscripción
          en cualquier momento. Sin embargo, cualquier cambio de precio no
          afectará el período de suscripción actual que ya ha pagado. Los nuevos
          precios se aplicarán a los períodos de suscripción posteriores.
        </li>
        <li>
          Retrasos e Interrupciones: No seremos responsables de ningún retraso o
          interrupción en el servicio causado por las Aplicaciones o sus
          servicios, incluidos, entre otros, problemas de facturación, problemas
          técnicos o actualizaciones de aplicaciones.
        </li>
        <li>
          Privacidad de los Datos: Podemos recopilar y procesar cierta
          información personal como se describe en nuestra Política de
          Privacidad. Al comprar una suscripción, acepta la recopilación y el
          procesamiento de sus datos de acuerdo con nuestra Política de
          Privacidad.
        </li>
        <li>
          Terminación: Nos reservamos el derecho de terminar o suspender su
          suscripción y acceso al servicio si viola nuestros Términos de
          Servicio o participa en un uso no autorizado de la aplicación.
        </li>
      </ul>
      <p>
        En el improbable caso de que no podamos reparar la compra "in-app"
        relevante o no podamos hacerlo dentro de un período de tiempo razonable
        y sin inconvenientes significativos para usted, podríamos autorizar al
        Proveedor de Suscripción, desde el cual se suscribió originalmente, a
        reembolsarle una cantidad de hasta el costo de la compra relevante.
      </p>
      <p>
        <strong>Prohibición de Revender Servicios</strong>
      </p>
      <p>
        Al utilizar nuestras Aplicaciones y suscribirse a nuestros servicios,
        acepta no revender, transferir o compartir su suscripción o ninguna
        parte de nuestros servicios a ningún tercero. La suscripción que compre
        es solo para su uso personal, y está estrictamente prohibido que
        distribuya, sublicencie o de cualquier otra forma ponga a disposición de
        otros nuestros servicios, ya sea con fines comerciales o de otra índole.
      </p>
      <p>
        Invertimos tiempo y recursos significativos en el desarrollo y la
        provisión de nuestros servicios a nuestros valiosos clientes. La reventa
        o distribución no autorizada de nuestros servicios socava la integridad
        de nuestra plataforma, viola nuestros Términos de Servicio y puede
        resultar en acciones legales.
        <br />
        Si tenemos motivos razonables para creer que ha participado en la
        reventa o distribución no autorizada de nuestros servicios, nos
        reservamos el derecho de tomar medidas apropiadas, incluyendo, entre
        otras:
      </p>
      <ul>
        <li>
          Terminación de la Suscripción: Podemos terminar su suscripción y
          acceso a nuestros servicios de inmediato y sin previo aviso.
        </li>
        <li>
          Acción Legal: Podemos emprender acciones legales para proteger
          nuestros derechos y buscar daños causados por la reventa o
          distribución no autorizada de nuestros servicios.
        </li>
        <li>
          Suspensión de la Cuenta: Podemos suspender su cuenta mientras se
          investigan posibles violaciones de esta cláusula.
        </li>
      </ul>
      <p>
        Al utilizar nuestra aplicación móvil y suscribirse a nuestros servicios,
        reconoce y acepta que los servicios se le proporcionan solo para uso
        personal. Además, comprende que cualquier reventa, transferencia o
        intercambio no autorizado de nuestros servicios puede tener
        consecuencias graves, como se describe en esta sección.
      </p>
      <p>
        <strong>Compatibilidad de la Aplicación</strong>
      </p>
      <p>
        En algún momento, es posible que deseemos actualizar las Aplicaciones.
        Los requisitos para los dispositivos pueden cambiar, y deberá descargar
        las actualizaciones si desea seguir utilizando las Aplicaciones. No
        prometemos que siempre actualizaremos las Aplicaciones para que sean
        relevantes para usted y/o funcionen con el sistema operativo o el
        navegador que tenga instalado en su dispositivo. Sin embargo, se
        compromete a aceptar siempre las actualizaciones de la aplicación cuando
        se le ofrezcan. También podemos desear dejar de proporcionar las
        Aplicaciones, y podemos terminar su uso en cualquier momento sin previo
        aviso. A menos que le indiquemos lo contrario, tras cualquier
        terminación: (a) los derechos y licencias otorgados a usted en estos
        términos terminarán; (b) debe dejar de utilizar las Aplicaciones y, si
        es necesario, eliminarlas de su dispositivo.
      </p>
      <p>
        <strong>Cambios en estos Términos de Servicio</strong>
      </p>
      <p>
        Podemos actualizar nuestros Términos de Servicio de vez en cuando. Por
        lo tanto, se le recomienda que revise esta página periódicamente para
        ver cualquier cambio. Le notificaremos cualquier cambio publicando los
        nuevos Términos de Servicio en esta página. Estos cambios son efectivos
        inmediatamente después de ser publicados en esta página.
      </p>
      <p>
        <strong>Contacto</strong>
      </p>
      <p>
        Si tiene alguna pregunta o sugerencia sobre nuestros Términos de
        Servicio, no dude en contactarnos en support@aquiestoy.io
      </p>
    </React.Fragment>
  );
}
