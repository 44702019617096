import { Copyright } from "@/components/copyright";
import GlobalLoading from "@/components/global-loading";
import { assignPathWith, getParams } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useQuery } from "@apollo/client";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const GET_ORGANIZATION = gql`
  query GetOrganization($organizationID: UUID!) {
    GetOrganization(id: $organizationID) {
      id
      name
      slug
    }
  }
`;

export default function MemberIndex() {
  const { t } = useTranslation("member");
  const navigate = useNavigate();
  const [organization, setOrganization] = useState({
    id: "",
    slug: "",
    name: "",
  });

  const params = getParams();
  let { organizationID } = useParams();
  if (!organizationID) organizationID = params.get("organization_id") || "";

  const getOrganization = useQuery(GET_ORGANIZATION, {
    variables: { organizationID },
  });

  if (getOrganization.loading) return <GlobalLoading />;
  if (getOrganization.data) {
    if (organization.id === "")
      setOrganization(getOrganization.data.GetOrganization);
  }
  if (getOrganization.error) {
    return <NotFound error={getOrganization.error} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <EmojiPeopleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("organization.welcome", { name: organization.name })}
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  assignPathWith(
                    navigate,
                    `/member/${organization.id}/sign-up`
                  );
                }}
                size="large"
                sx={{ mt: 2, p: 3, textTransform: "none" }}
              >
                <Trans t={t} i18nKey="organization.create-new-account-button">
                  {" "}
                  <br /> {{ name: organization.name }}
                </Trans>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  assignPathWith(
                    navigate,
                    `/authentication/sign-in?organization_id=${organizationID}`
                  );
                }}
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 2, p: 3, textTransform: "none" }}
              >
                <Trans t={t} i18nKey="organization.already-have-account-button">
                  {" "}
                  <br />{" "}
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
