import { Copyright } from "@/components/copyright";
import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

import i18n from "@/i18n";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export default function PrivacyStatement() {
  const { t } = useTranslation("legal");

  let legalPaper;
  if (i18n.language === "es") {
    legalPaper = <TermsSpanish />;
  } else {
    legalPaper = <TermsEnglish />;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <AccountBalanceIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ align: "center" }}>
          {t("legal.privacy-statement")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        {legalPaper}
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

export function TermsEnglish() {
  return (
    <React.Fragment>
      <p>
        The terms used in this Privacy Statement have the same meanings as in
        our Terms of service.
      </p>
      <p>
        Aquiestoy built this application as a Commercial app. This SERVICE is
        intended for use as is.
      </p>
      <p>
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Statement.
      </p>
      <p>
        <strong>Information Collection and Use</strong>
      </p>
      <p>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information.
      </p>
      <p>
        The information you provide within the application (such as your email
        address, group name and members, or operations) may be collected by our
        Service, stored on your device and/or send to our Server.
      </p>
      <p>
        The app does use third party services that may collect information used
        to identify you. Link to privacy policy of third party service providers
        used by the app
      </p>
      <ul>
        <li>Mixpanel</li>
        <li>Sentry</li>
      </ul>
      <p>
        <strong>Location Data</strong>
      </p>
      <p>
        This app may collect and use location data to provide certain features
        and improve user experience. For example, we may use location data to
        personalize content or provide location-based services. The location
        data is collected through your device’s GPS, Wi-Fi, or other
        network-based means. You have the option to enable or disable location
        services through your device settings. If you choose to disable location
        services, certain features of the app may not function properly.
      </p>
      <p>
        <strong>Log Data</strong>
      </p>
      <p>
        We want to inform you that whenever you use our Service, we collect data
        and information (through third party products) on your phone called Log
        Data. This Log Data may include information such as your device Internet
        Protocol (“IP”) address, device name, operating system version, the
        configuration of the app when utilizing our Service, the time and date
        of your use of the Service, and other statistics.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device&#39;s internal
        memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly. However, the app
        may use third party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>
      <p>
        <strong>Service Providers</strong>
      </p>
      <p>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We want to inform users of this Service that these third parties have
        access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <p>
        <strong>Security</strong>
      </p>
      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>
      <p>
        <strong>Links to Other Sites</strong>
      </p>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by Aquiestoy. Therefore, we strongly
        advise you to review the Privacy Statement of these websites. We have no
        control over and assume no responsibility for the content, privacy
        policies, or practices of any third-party sites or services.
      </p>
      <p>
        <strong>Children’s Privacy</strong>
      </p>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, We immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that I will
        be able to do necessary actions.
      </p>
      <p>
        <strong>Changes to This Privacy Statement</strong>
      </p>
      <p>
        We may update our Privacy Statement from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Statement on this page.
        These changes are effective immediately after they are posted on this
        page.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about my Privacy Statement, do
        not hesitate to contact us: support@aquiestoy.io
      </p>
    </React.Fragment>
  );
}

export function TermsSpanish() {
  return (
    <React.Fragment>
      <p>
        Los términos utilizados en esta Declaración de Privacidad tienen los
        mismos significados que en nuestros Términos de servicio.
      </p>
      <p>
        Aquiestoy construyó esta aplicación como una app Comercial. Este
        SERVICIO está destinado a ser utilizado tal como es.
      </p>
      <p>
        Esta página se utiliza para informar a los visitantes sobre nuestras
        políticas en relación con la recopilación, uso y divulgación de
        Información Personal si alguien decide utilizar nuestro Servicio.
      </p>
      <p>
        Si decides utilizar nuestro Servicio, entonces aceptas la recopilación y
        el uso de información en relación con esta política. La Información
        Personal que recopilamos se utiliza para proporcionar y mejorar el
        Servicio. No utilizaremos ni compartiremos tu información con nadie,
        excepto como se describe en esta Declaración de Privacidad.
      </p>
      <p>
        <strong>Recopilación y Uso de Información</strong>
      </p>
      <p>
        Para una mejor experiencia, mientras utilizas nuestro Servicio, es
        posible que te solicitemos que nos proporciones cierta información de
        identificación personal.
      </p>
      <p>
        La información que proporciones dentro de la aplicación (como tu
        dirección de correo electrónico, nombre del grupo y miembros, o las
        operaciones) puede ser recopilada por nuestro Servicio, almacenada en tu
        dispositivo y/o enviada a nuestro servidor.
      </p>
      <p>
        La aplicación utiliza servicios de terceros que pueden recopilar
        información utilizada para identificarte. Enlace a la política de
        privacidad de los proveedores de servicios de terceros utilizados por la
        aplicación:
      </p>
      <ul>
        <li>Mixpanel</li>
        <li>Sentry</li>
      </ul>
      <p>
        <strong>Datos de Ubicación</strong>
      </p>
      <p>
        Esta aplicación puede recopilar y utilizar datos de ubicación para
        proporcionar ciertas características y mejorar la experiencia del
        usuario. Por ejemplo, podemos utilizar datos de ubicación para
        personalizar contenido o proporcionar servicios basados en la ubicación.
        Los datos de ubicación se recopilan a través del GPS de tu dispositivo,
        Wi-Fi u otros medios basados en la red. Tienes la opción de habilitar o
        deshabilitar los servicios de ubicación a través de la configuración de
        tu dispositivo. Si eliges desactivar los servicios de ubicación, es
        posible que algunas funciones de la aplicación no funcionen
        correctamente.
      </p>
      <p>
        <strong>Datos de Registro</strong>
      </p>
      <p>
        Queremos informarte que cada vez que utilices nuestro Servicio,
        recopilamos datos e información (a través de productos de terceros) en
        tu teléfono llamados Datos de Registro. Estos Datos de Registro pueden
        incluir información como la dirección de Protocolo de Internet (“IP”) de
        tu dispositivo, el nombre del dispositivo, la versión del sistema
        operativo, la configuración de la aplicación cuando se utiliza nuestro
        Servicio, la hora y fecha de tu uso del Servicio, y otras estadísticas.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Las cookies son archivos con una pequeña cantidad de datos que se
        utilizan comúnmente como identificadores únicos anónimos. Estos son
        enviados a tu navegador desde los sitios web que visitas y se almacenan
        en la memoria interna de tu dispositivo.
      </p>
      <p>
        Este Servicio no utiliza estas “cookies” explícitamente. Sin embargo, la
        aplicación puede utilizar código y bibliotecas de terceros que usan
        “cookies” para recopilar información y mejorar sus servicios. Tienes la
        opción de aceptar o rechazar estas cookies y saber cuándo se está
        enviando una cookie a tu dispositivo. Si decides rechazar nuestras
        cookies, es posible que no puedas utilizar algunas partes de este
        Servicio.
      </p>
      <p>
        <strong>Proveedores de Servicios</strong>
      </p>
      <p>
        Podemos emplear empresas e individuos de terceros por las siguientes
        razones:
      </p>
      <ul>
        <li>Para facilitar nuestro Servicio;</li>
        <li>Para proporcionar el Servicio en nuestro nombre;</li>
        <li>Para realizar servicios relacionados con el Servicio; o</li>
        <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li>
      </ul>
      <p>
        Queremos informar a los usuarios de este Servicio que estos terceros
        tienen acceso a tu Información Personal. La razón es para realizar las
        tareas que se les asignen en nuestro nombre. Sin embargo, están
        obligados a no divulgar o utilizar la información para ningún otro
        propósito.
      </p>
      <p>
        <strong>Seguridad</strong>
      </p>
      <p>
        Valoramos tu confianza al proporcionarnos tu Información Personal, por
        lo tanto, nos esforzamos en utilizar medios comercialmente aceptables
        para protegerla. Pero recuerda que ningún método de transmisión por
        internet, o método de almacenamiento electrónico es 100% seguro y
        confiable, y no podemos garantizar su absoluta seguridad.
      </p>
      <p>
        <strong>Enlaces a Otros Sitios</strong>
      </p>
      <p>
        Este Servicio puede contener enlaces a otros sitios. Si haces clic en un
        enlace de terceros, serás dirigido a ese sitio. Ten en cuenta que estos
        sitios externos no son operados por Aquiestoy. Por lo tanto, te
        recomendamos encarecidamente que revises la Declaración de Privacidad de
        estos sitios web. No tenemos control sobre el contenido, las políticas
        de privacidad o las prácticas de ningún sitio o servicio de terceros y
        no asumimos responsabilidad alguna por ellos.
      </p>
      <p>
        <strong>Privacidad de los Niños</strong>
      </p>
      <p>
        Estos Servicios no se dirigen a menores de 13 años. No recopilamos
        conscientemente información de identificación personal de niños menores
        de 13 años. En el caso de que descubramos que un niño menor de 13 años
        nos ha proporcionado información personal, la eliminamos inmediatamente
        de nuestros servidores. Si eres un padre o tutor y tienes conocimiento
        de que tu hijo nos ha proporcionado información personal, por favor
        contáctanos para que podamos tomar las medidas necesarias.
      </p>
      <p>
        <strong>Cambios a Esta Declaración de Privacidad</strong>
      </p>
      <p>
        Podemos actualizar nuestra Declaración de Privacidad de vez en cuando.
        Por lo tanto, te aconsejamos que revises esta página periódicamente para
        ver si hay cambios. Te notificaremos de cualquier cambio publicando la
        nueva Declaración de Privacidad en esta página. Estos cambios entran en
        vigor inmediatamente después de su publicación en esta página.
      </p>
      <p>
        <strong>Contáctanos</strong>
      </p>
      <p>
        Si tienes alguna pregunta o sugerencia sobre mi Declaración de
        Privacidad, no dudes en contactarnos: support@aquiestoy.io
      </p>
    </React.Fragment>
  );
}
