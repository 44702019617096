import AstroQuestion from "@/components/astro-question";
import { GoogleMapMultipleMarkers, Marker } from "@/components/google-map";
import { SensitiveData } from "@/components/sensitive-data";
import { Transition } from "@/components/transition";
import { isAlert } from "@/helpers";
import { eventBusEmit } from "@/helpers/event-bus";
import {
  capitalizeFirst,
  memberName,
  removeHiglightAfter,
} from "@/helpers/format";
import { getAccuracy } from "@/helpers/geo";
import { naiveRound } from "@/helpers/maths";
import { getDurationFromString, relativeDate } from "@/helpers/time";
import { Event, Member } from "@/interfaces";
import { dialogStyle, tableHeadSx } from "@/theme";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TableBody,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const GET_MY_COMMENT = gql`
  query GetMyComment($eventID: UUID!) {
    GetMyComment(eventID: $eventID) {
      id
      content
    }
  }
`;

const UPSERT_EVENT_COMMENT = gql`
  mutation UpsertComment($eventID: UUID!, $content: String!) {
    UpsertComment(eventID: $eventID, content: $content) {
      id
      content
    }
  }
`;

import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";

export function ActionDisplay({ event }) {
  const { t } = useTranslation("organization");
  let color;
  let icon: React.ReactElement;
  let tooltip: string;
  let action: string = "";

  color = "error"; // default

  if (event.action === "CHECKIN") {
    color = "primary";
    action = capitalizeFirst(t(`events.action.check-in`));
  } else if (event.action === "CHECKOUT") {
    color = "secondary";
    action = capitalizeFirst(t(`events.action.check-out`));
  }

  let warning: boolean;
  if (isAlert(event.alerts, "system-checkout")) {
    warning = true;
    color = "error";
  } else {
    warning = false;
  }
  if (event.triggeredBy === "SYSTEM") {
    icon = <CloseIcon />;
    tooltip = t("events.system-checkout-tooltip");
  } else if (event.triggeredBy === "ADMIN") {
    icon = <DoNotTouchIcon color={color} />;
    tooltip = t("events.admin-action-tooltip");
  } else if (event.triggeredBy === "GEOFENCE") {
    icon = <FilterTiltShiftIcon color={color} />;
    tooltip = t("events.geofence-action-tooltip");
  } else {
    icon = <CheckIcon color={color} />;
    tooltip = t("events.member-checkout-tooltip");
  }
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Chip
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
        label={action}
        color={warning ? color : "default"}
        icon={icon}
      />
    </Tooltip>
  );
}

const displayMemberName = (member: Member): string => {
  if (member.firstName === null && member.lastName === null)
    return member.phone || member.email || "";
  return `${member.firstName} ${member.lastName}`;
};

export function ZoneCell({ event }: { event: Event }) {
  const { t, i18n } = useTranslation("organization");
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    // if we open the dialog, we should pause the event refresh
    // or it'll kinda crash them
    if (openDialog) {
      eventBusEmit({ type: "refetch-events", payload: false });
    } else {
      eventBusEmit({ type: "refetch-events", payload: true });
    }
  }, [openDialog]);

  const farAwayAlert = isAlert(event.alerts, "far-away-checking");

  const markers: Marker[] = [];

  if (event.location !== null) {
    const memberMarker: Marker = {
      latitude: event.location.latitude,
      longitude: event.location.longitude,
      accuracy: event.location.accuracy,
      name: displayMemberName(event.member),
      color: farAwayAlert ? "red" : "blue",
    };

    if (memberMarker.latitude !== 0 && memberMarker.longitude !== 0) {
      markers.push(memberMarker);
    }
  }

  if (event.zone.location !== null) {
    const zoneMarker: Marker = {
      latitude: event.zone.location.latitude,
      longitude: event.zone.location.longitude,
      accuracy: event.zone.location.accuracy,
      name: event.zone.name || "",
      color: "black",
    };

    if (zoneMarker.latitude !== 0 && zoneMarker.longitude !== 0) {
      markers.push(zoneMarker);
    }
  }

  // dialog to show map of position
  const LocationDialog = () => {
    // if there's no marker, there's no point in making the dialog
    if (markers.length === 0) {
      return <></>;
    }

    return (
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        sx={dialogStyle}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {displayMemberName(event.member)}
          <Chip
            style={{ float: "right" }}
            label={relativeDate(event.createdAt, i18n)}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GoogleMapMultipleMarkers markers={markers} />;
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            {t("members.create-event-close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  let color;
  let icon: JSX.Element;

  // if it's a moving zone we don't need much information
  if (event.zone.kind === "MOVING") {
    icon = <TimeToLeaveIcon />;
    return (
      <React.Fragment>
        <LocationDialog />
        <Chip
          color={color}
          icon={icon}
          label={event.zone.name}
          onClick={() => {
            setOpenDialog(true);
          }}
          clickable={markers.length > 0}
        />
      </React.Fragment>
    );
  }

  // if there's no location we just keep it unknown
  if (!event.location) {
    icon = <LocationOnIcon />;
    const tooltip: string = t("events.zone-no-position-tooltip");
    return (
      <Tooltip title={tooltip} placement="top" arrow>
        <Chip color={color} icon={icon} label={event.zone.name} />
      </Tooltip>
    );
  }

  // down below is only the case with locations
  if (event.location) {
    const distance: number = naiveRound(event.distance, 2);
    const tooltip: string = t("events.zone-position-tooltip", { distance });
    const accuracy: JSX.Element = getAccuracy(event.location.accuracy);

    if (farAwayAlert) {
      color = "error";
      icon = <CloseIcon />;
    } else {
      color = "default";
      icon = accuracy;
    }

    return (
      <React.Fragment>
        <LocationDialog />
        <Tooltip title={tooltip} placement="top" arrow>
          <Chip
            color={color}
            icon={icon}
            label={event.zone.name}
            onClick={() => {
              setOpenDialog(true);
            }}
            clickable={markers.length > 0}
          />
        </Tooltip>
      </React.Fragment>
    );
  }

  return <></>;
}

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export function ActionMore({ event, member }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label={t("events.more-button")}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuActionMetrics member={member} />
        <MenuActionComment event={event} handleClose={handleClose} />
      </Menu>
    </React.Fragment>
  );
}

import { FormError, FormInfo, FormSuccess } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { gql, useMutation, useQuery } from "@apollo/client";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DeviceChip } from "../members/devices/list";
import { MenuActionMetrics } from "../members/list";

export function MenuActionComment({ event, handleClose }) {
  const { t } = useTranslation("organization");
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <CommentDialog open={open} setOpen={setOpen} event={event} />
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.COMMENTS,
            permission: Permission.CREATE,
          })
        }
        onClick={() => {
          handleClose();
          setOpen(true);
        }}
      >
        <ListItemIcon>
          <EditNoteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("events.add-comment-button")}</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export function CommentDialog({ open, setOpen, event }) {
  const { t } = useTranslation("organization");

  const getMyComment = useQuery(GET_MY_COMMENT, {
    skip: !open,
    variables: { eventID: event.id },
  });

  const [createComment, { data, loading, error }] =
    useMutation(UPSERT_EVENT_COMMENT);
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    if (getMyComment.data) {
      setContent(getMyComment.data.GetMyComment.content);
    }
    if (data) {
      getMyComment.refetch();
    }
  }, [getMyComment.data, data]);

  const handleComment = () => {
    createComment({
      variables: {
        eventID: event.id,
        content,
      },
    });
  };

  const name = memberName(event.member);

  return (
    <Dialog
      open={open}
      sx={dialogStyle}
      TransitionComponent={Transition}
      onClose={() => {
        setOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("events.comment-for", { name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        <DialogContentText>
          <FormError error={error} sx={{ mb: 1 }} />
          {data ? (
            <FormSuccess message={t("events.comment-saved")} sx={{ mb: 1 }} />
          ) : (
            <></>
          )}
          <FormInfo message={t("events.add-comment-info")} />
          <TextField
            sx={{ mt: 2 }}
            autoComplete="off"
            name="content"
            value={content}
            disabled={data}
            onChange={(e) => setContent(e.target.value)}
            fullWidth
            id="content"
            label={t("events.comment.content")}
          />
          <LoadingButton
            loading={loading || getMyComment.loading}
            disabled={loading || data || getMyComment.loading}
            onClick={handleComment}
            text={t("events.comment-save-button")}
            fullWidth
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("members.create-event-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ListEvents({ events }: { events: Event[] }) {
  const { i18n, t } = useTranslation(["organization", "misc"]);

  React.useEffect(() => {
    if (events.length > 0) {
      removeHiglightAfter(2100);

      // this is when it starts appearing
      if (events.length >= 2) {
        eventBusEmit({ type: "menu-items", payload: "show-reports" });
      }
    }
  }, [events]);

  if (events.length === 0)
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid item xs={4} lg={3} sx={{ mb: 2 }}>
          <AstroQuestion />
          <Typography>
            <span
              dangerouslySetInnerHTML={{
                __html: t("events.please-scan-qr-codes", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Typography>
        </Grid>
      </Stack>
    );

  const RECENT_IN_SECONDS = 60; // in seconds
  const recentEvent = new Date().setSeconds(
    new Date().getSeconds() - RECENT_IN_SECONDS
  );

  const TimeWorked = ({ event }: { event: Event }) => {
    if (!event.session?.sessionDuration) return <></>;
    const readableDuration = getDurationFromString(
      event.session.sessionDuration,
      true,
      t
    );

    // if it was triggered by our system
    // we must warn people looking at the dashboard
    if (event.triggeredBy == "SYSTEM") {
      return (
        <Tooltip
          title={t("events.time-worked-system-tooltip")}
          arrow
          placement="top"
        >
          <span className="border-[0] border-dashed border-b-2 border-red fit-content">
            {readableDuration}
          </span>
        </Tooltip>
      );
    }

    return <span>{readableDuration}</span>;
  };

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow key="0">
            <TableCell sx={tableHeadSx}>{t("events.table-date")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("events.table-name")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("events.table-action")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("events.table-zone")}</TableCell>
            <TableCell sx={tableHeadSx}>
              {t("events.table-time-worked")}
            </TableCell>
            <TableCell sx={tableHeadSx}> {t("events.table-device")}</TableCell>
            <TableCell sx={tableHeadSx}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow
              key={event.id}
              className={
                new Date(recentEvent) < parseISO(event.createdAt)
                  ? "highlightedRow"
                  : ""
              }
            >
              <TableCell>
                {capitalizeFirst(relativeDate(event.createdAt, i18n))}
              </TableCell>
              <TableCell>
                <SensitiveData fakeType="name">
                  {event.member.firstName} {event.member.lastName}
                </SensitiveData>
              </TableCell>
              <TableCell>
                <ActionDisplay event={event} />
              </TableCell>
              <TableCell>
                <ZoneCell event={event} />
              </TableCell>
              <TableCell>
                <TimeWorked event={event} />
              </TableCell>
              <TableCell>
                <DeviceChip
                  device={event.device}
                  alerts={event.alerts}
                  tooltip={true}
                />
              </TableCell>
              <TableCell>
                <ActionMore event={event} member={event.member} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

// <20 = GOOD
// <50 = AVERAGE
// >100 = BAD

export default function Events({ events }: { events: Event[] }) {
  return (
    <React.Fragment>
      <ListEvents events={events} />
    </React.Fragment>
  );
}
