import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { URLforHTMLExport } from "@/helpers";
import {
  arrayToCSV,
  convertDailyMetricsToDataset,
  downloadCSV,
} from "@/helpers/csv";
import { eventBusEmit } from "@/helpers/event-bus";
import { getLanguage } from "@/helpers/identity";
import { assignPathWith, updateSearchParams } from "@/helpers/navigation";
import {
  dateToReadableDayMonthYear,
  getDurationFromString,
  localeFromI18n,
} from "@/helpers/time";
import {
  Member,
  SessionsDailyMetric,
  SessionsMetric,
  Zone,
} from "@/interfaces";
import Title from "@/pages/organization/dashboard/components/title";
import { dialogStyle, tableContainerSx } from "@/theme";
import { gql, useQuery } from "@apollo/client";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import PushPinIcon from "@mui/icons-material/PushPin";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TableContainer,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TimelineAverageDaily from "./timeline-average-daily";
import Timesheet from "./timesheet";

const GET_MEMBER = gql`
  query GetMember($id: UUID!) {
    GetMember(id: $id) {
      id
      organizationID
      firstName
      lastName
      email
      role
      nationalID
    }
  }
`;

const GET_SESSIONS_METRIC = gql`
  query GetSessionsMetric(
    $startDate: Time!
    $endDate: Time!
    $memberId: UUID!
    $zoneId: UUID
  ) {
    GetSessionsMetric(
      startDate: $startDate
      endDate: $endDate
      memberId: $memberId
      zoneId: $zoneId
    ) {
      totalSessions
      totalDuration
      averageDuration
      highestDuration
      lowestDuration
      earliestStartedAt
      latestStartedAt
      earliestEndedAt
      latestEndedAt
      firstStartedAt
      lastEndedAt
      averageDailyDuration
      highestDailyDuration
      lowestDailyDuration
    }
  }
`;

const paperStyle = {
  p: 2,
  mb: 2,
  display: "flex",
  flexDirection: "column",
};

const allowedDates = [
  "today",
  "yesterday",
  "this-week",
  "last-week",
  "2-weeks-ago",
  "this-month",
  "last-month",
  "2-months-ago",
];

const defaultDateSelector = "this-week";

export function getSearchDate() {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get("date_selector") || defaultDateSelector;
}

export function DateRangeSelector({
  dateRange,
  setDateRange,
  dateSelector,
  setDateSelector,
}) {
  const { t } = useTranslation("misc");
  const [customDateDialog, setCustomDateDialog] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    updateSearchParams({
      date_selector: dateSelector,
    });
  }, [dateSelector]);

  const searchDate = getSearchDate();
  // this happens only on page load never again
  // we set the date selector no search date
  // data if it's acceptable
  React.useEffect(() => {
    if (allowedDates.includes(searchDate) && searchDate != dateSelector) {
      setDateSelector(searchDate);
    }
  }, []);

  React.useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    switch (dateSelector) {
      case "today": {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "yesterday": {
        startDate.setDate(currentDate.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(currentDate.getDate() - 1);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "this-week": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "last-week": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek - 7);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek - 7);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "2-weeks-ago": {
        const firstDayOfTheWeek =
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7); // starts monday
        const lastDayOfTheWeek = firstDayOfTheWeek + 6;
        startDate.setDate(firstDayOfTheWeek - 14);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(lastDayOfTheWeek - 14);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "this-month": {
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setMonth(currentDate.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "last-month": {
        startDate.setMonth(currentDate.getMonth() - 1, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setDate(0);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "2-months-ago": {
        startDate.setMonth(currentDate.getMonth() - 2, 1);
        startDate.setHours(0, 0, 0, 0);
        endDate.setMonth(currentDate.getMonth() - 1, 0);
        endDate.setHours(23, 59, 59, 999);

        break;
      }
      default:
        break;
    }
    // we won't set the date range if it's a custom one
    // because it means it's probably already set from another part (custom date range)
    if (dateSelector !== "custom") {
      setDateRange({
        start: startDate,
        end: endDate,
      });
    }
  }, [setDateRange, dateSelector]);

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
      <CustomDateDialog
        customDateDialog={customDateDialog}
        setCustomDateDialog={setCustomDateDialog}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setDateSelector={setDateSelector}
      />
      <Grid item xs={12} md={6}>
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.today")}
          color={dateSelector === "today" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("today");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.yesterday")}
          color={dateSelector === "yesterday" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("yesterday");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.this-week")}
          color={dateSelector === "this-week" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("this-week");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.last-week")}
          color={dateSelector === "last-week" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("last-week");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.2-weeks-ago")}
          color={dateSelector === "2-weeks-ago" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("2-weeks-ago");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.this-month")}
          color={dateSelector === "this-month" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("this-month");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.last-month")}
          color={dateSelector === "last-month" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("last-month");
          }}
        />
        <Chip
          style={{ marginRight: 2, marginLeft: 2 }}
          label={t("time.2-months-ago")}
          color={dateSelector === "2-months-ago" ? "primary" : "default"}
          onClick={() => {
            setDateSelector("2-months-ago");
          }}
        />
      </Grid>
      <Grid style={{ marginLeft: "auto" }}>
        <Chip
          label={`${dateToReadableDayMonthYear(
            dateRange.start
          )} - ${dateToReadableDayMonthYear(dateRange.end)}`}
          color="primary"
          icon={<PushPinIcon />}
          onClick={() => {
            setCustomDateDialog(true);
          }}
        />
      </Grid>
    </Stack>
  );
}

import PartialLoading from "@/components/partial-loading";
import { Transition } from "@/components/transition";
import { DateRange, DateRangePicker } from "mui-daterange-picker";
import { FilterByZoneSelector } from "../../reports";

export function CustomDateDialog({
  customDateDialog,
  setCustomDateDialog,
  dateRange,
  setDateRange,
  setDateSelector,
}) {
  const { t, i18n } = useTranslation("organization");
  const locale = localeFromI18n(i18n);
  const [localDateRange, setLocalDateRange] = React.useState<DateRange>({});

  const handleClose = () => {
    setCustomDateDialog(false);
  };

  return (
    <Dialog
      maxWidth={false}
      sx={dialogStyle}
      open={customDateDialog}
      TransitionComponent={Transition}
      onClose={() => {
        setCustomDateDialog(false);
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("metrics.select-custom-date-range")}
      </DialogTitle>
      <DialogContent>
        <DateRangePicker
          initialDateRange={{
            startDate: dateRange.start,
            endDate: dateRange.end,
          }}
          wrapperClassName="date-range-picker-custom-class"
          maxDate={new Date()}
          open={true}
          toggle={handleClose}
          onChange={(range) => setLocalDateRange(range)}
          locale={locale}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={localDateRange === null}
          onClick={() => {
            // We set it from the beginning to the end of the days selected in the range
            localDateRange.startDate?.setHours(0, 0, 0, 0);
            localDateRange.endDate?.setHours(23, 59, 59, 999);
            setDateRange({
              start: localDateRange.startDate,
              end: localDateRange.endDate,
            });
            setDateSelector("custom");
            setCustomDateDialog(false);
          }}
          color="primary"
          variant="contained"
        >
          {t("metrics.select-custom-date-range-save")}
        </Button>
        <Button
          onClick={() => {
            setCustomDateDialog(false);
          }}
        >
          {t("metrics.select-custom-date-range-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShowDurationMetric({ name, metric, color = "primary" }) {
  const { t } = useTranslation("misc");

  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric ? getDurationFromString(metric, true, t) : "-"}
      </Typography>
    </Paper>
  );
}

export function ShowDateMetric({ name, metric, color = "primary" }) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric ? metric.split(".")[0] : "-"}
      </Typography>
    </Paper>
  );
}

export function MetricsPage({
  member,
  sessionsMetric,
  dateRange,
  loading,
  setZone,
  zone,
}) {
  const { t } = useTranslation("organization");

  const GlobalMemberMetrics = ({ member, dateRange, sessionsMetric }) => {
    return (
      <React.Fragment>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={6}>
            <TimelineAverageDaily
              dateRange={dateRange}
              memberId={member.id}
              zoneId={zone.id}
              referencePoint={sessionsMetric.averageDailyDuration}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={6}>
                <ShowDurationMetric
                  name={t("members.metrics.average-work-per-day")}
                  metric={sessionsMetric.averageDailyDuration}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ShowDurationMetric
                  name={t("members.metrics.time-worked")}
                  metric={sessionsMetric.totalDuration}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={6}>
                <ShowDurationMetric
                  name={t("members.metrics.longest-work-day")}
                  metric={sessionsMetric.highestDailyDuration}
                  color="info"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ShowDurationMetric
                  name={t("members.metrics.shortest-work-day")}
                  metric={sessionsMetric.lowestDailyDuration}
                  color="info"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} md={3}>
                <ShowDateMetric
                  name={t("members.metrics.earliest-day-start")}
                  metric={sessionsMetric.earliestStartedAt}
                  color="info"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ShowDateMetric
                  name={t("members.metrics.latest-day-end")}
                  metric={sessionsMetric.latestEndedAt}
                  color="info"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ShowDateMetric
                  name={t("members.metrics.latest-day-start")}
                  metric={sessionsMetric.latestStartedAt}
                  color="info"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ShowDateMetric
                  name={t("members.metrics.earliest-day-end")}
                  metric={sessionsMetric.earliestEndedAt}
                  color="info"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Title>{t("members.metrics.time-working")}</Title>
      <Typography
        style={{ margin: "auto" }}
        justifyContent="end"
        color="text.secondary"
        sx={{ flex: 1, mb: 2 }}
      >
        {t("members.metrics.time-working-subtitle")}
      </Typography>

      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12}>
          <FilterByZoneSelector
            setSelectedZone={setZone}
            selectedZone={zone}
            onZoneClicked={() => {}}
          />
        </Grid>
      </Grid>

      {loading ? (
        <PartialLoading />
      ) : (
        <React.Fragment>
          <GlobalMemberMetrics
            member={member}
            dateRange={dateRange}
            sessionsMetric={sessionsMetric}
          />
          <Divider variant="middle" sx={{ mb: 2 }} />
          <DailyTimesheet dateRange={dateRange} member={member} zone={zone} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export function DailyTimesheet({ dateRange, member, zone }) {
  const { t } = useTranslation("organization");
  const [dailyMetrics, setDailyMetrics] = React.useState<SessionsDailyMetric[]>(
    []
  );

  const CSVFilename: string[] = [];
  if (member.firstName) CSVFilename.push(member.firstName.toLowerCase());
  if (member.lastName) CSVFilename.push(member.lastName.toLowerCase());
  if (member.id) CSVFilename.push(member.id);

  if (dateRange.start)
    CSVFilename.push(dateToReadableDayMonthYear(dateRange.start));
  if (dateRange.end)
    CSVFilename.push(dateToReadableDayMonthYear(dateRange.end));

  const CSVButton = () => {
    return (
      <Grid style={{ float: "right" }}>
        <ButtonGroup color="secondary" size="small" variant="contained">
          <Button
            onClick={() => {
              const data = arrayToCSV(
                convertDailyMetricsToDataset(t, dailyMetrics)
              );
              const filename = CSVFilename.join("-");
              downloadCSV({ data, filename });
            }}
          >
            <SimCardDownloadIcon /> {t("members.metrics.download-csv")}
          </Button>
        </ButtonGroup>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <CSVButton />

      <Title>{t("members.metrics.timesheet-title")}</Title>
      <Typography
        style={{ margin: "auto" }}
        justifyContent="end"
        color="text.secondary"
        sx={{ flex: 1, mb: 2 }}
      >
        {t("members.metrics.timesheet-description")}
      </Typography>

      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} sx={tableContainerSx}>
            <Timesheet
              dateRange={dateRange}
              memberId={member.id}
              zoneId={zone.id}
              setDailyMetrics={setDailyMetrics}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default function MembersMetrics() {
  const { t } = useTranslation("organization");
  const navigate = useNavigate();

  const [dateSelector, setDateSelector] = React.useState<string>("");
  const [, setID] = React.useState<string>("");
  const [dateRange, setDateRange] = React.useState({
    start: new Date(),
    end: new Date(),
  });
  const [member, setMember] = React.useState<Member>({
    id: null,
    organizationID: null,
    phone: null,
    createdAt: null,
    updatedAt: null,
    firstName: null,
    lastName: null,
    email: null,
  });
  const [zone, setZone] = React.useState<Zone | { id?: string; name?: string }>(
    {}
  );

  const [sessionsMetric, setSessionsMetric] = React.useState<SessionsMetric>({
    totalSessions: 0,
    totalDuration: "",
    averageDuration: "",
    highestDuration: "",
    lowestDuration: "",
    earliestStartedAt: "",
    latestStartedAt: "",
    earliestEndedAt: "",
    latestEndedAt: "",
    firstStartedAt: "",
    lastEndedAt: "",
    averageDailyDuration: "",
    highestDailyDuration: "",
    lowestDailyDuration: "",
  });

  const { memberID } = useParams();
  const getMember = useQuery(GET_MEMBER, { variables: { id: memberID } });

  const variables = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    memberId: member.id,
  };
  if (zone.id) {
    variables["zoneId"] = zone.id;
  }

  const getSessionsMetric = useQuery(GET_SESSIONS_METRIC, {
    variables,
    skip: member.id === null,
  });

  React.useEffect(() => {
    if (getMember.data) {
      setMember(getMember.data.GetMember);
      setID(getMember.data.GetMember.id);
      if (member.firstName && member.lastName) {
        const pageName: string = `${member.firstName} ${member.lastName}`;
        eventBusEmit({ type: "page-name", payload: pageName });
      }

      const rightMenu: JSX.Element = (
        <React.Fragment>
          <IconButton
            color="inherit"
            aria-label={t("members.metrics.edit-employee-button")}
            onClick={() => {
              assignPathWith(
                navigate,
                `/organization/dashboard/members/${member.id}/edit`
              );
            }}
          >
            <EditIcon />
          </IconButton>
        </React.Fragment>
      );
      eventBusEmit({ type: "right-menu", payload: rightMenu });
    }
    if (getSessionsMetric.data) {
      setSessionsMetric(getSessionsMetric.data.GetSessionsMetric);
    }
  }, [
    getMember,
    getSessionsMetric,
    member.firstName,
    member.id,
    member.lastName,
    navigate,
    t,
  ]);

  if (getMember.loading || !member.id) return <Loading />;
  if (getMember.error)
    return <PartialError error={t("members.metrics.load-employee-error")} />;

  if (getSessionsMetric.error)
    return <PartialError error={t("members.metrics.load-metrics-error")} />;

  const accessHTML = () => {
    const url = URLforHTMLExport({
      organizationId: member.organizationID,
      memberId: member.id,
      zoneId: zone.id,
      startDate: dateRange.start.toISOString(),
      endDate: dateRange.end.toISOString(),
      lang: getLanguage(),
    });

    window.open(url, "_blank");
  };

  const PrintableVersionButton = (
    <Grid style={{ float: "right" }}>
      <ButtonGroup color="secondary" size="small" variant="contained">
        <Button onClick={accessHTML}>
          <DescriptionIcon /> {t("members.metrics.printable-version")}
        </Button>
      </ButtonGroup>
    </Grid>
  );

  return (
    <React.Fragment>
      <DateRangeSelector
        dateSelector={dateSelector}
        setDateSelector={setDateSelector}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <Divider variant="middle" sx={{ mb: 2 }} />

      {sessionsMetric.totalSessions === 0 ? "" : PrintableVersionButton}

      <MetricsPage
        member={member}
        sessionsMetric={sessionsMetric}
        dateRange={dateRange}
        loading={getSessionsMetric.loading}
        setZone={setZone}
        zone={zone}
      />
    </React.Fragment>
  );
}
