import { rawURLAssign } from "@/helpers/navigation";
import { gql, useQuery } from "@apollo/client";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const GET_MY_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      name
      subscriptionType
    }
  }
`;

export const UpgradeButton = () => {
  const { t } = useTranslation("billing");
  const { loading, error, data } = useQuery(GET_MY_ORGANIZATION);

  if (loading || data?.MyOrganization?.subscriptionType) {
    return <></>;
  }

  return (
    <Button
      className="bg-white pr-2 pl-2 capitalize"
      size="small"
      startIcon={<AutoAwesomeIcon />}
      onClick={() => {
        rawURLAssign("/organization/subscription");
      }}
    >
      {t("checkout.upgrade-button")}
    </Button>
  );
};
