import { clearItem, getItem, setItem } from "@/helpers/identity";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SignalCellularAlt1BarIcon from "@mui/icons-material/SignalCellularAlt1Bar";
import SignalCellularAlt2BarIcon from "@mui/icons-material/SignalCellularAlt2Bar";

export const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 0,
};

export function getAccuracy(accuracy) {
  if (accuracy == null) return <div>-</div>;
  const accInt = parseInt(accuracy);
  if (accInt <= 30) {
    return <SignalCellularAltIcon />;
  } else if (accInt <= 90) {
    return <SignalCellularAlt2BarIcon />;
  } else {
    return <SignalCellularAlt1BarIcon />;
  }
}

export function setGeoGranted() {
  setItem("permission-granted", "true");
}

// this is a check to now if we display the whole permission request UI on the page
// someone could very well grant it and then block it, this is not 100% safe
export function isGeoGranted() {
  return !!getItem("permission-granted");
}

export function unsetGeoGranted() {
  clearItem("permission-granted");
}
