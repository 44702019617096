import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import { FormError } from "./form";

export default function PartialError({ error = "" }) {
  return (
    <React.Fragment>
      {error === "" ? (
        <ErrorOutlineIcon color="error" sx={{ margin: "auto", fontSize: 50 }} />
      ) : (
        <FormError error={error} sx={{ mb: 3 }} />
      )}
    </React.Fragment>
  );
}
