import PartialError from "@/components/partial-error";
import PartialLoading from "@/components/partial-loading";
import { getDurationFromString } from "@/helpers/time";
import { MembersMetric } from "@/interfaces";
import { currentPusher } from "@/pusher";
import { gql, useQuery } from "@apollo/client";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

const GET_MEMBERS_METRIC = gql`
  query GetMembersMetric($startDate: Time!, $endDate: Time!) {
    GetMembersMetric(startDate: $startDate, endDate: $endDate) {
      totalPresent
      percentPresent
      averageSessionDuration
    }
  }
`;

const paperStyle = {
  p: 2,
  mb: 2,
  display: "flex",
  flexDirection: "column",
};

export function ShowNumericMetric({
  name,
  metric,
  color = "primary",
  unit = "",
}) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric === 0 ? "-" : `${metric}${unit}`}
      </Typography>
    </Paper>
  );
}

export function ShowTextMetric({ name, metric, color = "primary" }) {
  return (
    <Paper sx={paperStyle}>
      <Typography component="p">{name}</Typography>
      <Typography component="p" variant="h4" color={color}>
        {metric === "" ? "-" : `${metric}`}
      </Typography>
    </Paper>
  );
}

export default function Metrics({ dateRange, organization }) {
  const { t } = useTranslation(["organization", "misc"]);
  const [membersMetric, setMembersMetric] = React.useState<MembersMetric>({
    totalPresent: 0,
    percentPresent: 0,
    averageSessionDuration: "",
  });

  const { data, error, loading, refetch } = useQuery(GET_MEMBERS_METRIC, {
    variables: {
      startDate: dateRange.start,
      endDate: dateRange.end,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (organization.id) {
      const channel = currentPusher.subscribe(
        `aquiestoy.organization.${organization.id}`
      );
      channel.bind("aquiestoy.fct.event-created.0", function (data) {
        // for now, we just refetch the events
        // because we detect an event was effectively added
        refetch();
      });
    }
  }, [organization]);

  React.useEffect(() => {
    if (data) {
      const averageSessionDuration = getDurationFromString(
        data.GetMembersMetric.averageSessionDuration,
        true,
        t
      );

      setMembersMetric(
        Object.assign(Object.assign({}, data.GetMembersMetric), {
          averageSessionDuration,
        })
      );
    }
  }, [data]);

  if (loading) return <PartialLoading />;
  if (error) return <PartialError />;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <ShowNumericMetric
            name={t("metrics.members-present")}
            metric={membersMetric.totalPresent}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShowNumericMetric
            name={t("metrics.percent-organization")}
            metric={membersMetric.percentPresent}
            unit="%"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShowTextMetric
            name={t("metrics.average-time-worked")}
            metric={membersMetric.averageSessionDuration}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
