import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export function Copyright(props) {
  const { t } = useTranslation("misc");

  return (
    <Typography color="text.secondary" align="center" {...props}>
      {`${t("layout.copyright")} © `}
      <Link color="inherit" sx={{ border: 0 }} href="https://aquiestoy.io">
        Aquiestoy
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
