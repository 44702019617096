interface DeviceInput {
  screenWidth: number;
  screenHeight: number;
  screenRatio: number;
  touchScreen: boolean;
  deviceMemory?: number;
  deviceConcurrency?: number;
  GPU?: string;
  networkConnection?: string;
  networkBandwidth?: number;
  networkRTT?: number;
}

// Extend the Navigator interface to include deviceMemory and connection properties
interface NavigatorExtended extends Navigator {
  deviceMemory?: number;
  connection?: {
    effectiveType?: string;
    downlink?: number;
    rtt?: number;
  };
  hardwareConcurrency: number;
}

// Function to get the device information
export function getDeviceInfo(): DeviceInput {
  const navigatorExtended = navigator as NavigatorExtended;
  const deviceConcurrency = navigatorExtended.hardwareConcurrency;
  const deviceMemory = navigatorExtended.deviceMemory;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  // For some reason, sometimes the devicePixelRation actually send a string
  const screenRatio = Number(window.devicePixelRatio);
  const GPU = getWebGLRenderer();
  const touchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;
  const networkBandwidth = navigatorExtended.connection
    ? navigatorExtended.connection.downlink
    : undefined;
  const networkRTT = navigatorExtended.connection
    ? navigatorExtended.connection.rtt
    : undefined;
  const networkConnection = navigatorExtended.connection
    ? navigatorExtended.connection.effectiveType
    : undefined;

  return {
    deviceMemory,
    deviceConcurrency,
    screenWidth,
    screenHeight,
    screenRatio,
    GPU,
    touchScreen,
    networkConnection,
    networkBandwidth,
    networkRTT,
  };
}

// Helper functions to extract additional information
function getWebGLRenderer(): string | undefined {
  try {
    const canvas = document.createElement("canvas");
    const gl =
      canvas.getContext("webgl") ||
      (canvas.getContext("experimental-webgl") as WebGLRenderingContext);
    if (gl) {
      const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
      if (debugInfo) {
        return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }
    }
  } catch (e) {
    console.error("WebGL not supported:", e);
  }
  return undefined;
}
