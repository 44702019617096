import { hasFake, hasSensitiveData } from "@/helpers/identity";
import React from "react";

type FakeType = "name" | "email" | "phone" | "";

export function SensitiveData({
  children,
  fakeType = "",
}: {
  children: React.ReactNode;
  fakeType?: FakeType;
}) {
  const [blur, setBlur] = React.useState<boolean>(true);

  if (!children) {
    return <div>-</div>;
  }

  let data = children;
  if (hasFake() && fakeType !== "") {
    data = <>{getRandom(fakeType)}</>;
  }

  if (!hasSensitiveData()) {
    return data;
  }

  return (
    <div
      className={blur ? "blur-sm" : ""}
      onMouseOver={() => {
        setBlur(false);
      }}
      onMouseOut={() => {
        setBlur(true);
      }}
    >
      {data}
    </div>
  );
}

const fullNames: string[] = [
  "Alice Smith",
  "Bob Johnson",
  "Charlie Williams",
  "David Jones",
  "Eve Brown",
  "Frank Davis",
  "Grace Miller",
  "Hannah Wilson",
  "Ivy Moore",
  "Jack Taylor",
  "Kevin Anderson",
  "Liam Thomas",
  "Mia Jackson",
  "Noah White",
  "Olivia Harris",
  "Paul Martin",
  "Quinn Thompson",
  "Rachel Garcia",
  "Steve Martinez",
  "Tracy Robinson",
  "Uma Clark",
  "Victor Rodriguez",
  "Wendy Lewis",
  "Xander Lee",
  "Yara Walker",
  "Zach Hall",
  "Ada Allen",
  "Bruce Young",
  "Cathy King",
  "Derek Wright",
];

const emailAddresses: string[] = [
  "alice.smith@example.com",
  "bob.johnson@example.com",
  "charlie.williams@example.com",
  "david.jones@example.com",
  "eve.brown@example.com",
  "frank.davis@example.com",
  "grace.miller@example.com",
  "hannah.wilson@example.com",
  "ivy.moore@example.com",
  "jack.taylor@example.com",
  "kevin.anderson@example.com",
  "liam.thomas@example.com",
  "mia.jackson@example.com",
  "noah.white@example.com",
  "olivia.harris@example.com",
  "paul.martin@example.com",
  "quinn.thompson@example.com",
  "rachel.garcia@example.com",
  "steve.martinez@example.com",
  "tracy.robinson@example.com",
  "uma.clark@example.com",
  "victor.rodriguez@example.com",
  "wendy.lewis@example.com",
  "xander.lee@example.com",
  "yara.walker@example.com",
  "zach.hall@example.com",
  "ada.allen@example.com",
  "bruce.young@example.com",
  "cathy.king@example.com",
  "derek.wright@example.com",
];

const phoneNumbers: string[] = [
  "+12345678901",
  "+19876543210",
  "+12125550123",
  "+14155552671",
  "+15105551234",
  "+16175553456",
  "+17185551234",
  "+18195552345",
  "+19165554456",
  "+20125551234",
  "+21225552345",
  "+22335553456",
  "+23445554567",
  "+24555555678",
  "+25665556789",
  "+26775557890",
  "+27885558901",
  "+28995559012",
  "+30125551234",
  "+31235552345",
  "+32345553456",
  "+33455554567",
  "+34565555678",
  "+35675556789",
  "+36785557890",
  "+37895558901",
  "+38995559012",
  "+40125551234",
  "+41235552345",
  "+42345553456",
];

// Function to get a random item from an array
function getRandomItem<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

// Function to generate a random full name
function getRandom(fakeType: FakeType): string {
  switch (fakeType) {
    case "name":
      return getRandomItem(fullNames);
    case "email":
      return getRandomItem(emailAddresses);
    case "phone":
      return getRandomItem(phoneNumbers);
    default:
      return "";
  }
}
