// import MapPin from "@/assets/map-pin.svg";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Grid } from "@mui/material";
import { useEffect, useRef } from "react";

const DEFAULT_ZOOM = 13;
const API_KEY = import.meta.env.VITE_GOOGLE_CLOUD_API_KEY;
const MAP_ID = "d128efcb44e820a5";

const parser = new DOMParser();
const brandedMapMarker = `<svg width="42" height="56" viewBox="0 0 288 384" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.98 221.963L23.7 223.003C24.58 224.323 25.5 225.603 26.42 227.003L130.66 376.763C132.16 378.917 134.158 380.676 136.484 381.891C138.81 383.106 141.396 383.74 144.02 383.74C146.644 383.74 149.23 383.106 151.556 381.891C153.882 380.676 155.88 378.917 157.38 376.763L261.38 227.123C262.38 225.723 263.34 224.363 264.3 223.123L264.94 222.163C278.993 200.419 286.924 175.289 287.898 149.417C288.872 123.545 282.853 97.8897 270.474 75.1506C258.096 52.4116 239.816 33.4304 217.558 20.2052C195.301 6.97999 169.89 0 144 0C118.11 0 92.6989 6.97999 70.4416 20.2052C48.1843 33.4304 29.9042 52.4116 17.5257 75.1506C5.14711 97.8897 -0.872002 123.545 0.10195 149.417C1.0759 175.289 9.00689 200.419 23.06 222.163L22.98 221.963Z" fill="#1C92FF"/>
<path d="M224.034 75.5086C219.034 75.6752 214.297 77.7852 210.83 81.3903C174.041 118.259 145.946 149.051 111.802 183.899L75.4317 153.171C73.5018 151.539 71.2689 150.304 68.8609 149.536C66.4529 148.769 63.9172 148.484 61.399 148.699C58.8807 148.913 56.4296 149.622 54.1858 150.785C51.9421 151.948 49.9499 153.543 48.3234 155.477C46.697 157.411 45.4681 159.648 44.7074 162.058C43.9467 164.468 43.6689 167.004 43.8901 169.522C44.1113 172.04 44.8271 174.489 45.9965 176.729C47.1659 178.97 48.7658 180.958 50.7047 182.579L100.639 224.831C104.313 227.926 109.013 229.531 113.812 229.331C118.612 229.131 123.162 227.14 126.566 223.751C167.934 182.293 197.383 149.18 237.958 108.518C240.744 105.824 242.651 102.351 243.427 98.5537C244.203 94.7565 243.813 90.8132 242.306 87.2422C240.8 83.6711 238.249 80.6393 234.987 78.5451C231.726 76.4509 227.908 75.3925 224.034 75.5086Z" fill="white"/>
</svg>`;

const defaultMapOptions: google.maps.MapOptions = {
  zoom: DEFAULT_ZOOM,
  fullscreenControl: false,
  panControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  mapId: MAP_ID,
};

const brandedMapMarkerSVG = parser.parseFromString(
  brandedMapMarker,
  "image/svg+xml"
).documentElement;

export interface Marker {
  latitude: number;
  longitude: number;
  accuracy: number;
  name: string;
  color: "blue" | "red" | "black";
}

export function GoogleMapMultipleMarkers({ markers }: { markers: Marker[] }) {
  if (markers.length === 0) {
    return <></>;
  }

  return (
    <Grid container>
      <Wrapper apiKey={API_KEY}>
        <MultipleMarkersMap markers={markers} />
      </Wrapper>
    </Grid>
  );
}

export const MultipleMarkersMap = ({ markers }: { markers: Marker[] }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const mapOptions: google.maps.MapOptions = Object.assign(
      defaultMapOptions,
      {
        center: { lat: markers[0].latitude, lng: markers[0].longitude },
      }
    );

    // we need to use async for the library load
    (async () => {
      preventMapsStyleSpread();

      const { Map, AdvancedMarkerElement } = await loadElements();
      const map = new Map(ref.current as HTMLElement, mapOptions);

      // to extend the map to
      // the separation related to the markers
      selfZoomAdjustments({ map, markers });

      markers.map((marker) => {
        const mapTag = document.createElement("div");
        mapTag.className = `map-tag map-tag__${marker.color}`;
        mapTag.textContent = marker.name;

        new AdvancedMarkerElement({
          map,
          content: mapTag,
          position: { lat: marker.latitude, lng: marker.longitude },
        });
      });
    })();
  }, []);

  return (
    <div
      ref={ref}
      style={{
        height: "400px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
  );
};

function selfZoomAdjustments({ map, markers }) {
  const latlngbounds = new google.maps.LatLngBounds();
  markers.map((marker) => {
    latlngbounds.extend(
      new google.maps.LatLng(marker.latitude, marker.longitude)
    );
  });
  map.fitBounds(latlngbounds);
}

export function GoogleMapOneDraggableMarker({ geolocation, setGeolocation }) {
  if (!geolocation.latitude || !geolocation.longitude) {
    return <></>;
  }

  return (
    <Grid container>
      <Wrapper apiKey={API_KEY}>
        <DraggableOneMarkerMap
          geolocation={geolocation}
          setGeolocation={setGeolocation}
        />
      </Wrapper>
    </Grid>
  );
}

export const DraggableOneMarkerMap = ({ geolocation, setGeolocation }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const mapOptions: google.maps.MapOptions = Object.assign(
      defaultMapOptions,
      {
        center: { lat: geolocation.latitude, lng: geolocation.longitude },
      }
    );

    // we need to use async for the library load
    (async () => {
      preventMapsStyleSpread();

      const { Map, AdvancedMarkerElement } = await loadElements();
      const map = new Map(ref.current as HTMLElement, mapOptions);

      const draggableMarker = new AdvancedMarkerElement({
        map,
        content: brandedMapMarkerSVG,
        position: { lat: geolocation.latitude, lng: geolocation.longitude },
        gmpDraggable: true,
      });

      draggableMarker.addListener("dragend", () => {
        const position = draggableMarker.position as google.maps.LatLng;
        setGeolocation({
          latitude: position.lat,
          longitude: position.lng,
          // we keep the same accuracy
          accuracy: geolocation.accuracy,
        });
      });
    })();
  }, []);

  return (
    <div
      ref={ref}
      style={{
        height: "400px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
  );
};

export async function loadElements() {
  const { Map } = (await google.maps.importLibrary(
    "maps"
  )) as google.maps.MapsLibrary;
  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    "marker"
  )) as google.maps.MarkerLibrary;
  return { Map, AdvancedMarkerElement };
}

export function preventMapsStyleSpread() {
  // This is to prevent Google Maps from loading Roboto
  // And fucking up the boldness of the whole application
  const head = document.getElementsByTagName("head")[0];
  const insertBefore = head.insertBefore;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  head.insertBefore = function (newElement, referenceElement) {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newElement.href &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newElement.href.indexOf("//fonts.googleapis.com/css?family=Roboto") > -1
    ) {
      console.info("Prevented Roboto from loading!");
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };
}
