import Loading from "@/components/loading";
import PartialError from "@/components/partial-error";
import { eventBusEmit } from "@/helpers/event-bus";
import { assignPathWith } from "@/helpers/navigation";
import { Organization, Zone } from "@/interfaces";
import { gql, useQuery } from "@apollo/client";
import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Title from "../components/title";

const GET_MYSELF_ORGANIZATION = gql`
  query MyOrganization {
    MyOrganization {
      id
      zones {
        id
        name
        geofencing
        kind
        qrcodeSignedURL
      }
      zoneSetting {
        id
        geofenceRadius
        qrcodeAccessLocationMode
      }
    }
  }
`;

export default function ZonesQRCode() {
  const { t } = useTranslation(["organization", "misc"]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [updateZoneSetting, setUpdateZoneSetting] = useState<boolean>(false);
  const navigate = useNavigate();

  const [organization, setOrganization] = useState<Organization>({
    id: null,
  });
  const [zones, setZones] = useState<Zone[]>([]);
  const getMyOrganization = useQuery(GET_MYSELF_ORGANIZATION);

  useEffect(() => {
    if (error) {
      eventBusEmit({ type: "form-error", payload: error });
      setError("");
      return;
    }

    if (success)
      eventBusEmit({
        type: "form-success",
        payload: t("settings.edit.updated"),
      });
    setSuccess(false);
  }, [success, error]);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("zones.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  if (getMyOrganization.loading) return <Loading />;
  if (getMyOrganization.data) {
    if (organization.id === null)
      setOrganization(getMyOrganization.data.MyOrganization);

    const organizationZones = getMyOrganization.data.MyOrganization.zones;
    if (
      zones.length === 0 &&
      organizationZones &&
      organizationZones.length !== 0
    ) {
      setZones(organizationZones);
    }
  }
  if (getMyOrganization.error) {
    return <PartialError error={t("zones.qrcode-loading-error")} />;
  }

  const addZone = (event: React.MouseEvent) => {
    event.preventDefault();

    assignPathWith(navigate, "/organization/zones/create");
  };

  const NewZoneButton = () => {
    if (
      !permitAccess({
        role: getRole(),
        resource: Resource.DASHBOARD,
        permission: Permission.CREATE,
      })
    )
      return <></>;

    return (
      <Button
        className="w-auto float-right"
        type="submit"
        startIcon={<AddLocationIcon />}
        sx={{ textTransform: "none" }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={addZone}
      >
        {t("zones.add-new-zone-submit")}
      </Button>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7} lg={8}>
            <Title>{t("zones.qrcode-title")}</Title>
            <Typography color="text.secondary" sx={{ flex: 1, mb: 2 }}>
              {t("zones.qrcode-description")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            lg={4}
            sx={{ marginBottom: 2 }}
            className="relative"
          >
            <NewZoneButton />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ZonesList zones={zones} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <ZoneSettings
              fetchedZoneSetting={
                getMyOrganization.data.MyOrganization.zoneSetting
              }
              setUpdateZoneSetting={setUpdateZoneSetting}
              updateZoneSetting={updateZoneSetting}
              setError={setError}
              setSuccess={setSuccess}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "left" }}>
            <Button
              disabled={
                !permitAccess({
                  role: getRole(),
                  resource: Resource.DASHBOARD,
                  permission: Permission.UPDATE,
                })
              }
              type="submit"
              onClick={() => {
                setUpdateZoneSetting(true);
              }}
              variant="contained"
              sx={{ mb: 2 }}
            >
              {t("settings.submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { UpgradeButton } from "@/components/upgrade-button";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { ZoneSettings } from "../settings";
import ZonesList from "./list";
