import Loading from "@/components/loading";
import { eventBusEmit, eventBusReceive } from "@/helpers/event-bus";
import { getGlobal, setGlobal } from "@/helpers/identity";
import Title from "@/pages/organization/dashboard/components/title";
import Calendar from "@/pages/organization/dashboard/live/calendar";
import Events from "@/pages/organization/dashboard/live/events";
import { dialogStyle, tableContainerSx } from "@/theme";
import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Metrics from "./metrics";
import Timeline from "./timeline";

import PartialError from "@/components/partial-error";
import { QrcodeImage } from "@/components/qr-code";
import { Transition } from "@/components/transition";
import { graphQLErrorCode } from "@/helpers/format";
import { replacePathWith } from "@/helpers/navigation";
import { relativeDateFromDate } from "@/helpers/time";
import { Event, Myself, Organization } from "@/interfaces";
import { currentPusher } from "@/pusher";
import { useNavigate } from "react-router-dom";

export const APP_STORE_URL =
  "https://apps.apple.com/us/app/aquiestoy/id6615062652";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=io.loschcode.aquiestoy";

const GET_ORGANIZATION_METRIC = gql`
  query GetOrganizationMetric {
    GetOrganizationMetric {
      totalMembers
      totalEvents
    }
  }
`;

const GET_MYSELF = gql`
  query GetMyself {
    GetMyself {
      id
      firstName
      lastName
      password
      organization {
        id
        name
        primaryZone {
          id
          kind
          qrcodeSignedURL
          location {
            latitude
            longitude
            accuracy
          }
        }
      }
    }
  }
`;

const LIST_EVENTS = gql`
  query ListEvents($startDate: Time!, $endDate: Time!) {
    ListEvents(startDate: $startDate, endDate: $endDate) {
      id
      createdAt
      action
      triggeredBy
      distance
      alerts {
        alertType
      }
      zone {
        id
        name
        kind
        location {
          id
          accuracy
          latitude
          longitude
        }
      }
      member {
        id
        firstName
        lastName
        email
        phone
      }
      device {
        id
        deviceName
        deviceBrand
        deviceType
        platformName
        platformVersion
        browserName
        browserVersion
      }
      location {
        id
        accuracy
        latitude
        longitude
      }
      session {
        id
        sessionDuration
      }
    }
  }
`;

import AppStore from "@/assets/app-store.svg";
import GooglePlay from "@/assets/google-play.svg";
import { UpgradeButton } from "@/components/upgrade-button";

export function GettingStartedInstructions({ organization }) {
  const { t } = useTranslation("organization");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ px: 0 }}>
        <Grid container spacing={2}>
          {/* Step 1 */}
          <Grid item xs={12}>
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12}>
                <Chip label="1" color="primary" size={"medium"} />
                <span style={{ paddingLeft: "0.5em", fontWeight: "bold" }}>
                  {t("getting-started.step-1")}
                </span>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={5} marginTop={1}>
                <Grid
                  item
                  xs={6}
                  onClick={() => {
                    window.open(APP_STORE_URL, "_blank");
                  }}
                >
                  <img
                    src={AppStore}
                    alt="App Store"
                    width="100%"
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  onClick={() => {
                    window.open(GOOGLE_PLAY_URL, "_blank");
                  }}
                >
                  <img
                    src={GooglePlay}
                    alt="Play Store"
                    width="100%"
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Step 2 */}
          <Grid item xs={12}>
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12}>
                <Chip label="2" color="primary" size={"medium"} />
                <span style={{ paddingLeft: "0.5em", fontWeight: "bold" }}>
                  {t("getting-started.step-2")}
                </span>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={3}>
                <Grid item xs={6}>
                  <QrcodeImage
                    image={organization.primaryZone.qrcodeSignedURL}
                    clickToDownload={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Step 3 */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Chip label="3" color="primary" size={"medium"} />
                <span style={{ paddingLeft: "0.5em", fontWeight: "bold" }}>
                  {t("getting-started.step-3")}
                </span>
              </Grid>
              <Grid container xs={8} spacing={2} marginLeft={5}>
                <Grid item xs={12}>
                  {t("getting-started.step-3-description")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function GettingStartedDialog({
  organization,
  welcomeOpen,
  setWelcomeOpen,
}) {
  const { t } = useTranslation("organization");

  return (
    <Dialog
      open={welcomeOpen}
      TransitionComponent={Transition}
      sx={dialogStyle}
      onClose={() => {
        setWelcomeOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {t("getting-started.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("getting-started.instructions")}
        </DialogContentText>
        <GettingStartedInstructions organization={organization} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setWelcomeOpen(false);
          }}
        >
          {t("getting-started.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const defaultCalendar = new Date();
const defaultStart = Object.assign(new Date(), defaultCalendar);
const defaultEnd = Object.assign(new Date(), defaultCalendar);
defaultStart.setHours(0, 0, 0, 0);
defaultEnd.setHours(23, 59, 59, 999);

export default function Live() {
  const { i18n, t } = useTranslation(["organization", "misc"]);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    eventBusReceive("refetch-events", (payload) => {
      setRefetchEvents(payload);
    });
  }, []);

  let lastEventsPolling: Date = new Date();
  // TODO: this is useless and should be just put on the preflight and recovered directly
  const [, setMyself] = useState<Myself>({
    id: null,
    password: null,
    firstName: null,
    lastName: null,
  });
  const [organization, setOrganization] = useState<Organization>({
    id: null,
    name: null,
    primaryZone: {
      id: null,
      geofencing: false,
      qrcodeSignedURL: null,
      kind: "FIXED",
      location: { latitude: 0, longitude: 0, accuracy: 0 },
    },
  });
  const [gettingStarted, setGettingStarted] = useState<boolean>(false);
  const [welcomeOpen, setWelcomeOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [calendar, setCalendar] = useState<dayjs.Dayjs | null>(null);
  const [dateRange, setDateRange] = useState({
    start: defaultStart,
    end: defaultEnd,
  });
  const getGetMyself = useQuery(GET_MYSELF);
  const listEvents = useQuery(LIST_EVENTS, {
    variables: { startDate: dateRange.start, endDate: dateRange.end },
    skip: !refetchEvents,
    onCompleted: () => {
      lastEventsPolling = new Date();
    },
    notifyOnNetworkStatusChange: true,
    // we never want to have it cached
    // it should always refetch since we are "live"
    fetchPolicy: "cache-and-network",
  });
  const getOrganizationMetric = useQuery(GET_ORGANIZATION_METRIC, {
    skip: events.length > 0,
  });

  useEffect(() => {
    if (organization.id) {
      const channel = currentPusher.subscribe(
        `aquiestoy.organization.${organization.id}`
      );
      channel.bind("aquiestoy.fct.event-created.0", function (data) {
        // const pushedEvent = JSON.stringify(data);
        // for now, we just refetch the events
        // because we detect an event was effectively added
        listEvents.refetch();
      });
    }
  }, [organization]);

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("live-events.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  useEffect(() => {
    if (listEvents.data) {
      setEvents(listEvents.data.ListEvents);

      if (listEvents.data.ListEvents.length > 0) {
        setGettingStarted(false);
        setWelcomeOpen(false);
      }
    }
  }, [listEvents]);

  useEffect(() => {
    if (getGetMyself.data) {
      setMyself(getGetMyself.data.GetMyself);
      setOrganization(getGetMyself.data.GetMyself.organization);
    }
  }, [getGetMyself]);

  useEffect(() => {
    if (calendar) {
      // we use that to clone the date so it's dereferenced
      const date = calendar.toISOString(); // calendar.$d
      const start = new Date(date);
      const end = new Date(date);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      setDateRange({
        start,
        end,
      });
    }
  }, [calendar]);

  useEffect(() => {
    if (getOrganizationMetric.data) {
      if (getOrganizationMetric.data.GetOrganizationMetric.totalEvents === 0) {
        setGettingStarted(true);
      } else {
        // we want to refetch at least once
        // when we show for the first time the dashboard
        if (gettingStarted) {
          listEvents.refetch();
          setGettingStarted(false);
        }
      }
    }
  }, [getOrganizationMetric, listEvents]);

  useEffect(() => {
    if (
      gettingStarted &&
      getGetMyself.data &&
      !getGlobal("welcome-shown-once")
    ) {
      setWelcomeOpen(true);
      setGlobal("welcome-shown-once", true);
    }
  }, [gettingStarted, getGetMyself]);

  if (getGetMyself.loading) {
    return <Loading />;
  }

  if (getGetMyself.error) {
    if (graphQLErrorCode(getGetMyself.error, "ZONE_QRCODE_NOT_FOUND")) {
      replacePathWith(navigate, "/organization/zones/create?primary=true");
      return <Loading />;
    }

    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  if (listEvents.error) {
    return (
      <PartialError error={t("error.page-data-failure", { ns: "misc" })} />
    );
  }

  return (
    <Grid container spacing={3}>
      {/* Getting Started */}
      <GettingStartedDialog
        organization={organization}
        welcomeOpen={welcomeOpen}
        setWelcomeOpen={setWelcomeOpen}
      />
      {/* Timeline */}
      <Grid item xs={12} lg={8}>
        <Title>{t("timeline.title")}</Title>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 380,
          }}
        >
          <Timeline dateRange={dateRange} organization={organization} />
        </Paper>
      </Grid>
      {/* Calendar */}
      <Grid item xs={12} lg={4}>
        <Title>{t("calendar.title")}</Title>
        <TableContainer
          component={Paper}
          sx={{ p: 2, display: "flex", flexDirection: "column", height: 380 }}
        >
          <Calendar
            defaultCalendar={defaultCalendar}
            setCalendar={setCalendar}
            // we don't want to put a loading state to the calendar once it's done once on the page
            // otherwise refetch of listEvents will cause a scroll jump (because it's somehow a complet MUI Component)
            loading={listEvents.loading && !listEvents.data}
          />
        </TableContainer>
      </Grid>
      {/* Events */}
      <Grid item xs={12}>
        <Title>{t("events.title")}</Title>
        <TableContainer component={Paper} sx={tableContainerSx}>
          <Events events={events} />
        </TableContainer>
        <Typography
          component="p"
          sx={{ mt: 2, mr: 1, color: "grey", textAlign: "right" }}
        >
          {t("events.last-data-refresh", {
            date: relativeDateFromDate(lastEventsPolling, i18n),
          })}
        </Typography>
      </Grid>
      {/* Metrics */}
      <Grid item xs={12}>
        <Title>{t("metrics.title")}</Title>
        <Metrics dateRange={dateRange} organization={organization} />
      </Grid>
    </Grid>
  );
}
