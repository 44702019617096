import AstroQuestion from "@/components/astro-question";
import { QrcodeImage } from "@/components/qr-code";
import { downloadImage } from "@/helpers";
import { Permission, Resource, permitAccess } from "@/helpers/access";
import { getRole } from "@/helpers/identity";
import { rawURLAssign } from "@/helpers/navigation";
import { Zone } from "@/interfaces";
import { tableContainerSx, tableHeadSx, tableSx } from "@/theme";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export function List({ zones }) {
  const { t } = useTranslation("organization");

  if (zones == null || zones.length === 0)
    return (
      <Stack alignItems="center" textAlign="center">
        <Grid container alignContent="center">
          <Grid item xs={12} lg={4} sx={{ mb: 3 }} margin="auto">
            <AstroQuestion />
            <Typography>{t("zones.list.nothing-to-show")}</Typography>
          </Grid>
        </Grid>
      </Stack>
    );

  const ZoneKindCell = ({ zone }: { zone: Zone }) => {
    if (zone.kind === "FIXED") {
      return (
        <Tooltip title={t("zones.kind-fixed-tooltip")} placement="top">
          <Chip icon={<LocationOnIcon />} label={t("zones.kind-fixed")} />
        </Tooltip>
      );
    } else if (zone.kind === "MOVING") {
      return (
        <Tooltip title={t("zones.kind-moving-tooltip")} placement="top">
          <Chip
            icon={<DirectionsCarFilledIcon />}
            label={t("zones.kind-moving")}
          />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const ZoneGeofencingCell = ({ zone }: { zone: Zone }) => {
    if (zone.geofencing) {
      return (
        <Tooltip title={t("zones.geofencing-on-tooltip")} placement="top">
          <Chip
            icon={<FilterTiltShiftIcon color="primary" />}
            label={t("zones.geofencing-on")}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("zones.geofencing-off-tooltip")} placement="top">
          <Chip
            icon={<FilterTiltShiftIcon color="error" />}
            label={t("zones.geofencing-off")}
          />
        </Tooltip>
      );
    }
  };

  return (
    <React.Fragment>
      <Table size="small" sx={tableSx}>
        <TableHead>
          <TableRow key="0">
            <TableCell sx={tableHeadSx}>{t("zones.list.qrcode")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("zones.list.name")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("zones.list.geofencing")}</TableCell>
            <TableCell sx={tableHeadSx}>{t("zones.list.kind")}</TableCell>
            <TableCell sx={tableHeadSx}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {zones.map((zone) => (
            <TableRow key={zone.id}>
              <TableCell>
                <QrcodeImage
                  image={zone.qrcodeSignedURL}
                  clickToDownload={false}
                  mini={true}
                />
              </TableCell>
              <TableCell>{zone.name}</TableCell>
              <TableCell>
                <ZoneGeofencingCell zone={zone} />
              </TableCell>
              <TableCell>
                <ZoneKindCell zone={zone} />
              </TableCell>
              <TableCell>
                <ActionMore zone={zone} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export function ActionMore({ zone }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (zone: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(zone.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-label={t("zones.more-button")}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        keepMounted
      >
        <MenuAction zone={zone} handleClose={handleClose} />
      </Menu>
    </React.Fragment>
  );
}

export function MenuAction({ zone, handleClose }) {
  const { t } = useTranslation("organization");

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          handleClose();
          downloadImage(zone.qrcodeSignedURL);
        }}
      >
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>{t("zones.download-qrcode")}</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={
          !permitAccess({
            role: getRole(),
            resource: Resource.DASHBOARD,
            permission: Permission.UPDATE,
          })
        }
        onClick={() => {
          handleClose();
          rawURLAssign(`/organization/dashboard/zones/${zone.id}/edit`);
        }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>{t("zones.edit-qrcode")}</ListItemText>
      </MenuItem>
    </React.Fragment>
  );
}

export default function ZonesList({ zones }) {
  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={tableContainerSx}>
        <List zones={zones} />
      </TableContainer>
    </React.Fragment>
  );
}
