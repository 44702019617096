import { Copyright } from "@/components/copyright";
import { FormError } from "@/components/form";
import GlobalSmallLoading from "@/components/global-small-loading";
import PartialLoading from "@/components/partial-loading";
import { sleep } from "@/helpers";
import { getAfterAuthPath, rawURLReplace } from "@/helpers/navigation";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import BackHandIcon from "@mui/icons-material/BackHand";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UPDATE_MYSELF = gql`
  mutation UpdateMyself($firstName: String!, $lastName: String!) {
    UpdateMyself(firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`;

const GET_MYSELF = gql`
  query GetMyself {
    GetMyself {
      id
      firstName
      lastName
    }
  }
`;

export function UpdateButton({ firstName, lastName }) {
  const { t } = useTranslation("myself");
  const navigate = useNavigate();

  const [mutationUpdateMyself, { data, loading, error }] =
    useMutation(UPDATE_MYSELF);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    mutationUpdateMyself({
      variables: { firstName, lastName },
    });
  };

  useEffect(() => {
    if (data) {
      (async () => {
        await sleep(500);
        // this will happen if we set a name when trying to scan a qrcode
        const redirectURL = getAfterAuthPath({ clear: false });
        if (redirectURL) return rawURLReplace(redirectURL);
        rawURLReplace("/");
      })();
    }
  }, [data, navigate]);

  if (data) {
    return <PartialLoading />;
  }

  if (error) {
    return <FormError error={t("name.error-on-creation")} sx={{ mb: 3 }} />;
  }

  if (loading) {
    return <PartialLoading />;
  }
  const formCompleted = firstName.length > 0 && lastName.length > 0;

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mb: 2 }}
      onClick={handleSubmit}
      disabled={!formCompleted}
    >
      {t("name.submit")}
    </Button>
  );
}

export default function MyselfUpdateName() {
  const { t } = useTranslation("myself");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [myself, setMyself] = useState<{
    id: null | string;
    firstName: string;
    lastName: string;
  }>({ id: null, firstName: "", lastName: "" });
  const getMyself = useQuery(GET_MYSELF);

  useEffect(() => {
    if (getMyself.data) {
      if (myself.id === null) setMyself(getMyself.data.GetMyself);
      if (firstName === "")
        setFirstName(getMyself.data.GetMyself.firstName || "");
      if (lastName === "") setLastName(getMyself.data.GetMyself.lastName || "");
    }
  }, [getMyself]);

  if (getMyself.loading || !myself) return <GlobalSmallLoading />;
  if (getMyself.error) {
    return <NotFound error={getMyself.error} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <BackHandIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("name.title")}
        </Typography>
        <Typography variant="subtitle1">{t("name.subtitle")}</Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
                id="firstName"
                label={t("name.firstname")}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="family-name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
                id="lastName"
                label={t("name.lastname")}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <UpdateButton firstName={firstName} lastName={lastName} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
