import "react-international-phone/style.css";

import { gql, useQuery } from "@apollo/client";
import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";

const GET_CLIENT_INFO = gql`
  query GetClientInfo {
    GetClientInfo {
      country
    }
  }
`;

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

export interface MUIPhonePreProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}

export const PhoneField: React.FC<MUIPhonePreProps> = ({ value, ...props }) => {
  const { t } = useTranslation("misc");
  const { data, loading, error } = useQuery(GET_CLIENT_INFO, {
    skip: value?.length > 0,
  });

  const defaultCountry = data?.GetClientInfo?.country?.toLowerCase();

  // if it's loading and no error
  // appeared we can just wait a bit
  // it's not t o use
  // it's purely aesthetic as preloader
  if (loading && !error) {
    return (
      <TextField
        name="phone"
        value={value}
        required
        fullWidth
        id="phone"
        label={t("field.phone")}
      />
    );
  }

  return (
    <DisplayInput
      defaultCountry={defaultCountry}
      value={value || ""}
      {...props}
    />
  );
};

export const DisplayInput = ({
  defaultCountry,
  value,
  onChange,
  ...restProps
}) => {
  // NOTE: we are forced to hack this sytem
  // because otherwise it overwrite the value sent by the server to "+1"
  // and will set it on the setPhone() on upper level
  // so we have to wait until the focus is actually here to listen to "onChange"
  // all the changes before that shouldn't be listened to
  const [byFocus, setByFocus] = useState<boolean>(false);
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry,
      value,
      countries: defaultCountries,
      onChange: (data) => {
        // we enforce focus and also sending out only
        // when it's not only the country code
        if (byFocus) {
          onChange(data.phone);
        }
      },
    });

  return (
    <TextField
      variant="outlined"
      label="Phone number"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      onFocus={() => setByFocus(true)}
      style={{ marginTop: "8px" }}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: "flex" }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
