import AstroHi from "@/components/astro-hi";
import { FormError } from "@/components/form";
import LoadingButton from "@/components/loading-button";
import { UpgradeButton } from "@/components/upgrade-button";
import { eventBusEmit } from "@/helpers/event-bus";
import { gql, useMutation } from "@apollo/client";
import { Alert, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket($body: String!) {
    CreateSupportTicket(body: $body)
  }
`;

export default function Support() {
  const { t } = useTranslation("organization");
  const [ticketBody, setTicketBody] = useState<string>("");
  const [mutationCreateSupportTicket, { data, loading, error }] = useMutation(
    CREATE_SUPPORT_TICKET
  );

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    mutationCreateSupportTicket({
      variables: {
        body: ticketBody,
      },
    });
  };

  useEffect(() => {
    eventBusEmit({ type: "page-name", payload: t("support.title") });
    eventBusEmit({ type: "right-menu", payload: <UpgradeButton /> });
  }, [t]);

  if (data) {
    return (
      <Grid container spacing={3}>
        <Stack alignItems="center" textAlign="center">
          <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
            <AstroHi />
            <Typography align="center" textAlign="center">
              <Alert
                severity="success"
                sx={{
                  mb: 2,
                  mt: 2,
                  width: "fit-content",
                  margin: "auto",
                }}
              >
                {t("support.thank-you")}
              </Alert>
            </Typography>
          </Grid>
        </Stack>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Stack alignItems="center" textAlign="center">
        <Grid item xs={12} lg={8} sx={{ mb: 2 }}>
          <FormError error={error} sx={{ mb: 3 }} />
          <Typography align="center">
            <Grid item sx={{ m: 3 }}>
              {t("support.description")}
            </Grid>
            <Grid item sx={{ m: 3 }}>
              <TextareaAutosize
                value={ticketBody}
                style={{
                  width: "100%",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2em",
                }}
                onChange={(e) => {
                  setTicketBody(e.currentTarget.value);
                }}
                minRows={20}
              />
            </Grid>
            <Grid item sx={{ m: 3 }}>
              <LoadingButton
                loading={loading}
                disabled={loading || ticketBody.length === 0}
                onClick={handleSubmit}
                text={t("support.submit")}
                fullWidth
              />
            </Grid>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                mt: 2,
                width: "fit-content",
                margin: "auto",
              }}
            >
              {t("support.important")}
            </Alert>
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
}
