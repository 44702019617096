import {
  clearIdentity,
  getIdentity,
  getOrganizationID,
  getRole,
  setClientOrigin,
  setFake,
  setSensitiveData,
  unsetFake,
  unsetSensitiveData,
  withDashboardAccess,
} from "@/helpers/identity";
import PageError from "@/pages/error";
import Preflight from "@/preflight";
import { ApolloProvider } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRouteError,
} from "react-router-dom";

import { withoutDashboardAccess } from "@/helpers/identity";
import AuthenticationResetPassword from "@/pages/authentication/reset-password";
import AuthenticationSignIn from "@/pages/authentication/sign-in";
import AuthenticationSignInWithPassword from "@/pages/authentication/sign-in/with-password";
import LegalPrivacyStatement from "@/pages/legal/privacy-statement";
import LegalTermsOfService from "@/pages/legal/terms-of-service";
import MemberIndex from "@/pages/member";
import MemberSignUp from "@/pages/member/sign-up";
import MemberWelcome from "@/pages/member/welcome";
import MemberEntrypointQRCode from "@/pages/member/zones/entrypoint/qrcode";
import NotFound from "@/pages/not-found";
import OrganizationBilling from "@/pages/organization/billing";
import OrganizationDashboardLive from "@/pages/organization/dashboard/live";
import OrganizationDashboardMembers from "@/pages/organization/dashboard/members";
import OrganizationDashboardMembersDevices from "@/pages/organization/dashboard/members/devices";
import OrganizationDashboardMembersEdit from "@/pages/organization/dashboard/members/edit";
import OrganizationDashboardMembersImport from "@/pages/organization/dashboard/members/import";
import OrganizationDashboardMembersMetrics from "@/pages/organization/dashboard/members/metrics";
import OrganizationReports from "@/pages/organization/dashboard/reports/";
import OrganizationSettings from "@/pages/organization/dashboard/settings/";
import OrganizationSupport from "@/pages/organization/dashboard/support/";
import OrganizationDashboardZones from "@/pages/organization/dashboard/zones";
import OrganizationDashboardZonesEdit from "@/pages/organization/dashboard/zones/edit";
import OrganizationSignUp from "@/pages/organization/sign-up";
import OrganizationSubscription from "@/pages/organization/subscription/";
import OrganizationSettingsZoneCreate from "@/pages/organization/zones/create";
import OrganizationSettingsZoneCreateFixed from "@/pages/organization/zones/create/fixed";
import OrganizationSettingsZoneCreateMoving from "@/pages/organization/zones/create/moving";
import PresignAction from "@/pages/presign-action";
import SignOut from "@/pages/sign-out";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import React from "react";

import MyselfName from "@/pages/myself/name";
import MyselfPassword from "@/pages/myself/password";
import createApolloClient from "./apollo-client";
import { eventBusReceive } from "./helpers/event-bus";
import Layout from "./pages/organization/dashboard/layout/dashboard";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GlobalSmallLoading from "./components/global-small-loading";
import { rawURLAssign } from "./helpers/navigation";
import "./i18n";

const env = import.meta.env.VITE_ENV;

if (env !== "development") {
  Sentry.init({
    dsn: "https://d4f7fb0702f3a23a277af536e8da58c4@o4505968933797888.ingest.sentry.io/4506074857668608",
    // we ignore all apollo errors since
    // we process them manually from apollo-client.tsx
    ignoreErrors: [/ApolloError/],
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/api\.aquiestoy\.io/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  const { t } = useTranslation("misc");
  const [online, setOnline] = useState<boolean>(true);
  const [forbidden, setForbidden] = useState<boolean>(false);

  // from the landing we could some params such as offer and stuff
  const { searchParams } = new URL(window.location.href);
  if (searchParams.get("offer")) {
    setClientOrigin("originOffer", searchParams.get("offer") as string);
  }

  if (searchParams.get("blur") === "on") {
    setSensitiveData();
  }

  if (searchParams.get("blur") === "off") {
    unsetSensitiveData();
  }

  if (searchParams.get("fake") === "on") {
    setFake();
  }

  if (searchParams.get("fake") === "off") {
    unsetFake();
  }

  useEffect(() => {
    window.addEventListener("online", () => {
      setOnline(true);
    });
    window.addEventListener("offline", () => {
      setOnline(false);
    });
  }, []);

  const env = import.meta.env.VITE_ENV;
  const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
  const debug = env === "development";

  mixpanel.init(mixpanelToken, {
    debug,
    // for now we want to track page views
    // but it adds a ton of events so
    // we may want to deactivate it eventually
    track_pageview: true,
    persistence: "localStorage",
  });

  eventBusReceive("network-error", (payload) => {
    // here we can check all types of network error in details
    // and react accordingly before anything else is loaded
    if (payload.statusCode === 403) setForbidden(true);
  });

  if (!online) {
    return <PageError error={t("error.offline")} />;
  }

  if (forbidden) {
    clearIdentity();
    return <PageError error={t("error.forbidden")} />;
  }

  loadDevMessages();
  loadErrorMessages();

  const redirect = (url: string) => {
    rawURLAssign(url);
    // we show nothing because
    // it'll redirect the page directly
    return <GlobalSmallLoading />;
  };

  const requiredDomain: string = import.meta.env.VITE_APP_REQUIRED_DOMAIN;
  if (requiredDomain) {
    if (!window.location.href.startsWith(requiredDomain)) {
      return redirect(requiredDomain);
    }
  }

  const guestRoutes = ["/organization/sign-up", "/authentication/sign-in"];

  const organizationRoutes = (url: string): boolean => {
    if (guestRoutes.includes(url)) return false;
    if (url.startsWith("/organization/")) return true;
    return false;
  };

  const organizationRoot = "/organization/dashboard/live";
  const memberRoot = `/member/${getOrganizationID()}/welcome`;
  const guestRoot = "/organization/sign-up";
  const url = window.location.pathname;

  if (withDashboardAccess(getRole())) {
    if (guestRoutes.includes(url)) {
      return redirect(organizationRoot);
    }
  } else {
    if (organizationRoutes(url)) return redirect(guestRoot);
  }

  if (url === "/") {
    if (withDashboardAccess(getRole())) {
      return redirect(organizationRoot);
    } else if (withoutDashboardAccess(getRole())) {
      return redirect(memberRoot);
    } else return redirect(guestRoot);
  }

  function ErrorBoundary() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: any = useRouteError();

    // we manually sent it out to Sentry
    Sentry.setContext("identity", getIdentity());
    Sentry.captureException(error);

    // we show a generalist message
    // but if we are in development we show a little more
    let extra: JSX.Element = <></>;
    if (env === "development") {
      extra = <Typography color="error">DEV: {error.message}</Typography>;
    }
    return (
      <PageError
        error={t("error.something-went-wrong-please-refresh")}
        extra={extra}
      />
    );
  }

  const client = createApolloClient();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        {/* This was a global loading but it makes jumps effect so it was removed */}
        <Route index element={<GlobalSmallLoading />} />
        {/* Legal */}
        <Route
          path="legal/privacy-statement"
          errorElement={<ErrorBoundary />}
          element={<LegalPrivacyStatement />}
        />
        <Route
          path="legal/terms-of-service"
          errorElement={<ErrorBoundary />}
          element={<LegalTermsOfService />}
        />
        {/* Organization */}
        <Route
          path="organization/subscription"
          errorElement={<ErrorBoundary />}
          element={<OrganizationSubscription />}
        />
        <Route
          path="organization/billing"
          errorElement={<ErrorBoundary />}
          element={<OrganizationBilling />}
        />
        <Route
          path="organization/dashboard"
          errorElement={<ErrorBoundary />}
          element={<Navigate to="/organization/dashboard/live" />}
        />
        <Route
          path="organization/dashboard/live"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardLive />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/zones"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardZones />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/zones/:zoneID/edit"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardZonesEdit />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/members"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardMembers />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/members/:memberID/metrics"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardMembersMetrics />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/members/:memberID/devices"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardMembersDevices />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/members/import"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardMembersImport />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/members/:memberID/edit"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationDashboardMembersEdit />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/reports"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationReports />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/settings"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationSettings />
            </Layout>
          }
        />
        <Route
          path="organization/dashboard/support"
          errorElement={<ErrorBoundary />}
          element={
            <Layout>
              <OrganizationSupport />
            </Layout>
          }
        />
        <Route
          path="organization/zones/create"
          errorElement={<ErrorBoundary />}
          element={<OrganizationSettingsZoneCreate />}
        />
        <Route
          path="organization/zones/create/fixed"
          errorElement={<ErrorBoundary />}
          element={<OrganizationSettingsZoneCreateFixed />}
        />
        <Route
          path="organization/zones/create/moving"
          errorElement={<ErrorBoundary />}
          element={<OrganizationSettingsZoneCreateMoving />}
        />
        <Route
          path="organization/sign-up"
          errorElement={<ErrorBoundary />}
          element={<OrganizationSignUp />}
        />
        {/* Myself */}
        {/* This is organization agnostic, it'll be central configurations of the user itself.
        It can be used by admin as well as simple users */}
        <Route
          path="myself/password"
          errorElement={<ErrorBoundary />}
          element={<MyselfPassword />}
        />
        <Route
          path="myself/name"
          errorElement={<ErrorBoundary />}
          element={<MyselfName />}
        />
        {/* Member */}
        {/* Routes of the member related to a specific organization
        The organizationID isn't necessarily used every time right now, but it'll be useful in the future 
        for multi-organization */}
        <Route
          path="member/:organizationID"
          errorElement={<ErrorBoundary />}
          element={<MemberIndex />}
        />
        <Route
          path="member/:organizationID/welcome"
          errorElement={<ErrorBoundary />}
          element={<MemberWelcome />}
        />
        <Route
          path="member/:organizationID/sign-up"
          errorElement={<ErrorBoundary />}
          element={<MemberSignUp />}
        />
        <Route
          path="member/:organizationID/zones/:zoneID/entrypoints/qrcode"
          errorElement={<ErrorBoundary />}
          element={<MemberEntrypointQRCode />}
        />
        {/* Authentication */}
        <Route
          path="authentication/reset-password"
          errorElement={<ErrorBoundary />}
          element={<AuthenticationResetPassword />}
        />
        <Route
          path="authentication/sign-in"
          errorElement={<ErrorBoundary />}
          element={<AuthenticationSignIn />}
        />
        <Route
          path="authentication/sign-in/with-password"
          errorElement={<ErrorBoundary />}
          element={<AuthenticationSignInWithPassword />}
        />
        <Route
          path="presign-action"
          errorElement={<ErrorBoundary />}
          element={<PresignAction />}
        />
        <Route
          path="sign-out"
          errorElement={<ErrorBoundary />}
          element={<SignOut />}
        />
        <Route
          path="*"
          errorElement={<ErrorBoundary />}
          element={<NotFound />}
        />
        {/* Legacy routes (kept for old QRCode) equivalent to the entrypoints/qrcode one */}
        <Route
          path="employees/:organizationID/checking/:zoneID"
          element={<MemberEntrypointQRCode />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="members/:organizationID/zones/:zoneID/entrypoints/qrcode"
          element={<MemberEntrypointQRCode />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
    )
  );

  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <Preflight>
          <RouterProvider router={router} />
        </Preflight>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
