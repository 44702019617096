import { Copyright } from "@/components/copyright";
import { FormError, FormErrorComponent, FormSuccess } from "@/components/form";
import GlobalLoading from "@/components/global-loading";
import GlobalSmallLoading from "@/components/global-small-loading";
import LoadingButton from "@/components/loading-button";
import { PhoneField } from "@/components/phone-field";
import { sleep } from "@/helpers";
import { getDeviceInfo } from "@/helpers/device";
import { graphQLErrorCode } from "@/helpers/format";
import { hasIdentity, setIdentity, setLanguage } from "@/helpers/identity";
import {
  addParamsToPath,
  getAfterAuthPath,
  getParams,
  rawURLAssign,
  replacePathWith,
} from "@/helpers/navigation";
import i18n from "@/i18n";
import { Organization } from "@/interfaces";
import NotFound from "@/pages/not-found";
import { gql, useMutation, useQuery } from "@apollo/client";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const DEFAULT_AFTER_AUTH_URL = "/";

const GET_ORGANIZATION = gql`
  query GetOrganization($organizationID: UUID!) {
    GetOrganization(id: $organizationID) {
      id
      name
      slug
      registrationDisabled
    }
  }
`;

const MEMBER_SIGNIN = gql`
  mutation MemberSignin(
    $signinMethod: SigninMethod!
    $identifier: String!
    $deviceInput: DeviceInput!
  ) {
    MemberSignin(
      signinMethod: $signinMethod
      identifier: $identifier
      deviceInput: $deviceInput
    ) {
      id
      email
      phone
      role
      displayLanguage
      accessToken
      organizationID
    }
  }
`;

const MEMBER_SIGNIN_WITH_PASSWORD = gql`
  mutation MemberSignin(
    $signinMethod: SigninMethod!
    $identifier: String!
    $password: String!
    $deviceInput: DeviceInput!
  ) {
    MemberSignin(
      signinMethod: $signinMethod
      identifier: $identifier
      password: $password
      deviceInput: $deviceInput
    ) {
      id
      email
      phone
      role
      displayLanguage
      accessToken
      organizationID
    }
  }
`;

const REQUEST_RESET_PASSWORD = gql`
  mutation RequestPasswordReset(
    $signinMethod: SigninMethod!
    $identifier: String!
  ) {
    RequestPasswordReset(signinMethod: $signinMethod, identifier: $identifier)
  }
`;

export function SignInModule({ withPassword = false }) {
  const { t } = useTranslation("authentication");
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<Organization>({
    id: null,
    slug: null,
    name: null,
  });
  const [success, setSuccess] = useState<string>("");

  const params = getParams();
  let { organizationID } = useParams();
  if (!organizationID) organizationID = params.get("organization_id") || "";

  let signinMethod = organizationID ? "PHONE" : "EMAIL";
  const method = params.get("method") || "";
  if (["phone", "email"].includes(method)) {
    signinMethod = method.toUpperCase();
  }

  const getOrganization = useQuery(GET_ORGANIZATION, {
    variables: { organizationID },
    skip: !organizationID,
  });

  const defaultEmail: string = params.get("email") || "";
  const defaultPhone: string = params.get("phone") || "";
  const [formData, setFormData] = useState({
    email: defaultEmail,
    phone: defaultPhone,
  });
  const [password, setPassword] = useState<string>("");

  let currentMutation;
  if (withPassword) {
    currentMutation = MEMBER_SIGNIN_WITH_PASSWORD;
  } else {
    currentMutation = MEMBER_SIGNIN;
  }
  const [mutationMemberSignIn, { data, loading, error }] =
    useMutation(currentMutation);
  const [mutationRequestPasswordReset, requestResetPassword] = useMutation(
    REQUEST_RESET_PASSWORD
  );

  useEffect(() => {
    if (data) {
      setLanguage(data.MemberSignin.displayLanguage, i18n);
      setIdentity({
        id: data.MemberSignin.id,
        accessToken: data.MemberSignin.accessToken,
        role: data.MemberSignin.role,
        organizationID: data.MemberSignin.organizationID,
      });

      (async () => {
        while (hasIdentity() !== true) await sleep(500);
        // we don't clear the getAfterAuthPath because we may need
        // to have redirection manipulation right after the sign-in, such as set name
        const redirectURL =
          getAfterAuthPath({ clear: false }) || DEFAULT_AFTER_AUTH_URL;

        // TODO: this doesn't work... When "default path" is triggered
        // assignPathWith(navigate, redirectURL);
        rawURLAssign(redirectURL);
      })();
    }
  }, [data]);

  useEffect(() => {
    if (getOrganization.data) {
      setOrganization(getOrganization.data.GetOrganization);
    }
  }, [getOrganization]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    let identifier;
    if (signinMethod === "EMAIL") identifier = formData.email;
    if (signinMethod === "PHONE") identifier = formData.phone;

    const variables = { identifier, signinMethod };
    if (withPassword) {
      variables["password"] = password;
    }

    const deviceInput = getDeviceInfo();
    variables["deviceInput"] = deviceInput;

    mutationMemberSignIn({
      variables,
    });
  };

  if (getOrganization.loading) return <GlobalLoading />;
  if (getOrganization.error) {
    return <NotFound error={getOrganization.error} />;
  }

  let formCompleted = formData.email.length > 0 || formData.phone.length > 0;
  formCompleted =
    formCompleted && ((withPassword && password.length > 0) || !withPassword);

  let compError: JSX.Element = <></>;
  let hasCompError: boolean = false;

  if (error) {
    const params = {};
    // this will be "phone" or "email" for now
    if (signinMethod === "EMAIL") {
      params["email"] = formData.email;
      params["method"] = "email";
    }
    if (signinMethod === "PHONE") {
      params["phone"] = formData.phone;
      params["method"] = "phone";
    }

    if (graphQLErrorCode(error, "NO_PASSWORD_GIVEN")) {
      params["organization_id"] = organizationID;
      const path = addParamsToPath(
        "/authentication/sign-in/with-password",
        params
      );
      replacePathWith(navigate, path);
      // we show nothing because
      // it'll redirect the page directly
      return <GlobalSmallLoading />;
    } else if (graphQLErrorCode(error, "MEMBER_NOT_FOUND")) {
      if (!organization.registrationDisabled) {
        hasCompError = true;

        const path = addParamsToPath(
          organizationID
            ? `/member/${organizationID}/sign-up`
            : "/organization/sign-up",
          params
        );

        if (organizationID) {
          const key =
            signinMethod === "PHONE"
              ? "sign-in.phone-not-found-in-organization"
              : "sign-in.email-not-found-in-organization";
          compError = (
            <Trans t={t} i18nKey={key}>
              text <a href={path}>text</a>
            </Trans>
          );
        } else {
          const key =
            signinMethod === "PHONE"
              ? "sign-in.phone-not-found"
              : "sign-in.email-not-found";
          compError = (
            <Trans t={t} i18nKey={key}>
              text <a href={path}>text</a>
            </Trans>
          );
        }
      }
    } else if (graphQLErrorCode(error, "WRONG_PASSWORD")) {
      hasCompError = true;

      let identifier;
      if (signinMethod === "EMAIL") identifier = formData.email;
      if (signinMethod === "PHONE") identifier = formData.phone;

      const variables = { identifier, signinMethod };

      const resetPassword = () => {
        mutationRequestPasswordReset({
          variables,
        });
        setSuccess(t("sign-in.request-reset-password-successful"));
      };

      compError = (
        <Trans t={t} i18nKey={"sign-in.wrong-password"}>
          text
          <a href="#" onClick={resetPassword}>
            text
          </a>
        </Trans>
      );
    }
  }

  const disabled = !formCompleted || loading;
  const registrationDisabled =
    organizationID && organization.registrationDisabled;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {organization.id
            ? t("sign-in.initialize-session-organization", {
                name: organization.name,
              })
            : t("sign-in.initialize-session")}
        </Typography>
        <Box
          component="form"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          {hasCompError ? (
            <FormErrorComponent error={compError} sx={{ mb: 3 }} />
          ) : (
            <FormError
              error={error || requestResetPassword.error}
              sx={{ mb: 1 }}
            />
          )}
          {success ? <FormSuccess message={success} /> : <></>}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {signinMethod === "EMAIL" ? (
                <Fragment>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    type="email"
                    autoFocus={!formData.email}
                    value={formData.email}
                    onChange={handleChange}
                    label={t("sign-in.email")}
                    name="email"
                    autoComplete="email"
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <PhoneField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    type="phone"
                    autoFocus
                    value={formData.phone}
                    label={t("sign-in.phone")}
                    onChange={(phone) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone,
                      }));
                    }}
                    autoComplete="phone"
                  />
                </Fragment>
              )}
            </Grid>
            {withPassword ? (
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  value={password}
                  autoFocus={!!(formData.email || formData.phone)}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  label={t("sign-in.password")}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                disabled={disabled}
                sx={{ mt: 0 }}
                text={t("sign-in.submit")}
                fullWidth
              />
            </Grid>
            <Grid container direction="column" alignItems="flex-end">
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  {signinMethod === "PHONE" ? (
                    <Button
                      style={{ textTransform: "unset" }}
                      sx={{ mt: 1 }}
                      onClick={() => {
                        const params = {
                          method: "email",
                          organization_id: organizationID || "",
                        };

                        const path = addParamsToPath(
                          window.location.pathname,
                          params
                        );
                        rawURLAssign(path);
                      }}
                    >
                      {t("sign-in.use-email")}
                    </Button>
                  ) : (
                    <Button
                      style={{ textTransform: "unset" }}
                      sx={{ mt: 1 }}
                      onClick={() => {
                        const params = {
                          method: "phone",
                          organization_id: organizationID || "",
                        };

                        const path = addParamsToPath(
                          window.location.pathname,
                          params
                        );
                        rawURLAssign(path);
                      }}
                    >
                      {t("sign-in.use-phone")}
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    display: registrationDisabled ? "none" : "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    style={{ textTransform: "unset" }}
                    onClick={() => {
                      const params = {};
                      // this will be "phone" or "email" for now
                      if (signinMethod === "EMAIL")
                        params["email"] = formData.email;
                      if (signinMethod === "PHONE")
                        params["phone"] = formData.phone;

                      const path = addParamsToPath(
                        organizationID
                          ? `/member/${organizationID}/sign-up`
                          : "/organization/sign-up",
                        params
                      );
                      rawURLAssign(path);
                    }}
                  >
                    {t("sign-in.please-sign-up")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default function MemberSignIn() {
  return <SignInModule />;
}
