import { CircularProgress, Grid, Stack } from "@mui/material";

export default function Loading({ extra = "" }) {
  return (
    <Stack alignItems="center" textAlign="center" flexWrap="wrap">
      <Grid container direction="row" alignContent="middle">
        <Grid item xs={4} sm={4} style={{ margin: "auto" }}>
          <CircularProgress color="primary" />
          {extra}
        </Grid>
      </Grid>
    </Stack>
  );
}
